import React, { useState } from "react";
import {
	ChevronIcon,
	DownloadIcon,
	FunnelIcon,
	RefreshIcon,
} from "../../../assets/svg/icons";
import range from "../../../utils/range";
import Pagination from "../../../components/Pagination";
import Loader from "../../../components/Loader";
import { capitalize, prepareObjectArray } from "../../../utils/format";
import { formatDateUTC } from "../../../utils/formatDate";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import delay from "../../../utils/delay";

function PaymentList({
	items,
	refreshItems,
	showModal,
	setSelectedItem,
	showOffCanvas,
	filters,
	setFilter,
	resetFilters,
	generateReport,
}) {
	const navigate = useNavigate();
	const location = useLocation();
	const actualCrumb = location.pathname.split("/").pop();

	const [selectedRows, setSelectedRows] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(15);
	const [query, setQuery] = useState("");
	const [status, setStatus] = useState(false);

	const [canPreviousPage, setCanPreviousPage] = useState(false);
	const [canNextPage, setCanNextPage] = useState();

	const choices = {
		NUEVO: "text-slate-700 border border-slate-400 bg-slate-200",
		APROBADO: "text-emerald-700 border border-emerald-400 bg-emerald-200",
		RECHAZADO: "text-red-700 border border-red-400 bg-red-200",
		"PENDIENTE POR PAGAR":
			"text-amber-700 border border-amber-400 bg-amber-100",
		PAGADO: "text-blue-700 border border-blue-400 bg-blue-200",
		"PENDIENTE POR CONTABILIZAR":
			"text-amber-700 border border-amber-400 bg-amber-100",
		CONTABILIZADO: "text-violet-700 border border-violet-400 bg-violet-200",
		REMESA: "text-emerald-700 border border-emerald-400 bg-emerald-200",
		INDIVIDUAL: "text-violet-700 border border-violet-400 bg-violet-200",
		UNIFICADO: "text-pink-700 border border-pink-400 bg-pink-200",
	};

	const handleListRowClick = (e, item) => {
		if (e.detail === 2) {
			selectItem(item);
		}
	};

	const selectItem = (value) => {
		if (actualCrumb === "") {
			navigate(`/pagos/${value.id}`);
		} else {
			navigate(`/${actualCrumb}/${value.id}`);
		}
	};

	const handleRefreshItems = async () => {
		setStatus(true);
		refreshItems();
		await delay(2000);
		setStatus(false);
	};

	if (items !== undefined) {
		const totalPages = Math.ceil(items.length / itemsPerPage);

		const handleLimit = (e) => {
			setItemsPerPage(e.target.value);

			if (currentPage === 1) {
				setCanPreviousPage(false);
			} else {
				setCanPreviousPage(true);
			}

			if (currentPage === totalPages) {
				setCanNextPage(false);
			} else {
				setCanNextPage(true);
			}
		};

		if (currentPage <= totalPages) {
		} else {
			setCurrentPage(totalPages);
		}

		const limits = range(5, 30, 5);

		const indexOfLastIndex = currentPage * itemsPerPage;
		const indexOfFirstItem = indexOfLastIndex - itemsPerPage;
		const currentItems = items.slice(indexOfFirstItem, indexOfLastIndex);

		const handleReference = (id) => {
			let reference = "000000";
			id = id.toString();

			reference = reference.slice(0, reference.length - id.length) + id;
			return "# " + reference;
		};

		const handleSortColumn = (column, order) => {};

		const handleDisabled = () => {
			if (selectedRows.length < 2) return true;

			let validator = {
				state: false,
			};

			for (const pay of selectedRows) {
				if (pay.paystate !== "APROBADO") {
					validator.state = true;
				}

				if (!validator.category) {
					validator.category = pay.category;
				} else {
					if (pay.category !== validator.category) {
						validator.state = true;
					}
				}
			}

			return validator.state;
		};

		const handleSelectRow = (item) => {
			let itemIndex = selectedRows.findIndex((e) => e.id === item.id);

			if (itemIndex > -1) {
				let currentSelectedRows = [...selectedRows];
				currentSelectedRows.splice(itemIndex, 1);
				setSelectedRows(currentSelectedRows);
			} else {
				setSelectedRows([
					...selectedRows,
					{
						id: item.id,
						category: item.category.id,
						paystate: item.paystate.pay_sta_description,
					},
				]);
			}
		};

		const handleSelectedRows = (item) => {
			let itemIndex = selectedRows.some((e) => e.id === item.id);

			return itemIndex;
		};

		const handleUnifyButton = () => {
			if (selectedRows.length > 0) {
				return (
					<button
						className="btn text-white bg-violet-500 hover:bg-violet-400"
						title="Unificar"
						disabled={handleDisabled()}
						onClick={() => {
							showModal({ modal: "Unificar", show: true });
							setSelectedItem(selectedRows);
						}}
					>
						Unificar
					</button>
				);
			}
		};

		const handleSetFilter = (id) => {
			switch (id) {
				case "sort":
					let sortValue;

					if (filters) {
						if (filters.sort) {
							sortValue = false;
						} else {
							sortValue = true;
						}
						setFilter("sort", sortValue);
					} else {
						if (!filters?.sort) {
							setFilter("sort", false);
						}
					}

					break;

				default:
					break;
			}
		};

		return (
			<div className="flex flex-col justify-between h-full px-0 sm:px-6">
				<div>
					<div className="list-options flex flex-col md:flex-row items-center justify-between mb-10 sm:m-0 h-20">
						<div className="flex items-center self-start pl-4 sm:p-0 h-20">
							<BreadCrumbs />
						</div>
						<div className="flex flex-col-reverse sm:flex-row gap-2 sm:gap-4 items-center justify-center h-20 self-end mt-4 sm:m-0 pr-4 sm:p-0">
							<div className="flex gap-4 items-center justify-end h-20 w-full">
								{handleUnifyButton()}
								<button
									className="btn text-slate-400 bg-transparent hover:text-white hover:bg-blue-500"
									title="Cargar pago"
									onClick={() => {
										showModal({ modal: "", show: true });
									}}
								>
									Cargar pago
								</button>
							</div>
							<div className="flex gap-4 items-center justify-between h-20">
								{items.length === 1 &&
								typeof items[0] === "string" ? null : (
									<div
										className="h-4 w-4"
										title="Descargar reporte"
									>
										<DownloadIcon
											className={`h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black`}
											onClick={() => generateReport()}
										/>
									</div>
								)}
								<div
									className="h-4 w-4"
									title="Actualizar datos"
								>
									<RefreshIcon
										className={`h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black${
											status ? " rotate" : ""
										}`}
										onClick={() => handleRefreshItems()}
									/>
								</div>
								<div className="h-4 w-4" title="Filtros">
									<FunnelIcon
										className="h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black"
										onClick={showOffCanvas}
									/>
								</div>
								<div className="topbar-search">
									<input
										type="search"
										placeholder="Buscar"
										title="Buscar entre los registros por:
Referencia
Concepto
Creado por"
										onChange={(e) =>
											setQuery(e.target.value)
										}
									/>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
									>
										<path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div className="table-container scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded">
						<table className="table-auto w-full">
							<thead>
								<tr>
									<th className="w-12 hover:bg-transparent"></th>
									<th>Referencia</th>
									<th>Concepto</th>
									<th>Tipo de pago</th>
									<th>Categoría</th>
									<th
										className="flex items-center justify-between"
										onClick={() => {
											handleSetFilter("sort");
										}}
										title={
											filters?.sort === true
												? "Más nuevos a más antiguos"
												: "Más antiguos a más nuevos"
										}
									>
										Creado
										{filters?.sort === true ? (
											<ChevronIcon className="h-3 w-3 fill-gray-400 rotate-180" />
										) : (
											<ChevronIcon className="h-3 w-3 fill-gray-400" />
										)}
									</th>
									<th>Creado por</th>
									<th>Fecha de vencimiento</th>
									<th>Estado</th>
								</tr>
							</thead>
							{typeof items[0] !== "string" ? (
								<tbody>
									{currentItems
										.filter((item) => {
											const searchItem =
												query.toLocaleLowerCase();
											const id = item.id
												.toString()
												.toLocaleLowerCase();
											const description =
												item.pay_description.toLocaleLowerCase();
											const createdBy =
												item.user.name.toLocaleLowerCase();
											if (!searchItem) return true;
											return (
												id
													.toLowerCase()
													.indexOf(
														searchItem.toLowerCase()
													) > -1 ||
												description
													.toLowerCase()
													.indexOf(
														searchItem.toLowerCase()
													) > -1 ||
												createdBy
													.toLowerCase()
													.indexOf(
														searchItem.toLowerCase()
													) > -1
											);
										})
										.map((item) => (
											<tr
												key={item.id}
												className={`hover:bg-slate-100${
													handleSelectedRows(item)
														? " bg-gray-200"
														: ""
												}`}
											>
												<td
													className="flex items-center justify-center cursor-pointer"
													onClick={() =>
														handleSelectRow(item)
													}
												>
													<input
														type="checkbox"
														className="custom-checkbox"
														checked={handleSelectedRows(
															item
														)}
														onChange={() =>
															handleSelectRow(
																item
															)
														}
													/>
												</td>
												<td
													title={handleReference(
														item.id
													)}
													onClick={(e) => {
														handleListRowClick(
															e,
															item
														);
													}}
												>
													{handleReference(item.id)}
												</td>
												<td
													title={capitalize(
														item.pay_description
													)}
													className="max-w-[180px] overflow-hidden hover:underline cursor-pointer"
													onClick={(e) => {
														handleListRowClick(
															e,
															item
														);
														selectItem(item);
													}}
												>
													{capitalize(
														item.pay_description
													)}
												</td>
												<td
													title={capitalize(
														item.paystate
															.pay_sta_description
													)}
													onClick={(e) => {
														handleListRowClick(
															e,
															item
														);
													}}
												>
													<span
														className={`py-1 px-2 rounded-full ${
															choices[
																item.pay_type
															]
														}`}
														title={capitalize(
															item.pay_type
														)}
													>
														{capitalize(
															item.pay_type
														)}
													</span>
												</td>
												<td
													title={capitalize(
														item.category
															.cat_description
													)}
													onClick={(e) => {
														handleListRowClick(
															e,
															item
														);
													}}
												>
													{capitalize(
														item.category
															.cat_description
													)}
												</td>
												<td
													title={formatDateUTC(
														item.created
													).longDateTime24()}
													onClick={(e) => {
														handleListRowClick(
															e,
															item
														);
													}}
												>
													{formatDateUTC(
														item.created
													).shortDateTime24()}
												</td>
												<td
													title={item.user.name}
													onClick={(e) => {
														handleListRowClick(
															e,
															item
														);
													}}
												>
													{item.user.name}
												</td>
												<td
													title={
														item.invoice_due_date
															? item.invoice_due_date
															: "---"
													}
												>
													{item.invoice_due_date
														? item.invoice_due_date
														: "---"}
												</td>
												<td
													title={capitalize(
														item.paystate
															.pay_sta_description
													)}
													onClick={(e) => {
														handleListRowClick(
															e,
															item
														);
													}}
												>
													<span
														className={`py-1 px-2 rounded-full ${
															choices[
																item.paystate
																	.pay_sta_description
															]
														}`}
														title={capitalize(
															item.paystate
																.pay_sta_description
														)}
													>
														{capitalize(
															item.paystate
																.pay_sta_description
														)}
													</span>
												</td>
											</tr>
										))}
								</tbody>
							) : (
								<tbody>
									<tr key={0} className="h-12">
										<td
											colSpan={8}
											className="text-gray-500 text-center"
										>
											<span>No hay resultados.</span>{" "}
											<span
												className="text-blue-500 hover:text-blue-400 underline cursor-pointer"
												onClick={resetFilters}
											>
												Limpiar filtros
											</span>
										</td>
									</tr>
								</tbody>
							)}
						</table>
					</div>
				</div>
				<span className="pagination-info flex flex-row flex-wrap gap-5 items-center py-12 px-2">
					{items.length > 0 ? (
						<>
							<span className="pagination-info-limit">
								<select
									className="input"
									defaultValue={15}
									onChange={handleLimit}
								>
									{limits.map((limit) => (
										<option
											key={limit}
											id={limit}
											value={limit}
										>{`Mostrar ${limit}`}</option>
									))}
								</select>
							</span>
							<Pagination
								totalPages={totalPages}
								page={currentPage}
								setPage={setCurrentPage}
								limit={itemsPerPage}
								siblings={1}
								canNextPage={canNextPage}
								setCanNextPage={setCanNextPage}
								canPreviousPage={canPreviousPage}
								setCanPreviousPage={setCanPreviousPage}
							/>
						</>
					) : null}
				</span>
			</div>
		);
	} else {
		return (
			<div className="flex flex-col justify-between h-full px-0 sm:px-6">
				<div>
					<div className="list-options flex flex-col sm:flex-row items-center justify-between mb-10 sm:m-0 h-20">
						<div className="flex items-center self-start pl-4 sm:p-0 h-20">
							<BreadCrumbs />
						</div>
						<div className="flex gap-4 items-center justify-center h-20 self-end mt-4 sm:m-0 pr-4 sm:p-0">
							<div className="h-4 w-4" title="Actualizar datos">
								<RefreshIcon
									className={`h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black${
										status ? " rotate" : ""
									}`}
									onClick={() => handleRefreshItems()}
								/>
							</div>
							<div className="h-4 w-4" title="Filtros">
								<FunnelIcon
									className="h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black"
									onClick={showOffCanvas}
								/>
							</div>
							<div className="topbar-search">
								<input
									type="search"
									placeholder="Buscar"
									title="Busque por el nombre"
									onChange={(e) => setQuery(e.target.value)}
								/>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
								</svg>
							</div>
						</div>
					</div>
					<div className="table-container">
						<table className="table-auto w-full">
							<thead>
								<tr>
									<th>Referencia</th>
									<th>Concepto</th>
									<th>Tipo de pago</th>
									<th>Categoría</th>
									<th
										className="flex items-center justify-between"
										onClick={() => {
											/*setFilter(
													"sort",
													!filters.sort
												);*/
										}}
										title={
											"" /*
												filters.sort
													? "Más nuevos a más antiguos"
													: "Más antiguos a más nuevos"*/
										}
									>
										Creado
										{/*filters.sort ? (
												<ChevronIcon className="h-3 w-3 fill-gray-400 rotate-180" />
											) : (
												<ChevronIcon className="h-3 w-3 fill-gray-400" />
											)*/}
									</th>
									<th>Creado por</th>
									<th>Fecha de vencimiento</th>
									<th>Estado</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
								<tr>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
								<tr className="opacity-70">
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
								<tr className="opacity-50">
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
								<tr className="opacity-20">
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

export default PaymentList;
