import React, { useEffect, useRef } from "react";

function Modal({ show, className, modalTitle, onClose, children }) {
	const modalRef = useRef();

	useEffect(() => {
		const handler = (e) => {
			if (e.target !== modalRef.current) return;
			switch (e.code) {
				case "Escape":
					onClose();
					break;

				default:
					console.log(e.code);
					break;
			}
		};

		modalRef.current?.addEventListener("keydown", handler);

		return () => modalRef.current?.removeEventListener("keydown", handler);
	}, [modalRef, onClose]);

	if (!show) {
		return null;
	}

	return (
		<div ref={modalRef} className="modal">
			<div className={`modal-content${className ? " " + className : ""}`}>
				<div className="modal-content-header">
					<span className="header-title">{modalTitle}</span>
					<span
						className="header-icon"
						title="Cerrar"
						onClick={onClose}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							id="X"
							viewBox="0 0 315 315"
						>
							<rect
								id="stick"
								x="-12.43"
								y="176.33"
								width="425.87"
								height="47.34"
								rx="23.67"
								ry="23.67"
								transform="translate(157.15 -125.7) rotate(45)"
							/>
							<rect
								id="stick-2"
								data-name="stick"
								x="-12.43"
								y="176.33"
								width="425.87"
								height="47.34"
								rx="23.67"
								ry="23.67"
								transform="translate(440.7 157.15) rotate(135)"
							/>
						</svg>
					</span>
				</div>
				{children}
			</div>
		</div>
	);
}

export default Modal;
