import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useAuthContext } from "../contexts/AuthContext";
// Components
import Sidebar from "../layouts/Sidebar";
import Topbar from "../layouts/Topbar";
import PaymentDetails from "../features/payments/components/PaymentDetails";
import OffCanvas from "../components/OffCanvas";
// Utils
import Modal from "../hooks/useModal";
import delay from "../utils/delay";
import { isEqual } from "../utils/compare";
import { formatDateUTC } from "../utils/formatDate";
// Icons
import {
	BlockIcon,
	HomeIcon,
	MagnifyingGlassIcon,
	QuestionMarkIcon,
	TrashFilledIcon,
	UserIcon,
} from "../assets/svg/icons";
// Requests
import getPayment from "../features/payments/services/getPayment";
import approvePayment from "../features/payments/services/approvePayment";
import rejectPayment from "../features/payments/services/rejectPayment";
import processPayment from "../features/payments/services/processPayment";
import getPayAccounts from "../features/payments/services/getPayAccounts";
import downloadFile from "../features/payments/services/downloadFile";
import payOrder from "../features/payments/services/payOrder";
import startPosting from "../features/payments/services/startPosting";
import postOrder from "../features/payments/services/postOrder";
import { capitalize, prepareObjectArray } from "../utils/format";
import getBanks from "../services/getBanks";
import getDocumentTypes from "../services/getDocumentTypes";
import getCategories from "../features/categories/services/getCategories";
import Select from "../components/Select";
import updateIndividualPayment from "../features/payments/services/updateIndividualPayment";
import updateMultiplePayments from "../features/payments/services/updateMultiplePayment";
import updatePaylistState from "../features/payments/services/updatePaylistState";

const choices = {
	NUEVO: "text-slate-700 border border-slate-400 bg-slate-200",
	APROBADO: "border border-emerald-400 bg-emerald-100",
	RECHAZO: "border border-red-400 bg-red-100",
	PROCESADO: "border border-yellow-400 bg-yellow-100",
	PAGADO: "border border-blue-400 bg-blue-100",
	CONTABILIZADO: "border border-violet-400 bg-violet-100",
	UNIFICADO: "border border-pink-400 bg-pink-100",
	CPP: "border border-sky-400 bg-sky-100",
};

function PaymentDetail() {
	const selectRef = useRef();
	const fileRef = useRef();
	const formRef = useRef();

	const { userData } = useAuthContext();

	const navigate = useNavigate();

	if (userData && userData.appRoles.length <= 0) {
		navigate(`/no-roles`);
	}

	const location = useLocation();
	var actualCrumb = location.pathname.split("/").pop();

	const [dragNDrop, setDragNDrop] = useState();
	const [modal, setModal] = useState({ show: false });
	const [isOpen, setIsOpen] = useState(false);

	const [selectedFilters, setSelectedFilters] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [items, setItems] = useState();
	const [payAccounts, setPayAccounts] = useState();
	const [query, setQuery] = useState("");

	const [status, setStatus] = useState(false);

	const [selectedItem, setSelectedItem] = useState();
	const [uploadFiles, setUploadFiles] = useState();
	const [paymentData, setPaymentData] = useState({});
	const [banks, setBanks] = useState();
	const [documentTypes, setDocumentTypes] = useState();
	const [categories, setCategories] = useState();
	const [questionMark, setQuestionMark] = useState({
		question: "",
		show: false,
	});

	useEffect(() => {
		if (!selectedFilters > 0) {
			setFilteredData(items);
		}
	}, [items, selectedFilters]);

	const refreshBanks = async () => {
		let { banks } = await getBanks();
		setBanks(banks);
	};

	const refreshDocumentTypes = async () => {
		let { documenttypes } = await getDocumentTypes();
		setDocumentTypes(documenttypes);
	};

	const refreshCategories = async () => {
		let categories = await getCategories();
		for (let i = 0; i < categories.length; i++) {
			delete categories[i].cat_approval_level;
			delete categories[i].users;
		}
		categories = prepareObjectArray(categories);
		setCategories(categories);
	};

	const refreshPayment = async () => {
		let data = await getPayment(Number(actualCrumb));
		setItems(data);
	};

	const refreshPayAccounts = async () => {
		let { payaccounts } = await getPayAccounts();
		setPayAccounts(payaccounts);
	};

	useEffect(() => {
		refreshPayment();
		refreshPayAccounts();
		refreshBanks();
		refreshDocumentTypes();
		refreshCategories();

		const interval = setInterval(() => {
			refreshPayment();
			refreshPayAccounts();
			refreshBanks();
			refreshDocumentTypes();
			refreshCategories();
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	const handleDisabled = () => {
		switch (modal.modal) {
			case "Aprobar":
			case "Pagar":
			case "Iniciar contabilización":
			case "Contabilizar": {
				if (status === true) {
					return true;
				} else {
					return false;
				}
				break;
			}

			case "Rechazar": {
				if (status === true || paymentData?.note === "") {
					return true;
				} else {
					return false;
				}
				break;
			}

			case "Procesar": {
				if (
					status === true ||
					paymentData?.pay_account_id === "" ||
					paymentData?.pay_account_id === undefined
				) {
					return true;
				} else {
					return false;
				}
				break;
			}

			case "Individual": {
				if (
					status === false &&
					paymentData.pay_lis_holder_document_number.length >= 5 &&
					paymentData.pay_lis_holder_document_number.length <= 20 &&
					(paymentData.category_id !==
						selectedItem.payment.category.id ||
						paymentData.pay_lis_holder_name !==
							selectedItem.order[0].pay_lis_holder_name ||
						paymentData.document_type_id !==
							selectedItem.order[0].documenttype.id ||
						paymentData.pay_lis_holder_document_number !==
							selectedItem.order[0]
								.pay_lis_holder_document_number ||
						paymentData.pay_lis_holder_mail !==
							selectedItem.order[0].pay_lis_holder_mail ||
						paymentData.pay_lis_holder_immovable !==
							Number(
								selectedItem.order[0].pay_lis_holder_immovable
							) ||
						paymentData.bank_id !== selectedItem.order[0].bank.id ||
						paymentData.pay_lis_account_number !==
							selectedItem.order[0].pay_lis_account_number ||
						paymentData.pay_lis_account_type !==
							selectedItem.order[0].pay_lis_account_type ||
						paymentData.pay_lis_payment_amount !==
							selectedItem.order[0].pay_lis_payment_amount)
				) {
					return false;
				} else {
					return true;
				}
				break;
			}

			case "Remesa": {
				if (status === false && uploadFiles) {
					return false;
				} else {
					return true;
				}
				break;
			}

			case "Pago": {
				if (status === false) {
					return false;
				} else {
					return true;
				}
				break;
			}

			default:
				return true;
				break;
		}
	};

	const download = (file, name) => {
		// Crear un objeto URL del blob
		const url = window.URL.createObjectURL(new Blob([file]));

		// Crear un enlace de descarga
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", name);

		// Hacer clic en el enlace para descargar el archivo
		document.body.appendChild(link);
		link.click();

		// Liberar el objeto URL
		window.URL.revokeObjectURL(url);
	};

	const handleFormSubmit = async (e) => {
		if (e) e.preventDefault();
		setStatus(true);
		let response;
		const toastId = toast.loading("Actualizando...");

		switch (modal.modal) {
			case "Aprobar": {
				let user_approval = items.payment.category.approvers.filter(
					(approver) => {
						if (approver.id === userData.appId) {
							if (
								approver.usersxcategories.approval_level ===
								items.payment.pay_approval_level
							) {
								return approver;
							}
						}
					}
				);

				if (user_approval.length > 0) {
					let body = {
						id: items.payment.id,
						user_id: userData.appId,
						approval_level:
							user_approval[0].usersxcategories.approval_level,
					};

					response = await approvePayment(body);
				}

				break;
			}

			case "Rechazar": {
				let body = {
					id: items.payment.id,
					user_id: userData.appId,
					note: paymentData.note,
				};

				response = await rejectPayment(body);
				break;
			}

			case "Procesar": {
				let body = {
					id: items.payment.id,
					pay_account_id: paymentData.pay_account_id,
					user_id: userData.appId,
				};

				let data = await processPayment(body);

				let file = await downloadFile(
					"BANKFILE",
					items.payment.id,
					data.plane_file
				);

				download(file, data.plane_file);

				response = data.message;
				break;
			}

			case "Pagar": {
				let body = {
					id: items.payment.id,
					user_id: userData.appId,
				};

				let data = await payOrder(body);

				let file = await downloadFile(
					"REPORT",
					items.payment.id,
					data.file_name
				);

				download(file, data.file_name);

				response = data.message;
				break;
			}

			case "Iniciar contabilización": {
				let body = {
					id: items.payment.id,
					user_id: userData.appId,
				};

				response = await startPosting(body);
				break;
			}

			case "Contabilizar": {
				let body = {
					id: items.payment.id,
					user_id: userData.appId,
				};

				response = await postOrder(body);
				break;
			}

			case "Individual": {
				let body = {
					...paymentData,
					user_id: userData.appId,
				};

				console.log(body);
				response = await updateIndividualPayment(body);
				setUploadFiles();
				break;
			}

			case "Remesa": {
				let formData = new FormData();

				formData.append("file", uploadFiles, uploadFiles.name);
				formData.append("id", paymentData.id);
				//formData.append("general_concept", paymentData.general_concept);
				formData.append("category_id", paymentData.category_id);
				formData.append("user_id", userData.appId);

				response = await updateMultiplePayments(formData);
				setUploadFiles();
				break;
			}

			default:
				break;
		}

		switch (response) {
			case "PAYMENT_NOT_EXISTS":
				toast.error("No se encontró el pago", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "USER_NOT_FOUND":
				toast.error("No se encontró el usuario", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "Invalid payment ID":
				toast.error("Id del pago inválido", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_TYPE_IS_NOT_MULTIPLE":
				toast.error("El tipo de pago del pago no es de remesa", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "CATEGORY_NOT_EXISTS":
				toast.error("La categoría no existe", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "USER_IS_NOT_PAYMENT_USER_CREATOR":
				toast.error("El usuario no es el creador de este pago", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_STATUS_IS_NOT_REJECTED":
				toast.error("El estado del pago no es rechazado", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_TYPE_IS_NOT_INDIVIDUAL":
				toast.error("El tipo de pago del pago no es individual", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "USER_IS_NOT_APPROVER_FOR_THIS_PAYMENT_CATEGORY":
				toast.error(
					"El usuario no es aprobador en esta categoría de pago",
					{
						id: toastId,
						style: {
							border: "1px solid #FF4C4C",
						},
					}
				);
				break;

			case "USER_APPROVAL_LEVEL_DONT_MATCH_WITH_PAYMENT_APPROVAL_LEVEL":
				toast.error("El usuario no puede aprobar el pago actualmente", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_ALREADY_APPROVED":
				toast.error("El pago ya se encuentra aprobado", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_IS_NOT_APPROVED":
				toast.error("El pago ya se encuentra aprobado", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_ALREADY_REJECTED":
				toast.error("El pago ya se encuentra rechazado", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "ERROR_CREATING_INDIVIDUAL_PAYMENT":
				toast.error("Occurió un error al crear el pago individual", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "Multiple payments have been updated sucessfully!":
			case "Individual payment has been updated sucessfully!":
				toast.success("Pago actualizado exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			case "Payment has been aproved successfully!":
				toast.success("Pago aprobado exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			case "Payment has been rejected successfully!":
				toast.success("Pago rechazado exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			case "Payment has been processed successfully!":
				toast.success("Pago procesado exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			case "Order has been payed successfully!":
				toast.success("Orden pagaba exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			case "Posting process started successfully!":
				toast.success(
					"Si inició la contabilización del pago exitosamente",
					{
						id: toastId,
						style: {
							border: "1px solid #62D346",
						},
					}
				);
				break;

			case "Order has been posted successfully!":
				toast.success("Se contabilizó el pago exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			case "ERROR_APPROVING_PAYMENT":
			default:
				if (response.indexOf("{") > -1) {
					toast.error(response, {
						id: toastId,
						style: {
							border: "1px solid #FF4C4C",
						},
					});
				} else {
					toast.error(
						`Ocurrió un error al crear ${
							modal.modal === "individual"
								? "el pago individual"
								: "la remesa de pago"
						}`,
						{
							id: toastId,
							style: {
								border: "1px solid #FF4C4C",
							},
						}
					);
				}
				break;
		}

		if (response.indexOf("{") < 0) {
			setModal({ modal: "", show: false });
			setPaymentData({});
			setUploadFiles();
			setStatus(false);
		}
		refreshPayment();
		await delay(10000);
		toast.dismiss(toastId);
		setStatus(false);
	};

	const handleFormChange = (id, value) => {
		switch (id) {
			case "pay_account_id": {
				setPaymentData({ ...paymentData, [id]: Number(value) });
				break;
			}

			case "note": {
				setPaymentData({ ...paymentData, [id]: value });
				break;
			}

			default:
				break;
		}
	};

	/**
	 * Handle the changes of the payment form
	 *
	 * @param {string} id The identificator fo the payment object
	 * @param {any} value The value to set
	 */
	const handlePaymentForm = async (id, value, idx = 0) => {
		switch (id) {
			case "category_id": {
				console.log(value[0].value);
				setPaymentData({
					...paymentData,
					[id]: value[0].value,
				});
				break;
			}

			case "pay_lis_holder_name":
			case "pay_lis_account_type":
			case "pay_lis_account_number":
			case "pay_lis_holder_mail":
			case "pay_lis_holder_document_number":
			case "general_concept":
			case "pay_description": {
				setPaymentData({
					...paymentData,
					[id]: value,
				});
				break;
			}

			case "bank_id":
			case "document_type_id":
			case "pay_lis_payment_amount":
			case "pay_lis_ledger_account":
			case "pay_lis_origin_money_nit":
			case "pay_lis_holder_immovable": {
				if (value !== "") {
					if (!isNaN(value)) {
						setPaymentData({
							...paymentData,
							[id]: Number(value),
						});
					}
				} else {
					setPaymentData({
						...paymentData,
						[id]: "",
					});
				}
				break;
			}

			default:
				//setPaymentData({ ...paymentData, [id]: value });
				break;
		}
	};

	const handleUploadFiles = async (e) => {
		if (e.target.files.length > 0) {
			let droppedFile = e.target.files[0];
			let ext = droppedFile.type.split("/")[1];

			if (ext === "csv") {
				toast.success("Archivo cargado exitosamente", {
					position: "center-top",
					style: {
						border: "1px solid #62D346",
					},
				});
				setUploadFiles(droppedFile);
			} else {
				toast((t) => (
					<span className="flex items-center gap-1">
						<span>
							El archivo tipo <b>'{ext.toUpperCase()}'</b> cargado
							no coincide con el formato requerido <b>'CSV'.</b>
						</span>
						<button
							className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
							onClick={() => toast.dismiss(t.id)}
						>
							Cerrar
						</button>
					</span>
				));
			}
		} else {
			toast((t) => (
				<span className="flex items-center gap-1">
					<span>Inserción no válida.</span>
					<button
						className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
						onClick={() => toast.dismiss(t.id)}
					>
						Cerrar
					</button>
				</span>
			));
		}
	};

	const handleDragNDropOver = (e) => {
		e.preventDefault();
		setDragNDrop(true);
	};

	const handleDrop = (e) => {
		e.preventDefault();

		if (e.dataTransfer.files.length > 0) {
			let droppedFile = e.dataTransfer.files[0];
			let ext = droppedFile.type.split("/")[1];

			if (ext === "csv") {
				toast.success("Archivo cargado exitosamente", {
					position: "center-top",
					style: {
						border: "1px solid #62D346",
					},
				});
				setUploadFiles(droppedFile);
			} else {
				toast((t) => (
					<span className="flex items-center gap-1">
						<span>
							El archivo tipo <b>'{ext.toUpperCase()}'</b> cargado
							no coincide con el formato requerido <b>'CSV'.</b>
						</span>
						<button
							className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
							onClick={() => toast.dismiss(t.id)}
						>
							Cerrar
						</button>
					</span>
				));
			}
		} else {
			toast((t) => (
				<span className="flex items-center gap-1">
					<span>Inserción no válida.</span>
					<button
						className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
						onClick={() => toast.dismiss(t.id)}
					>
						Cerrar
					</button>
				</span>
			));
		}

		handleDragNDropOut();
	};

	const handleDragNDropOut = () => {
		setDragNDrop(false);
	};

	const handleUploadedFile = () => {
		if (!uploadFiles) {
			return (
				<div
					dropping={dragNDrop === true ? "true" : "false"}
					onDragOver={handleDragNDropOver}
					onDragLeave={handleDragNDropOut}
					onDrop={handleDrop}
					className="droparea mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/40 px-6 py-10"
				>
					<div className="text-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="mx-auto h-12 w-12 text-gray-300"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
							/>
						</svg>
						<div className="mt-4 flex text-sm leading-6 text-gray-600">
							<label
								forhtml="file-upload"
								className="relative cursor-pointer rounded-md font-semibold"
							>
								<span
									className="text-blue-500 hover:text-blue-600"
									title="Cargar archivo"
								>
									Carga un archivo
								</span>
								<input
									ref={fileRef}
									id="file-upload"
									name="file-upload"
									type="file"
									className="sr-only"
									accept=".csv"
									onChange={(e) => handleUploadFiles(e)}
								/>
							</label>
							<p className="pl-1">o arrastra y suelta</p>
						</div>
						<p className="text-xs leading-5 text-gray-600">
							Únicamente archivos CSV
						</p>
					</div>
				</div>
			);
		} else {
			return (
				<div className="flex h-14 flex-row rounded-md bg-gray-200">
					<div className="flex w-16 items-center justify-center">
						<svg
							className="h-8"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 20 20"
						>
							<path
								fill="#fff"
								d="M18 7h-2.5c-.827 0-1.5-.673-1.5-1.5V2H6v16h12V7z"
							/>
							<path
								fill="#fff"
								d="M18 6v-.293l-3-3V5.5c0 .275.225.5.5.5H18z"
							/>
							<path
								fill="#605E5C"
								fillRule="evenodd"
								d="M18.707 5 15 1.293A1 1 0 0 0 14.293 1H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5.707A1 1 0 0 0 18.707 5zM18 5.707V6h-2.5a.501.501 0 0 1-.5-.5V2.707l3 3zM6 2.2v15.6c0 .11.09.2.2.2h11.6a.2.2 0 0 0 .2-.2V7h-2.5A1.5 1.5 0 0 1 14 5.5V2H6.2a.2.2 0 0 0-.2.2z"
								clipRule="evenodd"
								opacity=".64"
							/>
							<path
								fill="#134A2C"
								fillRule="evenodd"
								d="M12.5 12h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"
								clipRule="evenodd"
							/>
							<path
								fill="#21A366"
								fillRule="evenodd"
								d="M12.5 10h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"
								clipRule="evenodd"
							/>
							<path
								fill="#33C481"
								d="M16.5 8h-1c-.275 0-.5.225-.5.5s.225.5.5.5h1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5z"
							/>
							<path
								fill="#21A366"
								d="M13.5 8h-1c-.275 0-.5.225-.5.5s.225.5.5.5h1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5z"
							/>
							<path
								fill="#107C41"
								d="M2 16h8a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1z"
							/>
							<path
								fill="#fff"
								d="m4.606 14 3.707-6H7.15l-3.755 6h1.21z"
							/>
							<path
								fill="#fff"
								d="M7.374 14 3.668 8H4.83l3.754 6h-1.21z"
							/>
						</svg>
					</div>
					<div className="flex w-full justify-between">
						<div className="flex flex-col justify-center">
							<span
								className="max-w-xs overflow-hidden text-ellipsis whitespace-nowrap font-semibold"
								title={uploadFiles.name}
							>
								{uploadFiles.name}
							</span>
							<span className="text-sm">
								{Number(uploadFiles.size) * 0.001 + "KB"}
							</span>
						</div>
						<div
							className="flex h-full w-14 cursor-pointer items-center justify-center rounded-br-md rounded-tr-md fill-gray-500 hover:bg-red-300 hover:fill-red-500"
							title="Eliminar"
							onClick={() => setUploadFiles()}
						>
							<TrashFilledIcon className="h-6 fill-inherit" />
						</div>
					</div>
				</div>
			);
		}
	};

	const handleSelectedValue = (key) => {
		switch (key) {
			case "category": {
				let value = categories.filter(
					(category) => category.value === paymentData.category_id
				);

				return value;
				break;
			}

			default:
				break;
		}
	};

	const handleCurrency = (value) => {
		let formatting_options = {
			style: "currency",
			currency: "COP",
			minimumFractionDigits: 0,
		};

		let currency = new Intl.NumberFormat("es-CO", formatting_options);
		let finalCurreny = currency.format(value);

		return finalCurreny;
	};

	const handleUpdatePaylistState = async () => {
		console.log(selectedItem.id);
		setModal({ show: false });
		setStatus(true);
		const toastId = toast.loading("Actualizando...");

		let response = await updatePaylistState(selectedItem.id);

		switch (response) {
			case "PAYLIST_NOT_FOUND": {
				toast.error("No se encontró la lista de pago", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;
			}

			case "PayList state has been updated successfully!": {
				toast.success("Pago inhabilitado exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;
			}

			case "ERROR_UPDATE_PAYLIST_STATUS":
			default: {
				toast.error("Ocurrió un error al inhabilitar el pago", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;
			}
		}

		refreshPayment();
		await delay(2000);
		toast.dismiss(toastId);
		setStatus(false);
	};

	const handleModalStep = () => {
		switch (modal.modal) {
			case "Notas": {
				return (
					<>
						<div className="modal-content-footer">
							<div className="flex w-full flex-col gap-3">
								<div className="relative self-end">
									<input
										type="search"
										name="search"
										id="search"
										title="Buscar por
Nombre del usuario
Cuerpo de la nota"
										className="block rounded-md border-gray-200 py-1.5 pr-10 placeholder:text-gray-400"
										onChange={(e) =>
											setQuery(e.target.value)
										}
										placeholder="Ej: Humpty Alexander Dumpty"
									/>
									<div className="pointer-events-none absolute bottom-0 right-0 top-0 flex items-center pr-3">
										<MagnifyingGlassIcon className="h-5 w-5 stroke-gray-400" />
									</div>
								</div>
								<div className="max-h-96 overflow-y-auto overflow-x-hidden">
									<ul className="flex flex-col-reverse gap-2">
										{items.notes.length > 0 ? (
											items.notes
												.filter((note) => {
													return (
														note.pay_not_type ===
														"INFORMATIVA"
													);
												})
												.filter((note) => {
													const searchItem =
														query.toLocaleLowerCase();
													const value =
														note.user.name.toLocaleLowerCase();
													const value2 =
														note.pay_not_description.toLocaleLowerCase();
													if (!searchItem)
														return true;
													return (
														value
															.toLowerCase()
															.indexOf(
																searchItem.toLowerCase()
															) > -1 ||
														value2
															.toLowerCase()
															.indexOf(
																searchItem.toLowerCase()
															) > -1
													);
												})
												.map((note, id) => (
													<li
														key={id}
														className={`py-3 px-4 text-base rounded-lg${
															note.pay_not_type ===
															"RECHAZO"
																? " bg-red-100 border border-red-300"
																: " bg-gray-100"
														}`}
													>
														<div className="mb-2 flex items-center justify-between">
															<div className="flex items-center gap-1">
																<p
																	title={
																		note
																			.user
																			.name
																	}
																	className="inline-flex items-center gap-2 text-sm text-gray-900"
																>
																	<UserIcon className="h-6 w-6 rounded-full bg-gray-300 p-1" />
																	{
																		note
																			.user
																			.name
																	}
																</p>
																{" - "}
																<p
																	title={formatDateUTC(
																		note.pay_not_date
																	).shortDateTime24()}
																	className="text-sm text-gray-600"
																>
																	{formatDateUTC(
																		note.pay_not_date
																	).shortDateTime24()}
																</p>
															</div>
														</div>
														{note.pay_not_type ===
														"RECHAZO" ? (
															<div className="flex flex-row items-center gap-2">
																<BlockIcon className="h-4 w-4 stroke-red-500" />
																<span className="font-bold text-red-500">
																	Motivo
																	rechazo
																</span>
															</div>
														) : null}
														<p className="text-gray-800">
															{
																note.pay_not_description
															}
														</p>
													</li>
												))
										) : (
											<li
												className={`py-3 px-4 text-base rounded-lg bg-gray-100`}
											>
												<div className="mb-2 flex items-center justify-between">
													<div className="flex items-center gap-1">
														<p
															title="Usuario"
															className="inline-flex items-center gap-2 text-sm text-gray-900"
														>
															<UserIcon className="h-6 w-6 rounded-full bg-gray-300 p-1" />
															Usuario
														</p>
														{" - "}
													</div>
												</div>
												<p className="text-gray-800">
													Aún no hay notas
												</p>
											</li>
										)}
									</ul>
								</div>
							</div>
						</div>
						<div className="mb-2"></div>
					</>
				);

				break;
			}

			case "Logs": {
				return (
					<>
						<div className="modal-content-footer">
							<div className="flex w-full flex-col gap-3">
								<div className="relative self-end">
									<input
										type="search"
										name="search"
										id="search"
										title="Buscar por
Nombre del usuario"
										className="block rounded-md border-gray-200 py-1.5 pr-10 placeholder:text-gray-400"
										onChange={(e) =>
											setQuery(e.target.value)
										}
										placeholder="Ej: Humpty Alexander Dumpty"
									/>
									<div className="pointer-events-none absolute bottom-0 right-0 top-0 flex items-center pr-3">
										<MagnifyingGlassIcon className="h-5 w-5 stroke-gray-400" />
									</div>
								</div>
								<div className="max-h-96 overflow-y-auto overflow-x-hidden">
									<ul className="flex flex-col-reverse gap-2">
										{items.notes.length > 0 ? (
											items.notes
												.filter((note) => {
													return (
														note.pay_not_type !==
														"INFORMATIVA"
													);
												})
												.filter((note) => {
													const searchItem =
														query.toLocaleLowerCase();
													const value =
														note.user.name.toLocaleLowerCase();
													if (!searchItem)
														return true;
													return (
														value
															.toLowerCase()
															.indexOf(
																searchItem.toLowerCase()
															) > -1
													);
												})
												.map((note, id) => (
													<li
														key={id}
														className={`py-3 px-4 text-base rounded-lg ${
															choices[
																note
																	.pay_not_type
															]
														}`}
													>
														<div className="mb-2 flex items-center justify-between">
															<div className="flex items-center gap-1">
																<p
																	title={
																		note
																			.user
																			.name
																	}
																	className="inline-flex items-center gap-2 text-sm text-gray-900"
																>
																	<UserIcon className="h-6 w-6 rounded-full bg-gray-300 p-1" />
																	{
																		note
																			.user
																			.name
																	}
																</p>
																{" - "}
																<p
																	title={formatDateUTC(
																		note.pay_not_date
																	).shortDateTime24()}
																	className="text-sm text-gray-600"
																>
																	{formatDateUTC(
																		note.pay_not_date
																	).shortDateTime24()}
																</p>
															</div>
														</div>
														{note.pay_not_type ===
														"RECHAZO" ? (
															<div className="flex flex-row items-center gap-2">
																<BlockIcon className="h-4 w-4 stroke-red-500" />
																<span className="font-bold text-red-500">
																	Motivo
																	rechazo
																</span>
															</div>
														) : null}
														<p className="text-gray-800">
															{capitalize(
																note.pay_not_description
															)}
														</p>
													</li>
												))
										) : (
											<li
												className={`py-3 px-4 text-base rounded-lg bg-gray-100`}
											>
												<div className="mb-2 flex items-center justify-between">
													<div className="flex items-center gap-1">
														<p
															title="Usuario"
															className="inline-flex items-center gap-2 text-sm text-gray-900"
														>
															<UserIcon className="h-6 w-6 rounded-full bg-gray-300 p-1" />
															Usuario
														</p>
														{" - "}
													</div>
												</div>
												<p className="text-gray-800">
													Aún no hay notas
												</p>
											</li>
										)}
									</ul>
								</div>
							</div>
						</div>
						<div className="mb-2"></div>
					</>
				);

				break;
			}

			case "Aprobar": {
				return (
					<>
						<div className="modal-content-body">
							<span>
								¿Está seguro que desea cambiar el estado de este
								pago a <b>aprobado</b>?
							</span>
						</div>
						<div className="modal-content-footer justify-end">
							<button
								className="rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-black"
								title="Cancelar"
								onClick={() => {
									setPaymentData({ note: "" });
									setModal({ modal: "", show: false });
								}}
							>
								Cancelar
							</button>
							<button
								className="rounded-md bg-emerald-500 px-3 py-2 text-sm font-semibold text-white hover:bg-emerald-400"
								title="Aprobar"
								onClick={() => handleFormSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Aprobar
							</button>
						</div>
					</>
				);

				break;
			}

			case "Rechazar": {
				return (
					<>
						<div className="modal-content-body">
							<span>
								¿Está seguro que desea cambiar el estado de este
								pago a <b>rechazado</b>?
							</span>
							<div>
								<form>
									<div className="mb-4">
										<label
											htmlFor="note"
											className="block text-sm font-medium leading-6 text-gray-900"
											required
										>
											Nota
											<br />
											<span className="font-normal text-gray-600">
												Ingresa el motivo por el que
												rechazas el pago.
											</span>
										</label>
										<div className="mt-2">
											<input
												id="note"
												type="text"
												className="w-full"
												onChange={(e) =>
													handleFormChange(
														e.target.id,
														e.target.value
													)
												}
												placeholder="Ej: Rechazo esta factura por..."
												autoFocus
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="modal-content-footer justify-end">
							<button
								className="rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-black"
								title="Cancelar"
								onClick={() => {
									setPaymentData({ note: "" });
									setModal({ modal: "", show: false });
								}}
							>
								Cancelar
							</button>
							<button
								className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white hover:bg-red-400"
								title="Rechazar"
								onClick={() => handleFormSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Rechazar
							</button>
						</div>
					</>
				);

				break;
			}

			case "Procesar": {
				return (
					<>
						<div className="modal-content-body">
							<span>
								¿Está seguro que desea procesar el pago?
							</span>
							<div>
								<form>
									<div className="mb-4">
										<label
											htmlFor="note"
											className="block text-sm font-medium leading-6 text-gray-900"
											required
										>
											Cuenta de pago
											<br />
											<span className="font-normal text-gray-600">
												Selecciona la cuenta de pago.
											</span>
										</label>
										<div className="mt-2">
											<select
												id="pay_account_id"
												className="select"
												onChange={(e) =>
													handleFormChange(
														e.target.id,
														e.target.value
													)
												}
											>
												{paymentData?.pay_account_id ? null : (
													<option>Selecciona</option>
												)}
												{payAccounts.map((account) => (
													<option
														key={account.id}
														value={account.id}
													>
														{
															account.pay_acc_description
														}
													</option>
												))}
											</select>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="modal-content-footer justify-end">
							<button
								className="rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-black"
								title="Cancelar"
								onClick={() => {
									setPaymentData({ pay_account_id: "" });
									setModal({ modal: "", show: false });
								}}
							>
								Cancelar
							</button>
							<button
								className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white hover:bg-blue-400"
								title="Procesar"
								onClick={() => handleFormSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Procesar
							</button>
						</div>
					</>
				);

				break;
			}

			case "Pagar": {
				return (
					<>
						<div className="modal-content-body">
							<span>
								¿Está seguro que desea cambiar el estado de este
								pago a <b>pagado</b>?
							</span>
						</div>
						<div className="modal-content-footer justify-end">
							<button
								className="rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-black"
								title="Cancelar"
								onClick={() => {
									setModal({ modal: "", show: false });
								}}
							>
								Cancelar
							</button>
							<button
								className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white hover:bg-blue-400"
								title="Pagar"
								onClick={() => handleFormSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Pagar
							</button>
						</div>
					</>
				);

				break;
			}

			case "Iniciar contabilización": {
				return (
					<>
						<div className="modal-content-body">
							<span>
								¿Está seguro que desea iniciar la
								contabilización del pago?
							</span>
						</div>
						<div className="modal-content-footer justify-end">
							<button
								className="rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-black"
								title="Cancelar"
								onClick={() => {
									setModal({ modal: "", show: false });
								}}
							>
								Cancelar
							</button>
							<button
								className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white hover:bg-blue-400"
								title="Iniciar contabilización"
								onClick={() => handleFormSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Iniciar contabilización
							</button>
						</div>
					</>
				);

				break;
			}

			case "Contabilizar": {
				return (
					<>
						<div className="modal-content-body">
							<span>
								¿Está seguro que desea contabilizar el pago?
							</span>
						</div>
						<div className="modal-content-footer justify-end">
							<button
								className="rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-black"
								title="Cancelar"
								onClick={() => {
									setModal({ modal: "", show: false });
								}}
							>
								Cancelar
							</button>
							<button
								className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white hover:bg-blue-400"
								title="Contabilizar"
								onClick={() => handleFormSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Contabilizar
							</button>
						</div>
					</>
				);

				break;
			}

			case "Individual": {
				return (
					<>
						<div className="modal-content-body">
							<form ref={formRef} onSubmit={handleFormSubmit}>
								<div className="mb-2">
									<h2 className="text-base font-semibold leading-7 text-gray-900">
										Pago
									</h2>
									<span className="font-normal text-gray-600">
										Ingresa la información básica del pago
									</span>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Descripción del pago
									</label>
									<div className="mt-2">
										<input
											id="pay_description"
											name="pay_description"
											type="text"
											className="w-full cursor-not-allowed bg-gray-100"
											defaultValue={
												selectedItem?.payment
													.pay_description
											}
											disabled={true}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="category_id"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Categoría
									</label>
									<div className="mt-2">
										<Select
											options={categories}
											onChange={(value) => {
												handlePaymentForm(
													"category_id",
													value
												);
											}}
											value={handleSelectedValue(
												"category"
											)}
											searchable={false}
											capitalizeValue={true}
											isMulti
											placeholder="Selecciona la categoría"
										/>
									</div>
								</div>
								<hr className="py-2" />
								<div className="mb-2">
									<h2 className="text-base font-semibold leading-7 text-gray-900">
										Titular
									</h2>
									<span className="font-normal text-gray-600">
										Ingresa la información básica del
										titular
									</span>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_holder_name"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Nombre del titular
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_holder_name"
											name="pay_lis_holder_name"
											type="text"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											defaultValue={
												selectedItem?.order[0]
													.pay_lis_holder_name
											}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="document_type_id"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Tipo de documento
									</label>
									<div className="mt-2">
										{
											<select
												ref={selectRef}
												id="document_type_id"
												name="document_type_id"
												className="input w-full"
												defaultValue={
													selectedItem?.order[0]
														.documenttype.id
												}
												onChange={(e) =>
													handlePaymentForm(
														e.target.id,
														e.target.value
													)
												}
											>
												{!paymentData.document_type_id ? (
													<option>
														Selecciona...
													</option>
												) : null}
												{documentTypes?.map(
													(document) => (
														<option
															key={document.id}
															value={document.id}
														>
															{capitalize(
																document.doc_typ_description
															)}
														</option>
													)
												)}
											</select>
										}
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_holder_document_number"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Número de documento
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_holder_document_number"
											name="pay_lis_holder_document_number"
											type="text"
											className="w-full"
											defaultValue={
												selectedItem?.order[0]
													.pay_lis_holder_document_number
											}
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_holder_mail"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Email del titular
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_holder_mail"
											name="pay_lis_holder_mail"
											type="email"
											className="w-full"
											defaultValue={
												selectedItem?.order[0]
													.pay_lis_holder_mail
											}
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_holder_immovable"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Inmueble
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_holder_immovable"
											name="pay_lis_holder_immovable"
											type="number"
											className="w-full"
											defaultValue={
												selectedItem?.order[0]
													.pay_lis_holder_immovable
											}
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										/>
									</div>
								</div>
								<hr className="py-2" />
								<div className="mb-2">
									<h2 className="text-base font-semibold leading-7 text-gray-900">
										Cuenta
									</h2>
									<span className="font-normal text-gray-600">
										Ingresa la información básica de la
										cuenta
									</span>
								</div>
								<div className="mb-4">
									<label
										htmlFor="bank_id"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Banco
									</label>
									<div className="mt-2">
										<select
											ref={selectRef}
											id="bank_id"
											name="bank_id"
											className="input w-full"
											defaultValue={
												selectedItem?.order[0].bank.id
											}
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										>
											{!paymentData.bank_id ? (
												<option>Selecciona...</option>
											) : null}
											{banks?.map((bank) => (
												<option
													key={bank.id}
													value={bank.id}
												>
													{bank.ban_description}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_account_number"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Número de cuenta
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_account_number"
											name="pay_lis_account_number"
											type="number"
											className="w-full"
											defaultValue={
												selectedItem?.order[0]
													.pay_lis_account_number
											}
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_account_type"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Tipo de cuenta
									</label>
									<div className="mt-2">
										{
											<select
												ref={selectRef}
												id="pay_lis_account_type"
												name="pay_lis_account_type"
												className="input w-full"
												defaultValue={
													selectedItem?.order[0]
														.pay_lis_account_type
												}
												onChange={(e) =>
													handlePaymentForm(
														e.target.id,
														e.target.value
													)
												}
											>
												{!paymentData.pay_lis_account_type ? (
													<option>
														Selecciona...
													</option>
												) : null}
												<option value={"AHORROS"}>
													Ahorros
												</option>
												<option value={"CORRIENTE"}>
													Corriente
												</option>
											</select>
										}
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_payment_amount"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Valor
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_payment_amount"
											name="pay_lis_payment_amount"
											type="number"
											className="w-full"
											defaultValue={
												selectedItem?.order[0]
													.pay_lis_payment_amount
											}
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											min={0}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_ledger_account"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Cuenta contable
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_ledger_account"
											name="pay_lis_ledger_account"
											type="number"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="84743"
											min={0}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_origin_money_nit"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										NIT desde donde sale el dinero
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_origin_money_nit"
											name="pay_lis_origin_money_nit"
											type="number"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="4585115"
											min={0}
										/>
									</div>
								</div>
							</form>
						</div>
						<div
							className={`${
								modal.modal === ""
									? "justify-between"
									: "justify-end"
							} modal-content-footer text-sm font-medium leading-6 text-gray-900`}
						>
							{status === true ? (
								""
							) : (
								<button
									className="px-3 py-2 text-sm font-semibold text-gray-900"
									onClick={() => {
										setPaymentData({});
										setUploadFiles();
										setModal({ modal: "", show: false });
									}}
									title="Cancelar"
								>
									Cancelar
								</button>
							)}
							<button
								className="btn primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								title="Cargar remesa de pago"
								onClick={() => formRef.current.requestSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar
							</button>
						</div>
					</>
				);
				break;
			}

			case "Remesa": {
				return (
					<>
						<div className="modal-content-body">
							<form
								ref={formRef}
								onSubmit={(e) => handleFormSubmit(e)}
							>
								<div className="mb-4">
									<h2 className="text-base font-semibold leading-7 text-gray-900">
										Pago
									</h2>
									<span className="font-normal text-gray-600">
										Ingresa la información básica del pago
									</span>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Descripción del pago
									</label>
									<div className="mt-2">
										<input
											id="pay_description"
											name="pay_description"
											type="text"
											className="w-full cursor-not-allowed bg-gray-100"
											defaultValue={
												selectedItem?.payment
													.pay_description
											}
											disabled={true}
										/>
									</div>
								</div>
								{/* {<div className="mb-4">
									<label
										htmlFor="pay_description"
										className="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900"
										required
									>
										Concepto general
										<QuestionMarkIcon
											className="h-4 w-4 cursor-pointer hover:fill-black hover:stroke-white"
											onClick={() =>
												setQuestionMark({
													show: !questionMark.show,
												})
											}
										/>
									</label>
									<div className="mt-2">
										<input
											id="general_concept"
											name="general_concept"
											type="text"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="Ej: Pago canon"
											autoFocus
										/>
										{questionMark.show ? (
											<p className="mt-2 text-xs">
												Cuando se carga una remesa de
												pago y en ella se encuentran
												múltiples pagos para un mismo
												cliente, el sistema unificará
												estos pagos bajo un mismo
												concepto en el archivo plano.
												<br />
												Para ello se requiere
												especificar el concepto general
												de estos pagos unificados.
											</p>
										) : null}
									</div>
								</div>} */}
								<div className="mb-4">
									<label
										htmlFor="cat_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Categoría
									</label>
									<div className="mt-2">
										<Select
											options={categories}
											onChange={(value) => {
												handlePaymentForm(
													"category_id",
													value
												);
											}}
											value={handleSelectedValue(
												"category"
											)}
											searchable={false}
											capitalizeValue={true}
											isMulti
											placeholder="Selecciona la categoría"
										/>
									</div>
								</div>
								<hr className="py-2" />
								<div className="mb-4">
									<label
										htmlFor="cat_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Archivo
									</label>
									<div className="mt-2">
										{handleUploadedFile()}
									</div>
								</div>
							</form>
						</div>
						<div
							className={`${
								modal.modal === ""
									? "justify-between"
									: "justify-end"
							} modal-content-footer text-sm font-medium leading-6 text-gray-900`}
						>
							{status === true ? (
								""
							) : (
								<button
									className="px-3 py-2 text-sm font-semibold text-gray-900"
									onClick={() => {
										setPaymentData({});
										setUploadFiles();
										setModal({ modal: "", show: false });
									}}
									title="Cancelar"
								>
									Cancelar
								</button>
							)}
							<button
								className="btn primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								title="Cargar remesa de pago"
								onClick={() => formRef.current.requestSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar
							</button>
						</div>
					</>
				);
				break;
			}

			case "Pago": {
				return (
					<>
						<div className="modal-content-body">
							<span>
								¿Está seguro que desea inhabilitar este pago?
							</span>
							<div className="border-b pb-1">
								<h3 className="text-lg font-bold text-gray-800">
									{selectedItem?.pay_lis_payment_concept}
								</h3>
								<p className="text-base font-light">
									Información del pago
								</p>
							</div>
							<dl>
								<dt className="mb-2 block text-sm font-medium text-gray-900">
									Banco
								</dt>
								<dd className="mb-2 text-sm font-light text-gray-700">
									{selectedItem.bank.ban_description}
								</dd>
								<dt className="mb-2 block text-sm font-medium text-gray-900">
									Cuenta
								</dt>
								<dd className="mb-2 text-sm font-light text-gray-700">
									{selectedItem.pay_lis_account_number}
									<span className="flex-shrink-0 text-gray-400">
										{` - ${capitalize(
											selectedItem.pay_lis_account_type
										)}`}
									</span>
								</dd>
								<dt className="mb-2 block text-sm font-medium text-gray-900">
									Valor
								</dt>
								<dd className="mb-2 text-sm font-light text-gray-700">
									{handleCurrency(
										selectedItem.pay_lis_payment_amount
									)}
								</dd>
								<dt className="mb-2 block text-sm font-medium text-gray-900">
									Nombre titular
								</dt>
								<dd className="mb-2 text-sm font-light text-gray-700">
									{selectedItem.pay_lis_holder_name}
								</dd>
								<dt className="mb-2 block text-sm font-medium text-gray-900">
									Email titular
								</dt>
								<dd className="mb-2 text-sm font-light text-gray-700">
									{selectedItem.pay_lis_holder_mail}
								</dd>
								<dt className="mb-2 block text-sm font-medium text-gray-900">
									Documento
								</dt>
								<dd className="mb-2 text-sm font-light text-gray-700">
									{
										selectedItem.pay_lis_holder_document_number
									}
									<span className="flex-shrink-0 text-gray-400">
										{` - ${capitalize(
											selectedItem.documenttype
												.doc_typ_description
										)}`}
									</span>
								</dd>
								<dt className="mb-2 block text-sm font-medium text-gray-900">
									Inmueble
								</dt>
								<dd className="mb-2 text-sm font-light text-gray-700">
									<div className="flex items-center gap-1 text-gray-700">
										<HomeIcon className="h-4 w-4" />
										{selectedItem.pay_lis_holder_immovable ??
											"---"}
									</div>
								</dd>
							</dl>
						</div>
						<div
							className={`${
								modal.modal === ""
									? "justify-between"
									: "justify-end"
							} modal-content-footer text-sm font-medium leading-6 text-gray-900`}
						>
							{status === true ? (
								""
							) : (
								<button
									className="px-3 py-2 text-sm font-semibold text-gray-900"
									onClick={() => {
										setPaymentData({});
										setUploadFiles();
										setModal({ modal: "", show: false });
									}}
									title="Cancelar"
								>
									Cancelar
								</button>
							)}
							<button
								className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
								title="Inhabilitar"
								onClick={() => handleUpdatePaylistState()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Inhabilitar
							</button>
						</div>
					</>
				);
				break;
			}

			case "":
			default:
				break;
		}
	};

	const handleOrderReference = (id) => {
		let reference = "000000";
		id = id.toString();

		reference = reference.slice(0, reference.length - id.length) + id;
		return "#" + reference;
	};

	const handleFirstPaymentData = (value) => {
		if (!value.pay_lis_payment_concept) {
			let payment = value.payment;
			let order = value.order;

			let paymentObj = {
				id: payment.id,
				category_id: payment.category.id,
				pay_lis_holder_name: order[0].pay_lis_holder_name,
				pay_lis_holder_mail: order[0].pay_lis_holder_mail,
				pay_lis_account_type: order[0].pay_lis_account_type,
				pay_lis_holder_document_number:
					order[0].pay_lis_holder_document_number,
				document_type_id: order[0].documenttype.id,
				bank_id: order[0].bank.id,
				pay_lis_account_number: order[0].pay_lis_account_number,
				pay_lis_payment_amount: order[0].pay_lis_payment_amount,
				pay_lis_holder_immovable: Number(
					order[0].pay_lis_holder_immovable
				),
			};

			setPaymentData(paymentObj);
		}
	};

	return (
		<>
			<Helmet>
				<title>Orden {handleOrderReference(actualCrumb)}</title>
			</Helmet>
			<div className="home">
				<Sidebar />
				<div className="content">
					<Topbar />
					<PaymentDetails
						items={items}
						refreshItems={() => refreshPayment()}
						setModal={setModal}
						showOffCanvas={() => setIsOpen(true)}
						setSelectedItem={(value) => {
							handleFirstPaymentData(value);
							setSelectedItem(value);
						}}
					/>
				</div>
				<Toaster />
			</div>
			<OffCanvas
				onClose={() => setIsOpen(false)}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				staticOffCanvas={false}
				closeOnOutsideClick={false}
			>
				<div className="offcanvas-body mt-5 h-3/5"></div>
			</OffCanvas>
			<Modal
				onClose={() => {
					setPaymentData({});
					setModal({ modal: "", show: false });
				}}
				show={modal.show}
				modalTitle={modal.modal}
			>
				{handleModalStep()}
			</Modal>
		</>
	);
}

export default PaymentDetail;
