import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import Sidebar from "../layouts/Sidebar";
import Topbar from "../layouts/Topbar";
import Modal from "../hooks/useModal";
import UsersList from "../features/users/components/UserList";
import Select from "../components/Select";
import getRoles from "../features/roles/services/getRoles";
import { prepareObjectArray } from "../utils/format";
import getUsers from "../features/users/services/getUsers";
import delay from "../utils/delay";
import updateUserRoles from "../features/users/services/updateUserRoles";
import OffCanvas from "../components/OffCanvas";
import { isEqual } from "../utils/compare";
import { useAuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { NO_ROLES } from "../config/routes/paths";

const emptyFilters = {
	sort: true,
	roles: "Todos",
};

function Users() {
	const navigate = useNavigate();

	const { userData } = useAuthContext();

	if (userData && userData.appRoles.length <= 0) {
		navigate(`/no-roles`);
	}

	var appRoles = [];
	for (let i = 0; i < userData.appRoles.length; i++) {
		appRoles = [...appRoles, userData.appRoles[i].rol_description];
	}

	const [userModal, setUserModal] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState(
		sessionStorage.getItem("usersFilters")
			? JSON.parse(sessionStorage.getItem("usersFilters"))
			: sessionStorage.setItem(
					"usersFilters",
					JSON.stringify(emptyFilters)
			  )
	);
	const [filteredData, setFilteredData] = useState();

	const [status, setStatus] = useState(false);
	const [items, setItems] = useState();
	const [roles, setRoles] = useState();
	const [preparedRoles, setPreparedRoles] = useState();
	const [selectedUser, setSelectedUser] = useState();
	const [selectedRoles, setSelectedRoles] = useState([]);

	const handleResetFilters = () => {
		setSelectedFilters(emptyFilters);
		setFilteredData(items);
		sessionStorage.setItem("usersFilters", JSON.stringify(emptyFilters));
	};

	const handleSettedFilters = (providedItems) => {
		providedItems = providedItems ?? items;

		if (providedItems) {
			let itemsToFilter = [...providedItems];
			let actualFilters = JSON.parse(
				sessionStorage.getItem("usersFilters")
			);

			setFilteredData();
			for (var key of Object.getOwnPropertyNames(selectedFilters)) {
				if (key !== "undefined") {
					switch (key) {
						case "roles": {
							if (
								actualFilters?.roles &&
								actualFilters?.roles !== "Todos"
							) {
								let usersItems = [];

								itemsToFilter.map((item) =>
									item.roles.filter((role) => {
										if (
											role.rol_description ===
											actualFilters.roles
										) {
											usersItems.push(item);
										}
									})
								);

								itemsToFilter = [...usersItems];
							}

							break;
						}

						default:
							break;
					}
				}
			}

			setFilteredData(itemsToFilter.length > 0 ? itemsToFilter : [""]);
		}
	};

	useEffect(() => {
		handleSettedFilters();
	}, [selectedFilters]);

	useEffect(() => {
		handleSettedFilters();
	}, []);

	const searchForRole = (roleToSearch) => {
		let isThere = appRoles.findIndex(
			(role) => role.toLowerCase() === roleToSearch.toLowerCase()
		);
		return isThere > -1 ? true : false;
	};

	if (searchForRole("administrador") < 0) {
		navigate("/pagos");
	}

	const refreshUsers = async () => {
		let actualSortOrder = JSON.parse(
			sessionStorage.getItem("usersFilters")
		);
		const { users } = await getUsers();

		if (Object.keys(selectedFilters).length > 1) {
			handleSettedFilters(users);
		} /*else {
			if (actualSortOrder.sort === true) {
				// Descending
				users.sort((a, b) => new Date(b.created) - new Date(a.created));
			} else {
				//Ascending
				users.sort((a, b) => new Date(a.created) - new Date(b.created));
			}
		}*/

		setItems(users);
	};

	const refreshRoles = async () => {
		const { roles } = await getRoles();
		let preparedArray = prepareObjectArray(roles);
		setRoles(roles);
		setPreparedRoles(preparedArray);
	};

	useEffect(() => {
		refreshRoles();
		refreshUsers();

		const interval = setInterval(() => {
			refreshRoles();
			refreshUsers();
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	const handleUpdateRoles = async () => {
		setStatus(true);
		setUserModal(false);
		const toastId = toast.loading("Actualizando...");
		const body = { id: selectedUser.id };
		body.roles = [...selectedRoles];

		const request = await updateUserRoles(body);

		switch (request) {
			case "ERROR_ADDING_ROLES_TO_USER":
				toast.error("Ocurrió un error al actualizar el usuario", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "Roles added successfully!":
				toast.success("Usuario actualizado exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			default:
				toast.error("Ocurrió un error al actualizar el usuario", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;
		}

		refreshUsers();
		await delay(1000);
		toast.dismiss(toastId);
		setStatus(false);
	};

	const handleSettedForm = (column, value) => {
		switch (column) {
			case "roles": {
				if (selectedRoles.indexOf(value) > -1) {
					let preparedRoles = selectedRoles.filter(
						(rol) => rol !== value
					);
					setSelectedRoles(preparedRoles);
				} else {
					setSelectedRoles([...selectedRoles, value]);
				}
				break;
			}

			default:
				break;
		}
	};

	const handleSelectForm = (column) => {
		switch (column) {
			case "roles":
				if (roles) {
					let items = roles.filter((rol) =>
						selectedRoles.includes(rol.id)
					);

					items = prepareObjectArray(items);
					return items;
				}
				break;

			default:
				break;
		}
	};

	/**
	 * Handle the selected values of the filters inputs
	 *
	 * Validate if the filter it's already selected.
	 * Add or update the filter
	 *
	 * @param {string} id The filter's identifier
	 * @param {string} value The filter's value
	 */
	const handleSelectedFilters = (id, value) => {
		setSelectedFilters({ ...selectedFilters, [id]: value });
		sessionStorage.setItem(
			"usersFilters",
			JSON.stringify({ ...selectedFilters, [id]: value })
		);
	};

	const handleSelectValue = (id) => {
		let value = [];

		let originItem;
		if (selectedFilters[id]) {
			switch (id) {
				case "roles": {
					if (roles) {
						if (selectedFilters[id] === "Todos") {
							originItem = [{ value: 0, label: "Todos" }];
						} else {
							originItem = preparedRoles.filter((role) => {
								return role.label === selectedFilters[id];
							});
						}
						return originItem;
					}
					break;
				}

				default:
					break;
			}
		}

		return value;
	};

	return (
		<>
			<Helmet>
				<title>Usuarios</title>
			</Helmet>
			<div className="home">
				<Sidebar />
				<div className="content">
					<Topbar />
					<UsersList
						items={filteredData ?? items}
						refreshItems={() => refreshUsers()}
						showModal={() => setUserModal(true)}
						setSelectedUser={(value) => setSelectedUser(value)}
						selectedRoles={(value) => setSelectedRoles(value)}
						filters={selectedFilters}
						setFilter={handleSelectedFilters}
						resetFilters={() => handleResetFilters()}
						showOffCanvas={() => setIsOpen(true)}
					/>
				</div>
				<Toaster />
			</div>
			<OffCanvas
				onClose={() => setIsOpen(false)}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				staticOffCanvas={false}
				closeOnOutsideClick={false}
			>
				<div className="offcanvas-body h-3/5">
					{selectedFilters?.roles !== "Todos" ? (
						<span
							className="flex justify-end mt-1 text-sm text-blue-500 hover:text-blue-400 underline cursor-pointer"
							onClick={() => handleResetFilters()}
						>
							Limpiar filtros
						</span>
					) : (
						<br />
					)}
					<form>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Roles
							</label>
							<div className="mt-2">
								<Select
									options={
										preparedRoles
											? [
													{
														value: 0,
														label: "Todos",
													},
													...preparedRoles,
											  ] ?? preparedRoles
											: preparedRoles
									}
									onChange={(value) =>
										handleSelectedFilters(
											"roles",
											value[0].label
										)
									}
									value={handleSelectValue("roles")}
									capitalizeValue={true}
									multiple={false}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
					</form>
				</div>
			</OffCanvas>
			<Modal
				onClose={() => {
					setUserModal(false);
				}}
				show={userModal}
				modalTitle="Editar usuario"
			>
				<div className="modal-content-body h-52">
					<form>
						<div className="mb-4">
							<label
								htmlFor={"roles"}
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Roles
								<br />
								<span className="font-normal text-gray-600">
									Asigna los roles respectivos al usuario
								</span>
							</label>
							<div className="mt-2">
								<Select
									options={preparedRoles}
									onChange={(value) =>
										handleSettedForm("roles", value.value)
									}
									value={handleSelectForm("roles")}
									multiple={true}
									capitalizeValue={true}
									clearable={false}
									placeholder="Selecciona los roles"
									className="w-full"
								/>
							</div>
						</div>
						{/*<pre>
							<code>
								{JSON.stringify(
									secureLocalStorage.getItem(ITEM_DATA)?.roles,
									null,
									4
								)}
							</code>
								</pre>*/}
					</form>
				</div>
				<div className="modal-content-footer justify-end text-sm font-medium leading-6 text-gray-900">
					{status === true ? (
						""
					) : (
						<button
							className="px-3 py-2 text-sm font-semibold text-gray-900"
							onClick={() => {
								setUserModal(false);
							}}
							title="Cancelar"
						>
							Cancelar
						</button>
					)}
					<button
						className="btn primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
						title="Crear"
						onClick={() => handleUpdateRoles()}
						disabled={status === true ? true : false}
					>
						{status === true ? (
							<div className="lds-dual-ring button"></div>
						) : (
							""
						)}
						Guardar
					</button>
				</div>
			</Modal>
		</>
	);
}

export default Users;
