import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const addNote = async (body) => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "put",
		maxBodyLength: Infinity,
		url: "https://apipagoterceros.spagrupoinmobiliario.com/api/payments/addNote",
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		data: body,
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);

	if (data.errors) {
		return data.errors[0].msg;
	} else {
		return data.data.message;
	}
};

export default addNote;
