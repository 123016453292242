import React, { useEffect, useRef, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import Sidebar from "../layouts/Sidebar";
import Topbar from "../layouts/Topbar";
import Modal from "../hooks/useModal";
import CategoriesList from "../features/categories/components/CategoriesList";
import Select from "../components/Select";
import getRoles from "../features/roles/services/getRoles";
import { capitalize, prepareObjectArray } from "../utils/format";
import delay from "../utils/delay";
import updateUserRoles from "../features/users/services/updateUserRoles";
import OffCanvas from "../components/OffCanvas";
import { isEqual, isType } from "../utils/compare";
import getCategories from "../features/categories/services/getCategories";
import { ChevronIcon } from "../assets/svg/icons";
import getUsers from "../features/users/services/getUsers";
import createCategory from "../features/categories/services/createCategory";
import updateCategory from "../features/categories/services/updateCategory";
import { useAuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const emptyFilters = {
	sort: true,
	approvers: "Todos",
};

function Categories() {
	const navigate = useNavigate();
	const { userData } = useAuthContext();

	if (userData && userData.appRoles.length <= 0) {
		navigate(`/no-roles`);
	}

	const selecteRef = useRef();

	const [modal, setModal] = useState({ show: false });
	const [isOpen, setIsOpen] = useState(false);

	const [selectedFilters, setSelectedFilters] = useState(
		sessionStorage.getItem("categoriesFilters")
			? JSON.parse(sessionStorage.getItem("categoriesFilters"))
			: sessionStorage.setItem(
					"categoriesFilters",
					JSON.stringify(emptyFilters)
			  )
	);
	const [filteredData, setFilteredData] = useState([]);

	const [items, setItems] = useState();
	const [users, setUsers] = useState();
	const [remainingUsers, setRemainingUsers] = useState();

	const [status, setStatus] = useState(false);
	const [roles, setRoles] = useState();
	const [selectedItem, setSelectedItem] = useState();

	const [categoryData, setCategoryData] = useState({
		cat_approval_level: 1,
		approvers: [{ value: undefined, label: undefined }],
	});

	const handleResetFilters = () => {
		setSelectedFilters(emptyFilters);
		setFilteredData(items);
		sessionStorage.setItem(
			"categoriesFilters",
			JSON.stringify(emptyFilters)
		);
	};

	const handleSettedFilters = (providedItems) => {
		providedItems = providedItems ?? items;

		if (providedItems) {
			let itemsToFilter = [...providedItems];
			let actualFilters = JSON.parse(
				sessionStorage.getItem("categoriesFilters")
			);

			setFilteredData();
			for (var key of Object.getOwnPropertyNames(selectedFilters)) {
				if (key !== "undefined") {
					switch (key) {
						case "approvers": {
							if (
								actualFilters?.approvers &&
								actualFilters?.approvers !== "Todos"
							) {
								let approversItems = [];

								itemsToFilter.map((item) =>
									item.approvers.filter((approver) => {
										if (
											approver.name ===
											actualFilters.approvers
										) {
											approversItems.push(item);
										}
									})
								);

								itemsToFilter = [...approversItems];
							}

							break;
						}

						default:
							break;
					}
					//console.log("original", items, itemsToFilter);
				}
			}

			//await delay(500);
			//console.log(itemsToFilter);
			setFilteredData(itemsToFilter.length > 0 ? itemsToFilter : [""]);
		}
	};

	useEffect(() => {
		handleSettedFilters();
	}, [selectedFilters]);

	useEffect(() => {
		handleSettedFilters();
	}, []);

	const refreshCategories = async () => {
		let actualSortOrder = JSON.parse(
			sessionStorage.getItem("categoriesFilters")
		);

		let data = await getCategories();
		data = data.filter((d) => d.id !== 12 && d.id !== 13);

		if (Object.keys(selectedFilters).length > 1) {
			handleSettedFilters(data);
		} /*else {
			if (actualSortOrder.sort === true) {
				// Descending
				data.sort((a, b) => new Date(b.created) - new Date(a.created));
			} else {
				//Ascending
				data.sort((a, b) => new Date(a.created) - new Date(b.created));
			}
		}*/

		setItems(data);
	};

	const refreshUsers = async () => {
		const { users } = await getUsers();
		let filteredUsers = users.filter((user) => {
			for (let i = 0; i < user.roles.length; i++) {
				if (user.roles[i].rol_description === "APROBADOR") {
					delete user.microsoft_id;
					return user;
				}
			}
		});
		setUsers(prepareObjectArray(filteredUsers));
	};

	useEffect(() => {
		refreshUsers();
		refreshCategories();

		const interval = setInterval(() => {
			refreshUsers();
			refreshCategories();
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	const handleCategorySubmit = async () => {
		setStatus(true);
		setModal({ status: false });
		let response;
		const toastId = toast.loading("Actualizando...");
		let preparedCategory = {
			cat_description: "",
			cat_approval_level: "",
			approvers: {},
		};

		preparedCategory.cat_description = categoryData.cat_description;
		preparedCategory.cat_approval_level = categoryData.cat_approval_level;
		preparedCategory.approvers = categoryData.approvers;

		for (let i = 0; i < preparedCategory.approvers.length; i++) {
			preparedCategory.approvers[i].user_id =
				preparedCategory.approvers[i].value;
		}

		if (modal.modal === "update") {
			preparedCategory.id = categoryData.id;
			response = await updateCategory(preparedCategory);
		} else {
			response = await createCategory(preparedCategory);
		}

		switch (response) {
			case "ERROR_CREATING_CATEGORY":
				toast.error("Ocurrió un error al crear la categoría", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "AN_USER_HAS_NOT_APPROVER_ROLE":
				toast.error(
					"Uno de los usuarios seleccionados no es un aprobador",
					{
						id: toastId,
						style: {
							border: "1px solid #FF4C4C",
						},
					}
				);
				break;

			case "AN_USER_LEVEL_IS_BIGGER_THAN_APPROVAL_LEVEL":
				toast.error(
					"El nivel de aprobación de un usuario es mayor al nivel de aprobación general",
					{
						id: toastId,
						style: {
							border: "1px solid #FF4C4C",
						},
					}
				);
				break;

			case "Category has been created successfully!":
				toast.success("Categoría creada exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			case "Category has been updated successfully!":
				toast.success("Categoría actualizada exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			default:
				toast.error(
					`Ocurrió un error al ${(modal.modal = "update"
						? "actualizar"
						: "crear")} la categoría`,
					{
						id: toastId,
						style: {
							border: "1px solid #FF4C4C",
						},
					}
				);
				break;
		}

		refreshCategories();
		await delay(1000);
		toast.dismiss(toastId);
		setStatus(false);
	};

	/**
	 * Handle the selected values of the filters inputs
	 *
	 * Validate if the filter it's already selected.
	 * Add or update the filter
	 *
	 * @param {string} id The filter's identifier
	 * @param {string} value The filter's value
	 */
	const handleSelectedFilters = (id, value) => {
		setSelectedFilters({ ...selectedFilters, [id]: value });
		sessionStorage.setItem(
			"categoriesFilters",
			JSON.stringify({ ...selectedFilters, [id]: value })
		);
	};

	const handleSelectValue = (id) => {
		let value = [];

		let originItem;
		if (selectedFilters[id]) {
			switch (id) {
				case "approvers": {
					if (users) {
						if (selectedFilters[id] === "Todos") {
							originItem = [{ value: 0, label: "Todos" }];
						} else {
							originItem = users.filter((category) => {
								return category.label === selectedFilters[id];
							});
						}
						return originItem;
					}
					break;
				}

				default:
					break;
			}
		}

		return value;
	};

	/**
	 * Handle the changes of the category form
	 *
	 * @param {string} id The identificator fo the category object
	 * @param {any} value The value to set
	 */
	const handleCategoryForm = async (id, value, idx = 0) => {
		switch (id) {
			case "cat_description": {
				setCategoryData({
					...categoryData,
					[id]: value,
				});
				break;
			}

			case "cat_approval_level": {
				if (Number(value) <= users.length) {
					let approvers = [];
					for (let i = 0; i < Number(value); i++) {
						approvers = [
							...approvers,
							{ value: undefined, label: undefined },
						];
					}

					setCategoryData({
						...categoryData,
						[id]: Number(value),
						approvers: approvers,
					});
				} else {
					let approvers = [];
					for (let i = 0; i < users.length; i++) {
						approvers = [
							...approvers,
							{ value: undefined, label: undefined },
						];
					}

					setCategoryData({
						...categoryData,
						[id]: users.length,
						approvers: approvers,
					});
					selecteRef.current.value = users.length;

					toast((t) => (
						<span className="flex items-center gap-1">
							<span>
								No es posible seleccionar un nivel de aprobación
								mayor a la cantidad de aprobadores.
							</span>
							<button
								className="ml-2 py-1 rounded px-2 border bg-gray-100 text-gray-900"
								onClick={() => toast.dismiss(t.id)}
							>
								Cerrar
							</button>
						</span>
					));
				}

				setRemainingUsers(users);
				break;
			}

			case "users": {
				// Check if the sended value isn't empty
				if (value.length > 0) {
					value[0].level = idx + 1;
					// Check if the object approver has a value
					if (
						isType(categoryData.approvers[idx].value) !==
						"undefined"
					) {
						let alreadyThere = remainingUsers.filter((user) => {
							if (
								user.value === categoryData.approvers[idx].value
							) {
								return true;
							}
						});

						if (alreadyThere.length > 0) {
							let preparedApprovers = [...categoryData.approvers];
							preparedApprovers[idx] = value[0];

							setCategoryData({
								...categoryData,
								cat_approval_level:
									categoryData.approvers.length,
								approvers: preparedApprovers,
							});

							let remaining;
							if (isType(remainingUsers) === "undefined") {
								remaining = users;
							} else {
								remaining = remainingUsers;
							}

							let filterRemaning = remaining.filter(
								(user) => user.value !== value[0].value
							);
							setRemainingUsers(filterRemaning);
						} else {
							let remaining = remainingUsers;
							remaining.push(categoryData.approvers[idx]);
							setRemainingUsers(remaining);

							let preparedApprovers = [...categoryData.approvers];
							preparedApprovers[idx] = value[0];

							setCategoryData({
								...categoryData,
								cat_approval_level:
									categoryData.approvers.length,
								approvers: preparedApprovers,
							});

							if (isType(remainingUsers) === "undefined") {
								remaining = users;
							} else {
								remaining = remainingUsers;
							}

							let filterRemaning = remaining.filter(
								(user) => user.value !== value[0].value
							);
							setRemainingUsers(filterRemaning);
						}
					} else {
						let preparedApprovers = categoryData.approvers;
						preparedApprovers[idx] = value[0];

						setCategoryData({
							...categoryData,
							cat_approval_level: categoryData.approvers.length,
							approvers: preparedApprovers,
						});

						let remaining;
						if (isType(remainingUsers) === "undefined") {
							remaining = users;
						} else {
							remaining = remainingUsers;
						}

						let filterRemaning = remaining.filter(
							(user) => user.value !== value[0].value
						);
						setRemainingUsers(filterRemaning);
					}
				} else {
					if (categoryData.approvers.length > 0) {
						let remaining = remainingUsers;
						remaining.push(categoryData.approvers[idx]);
						setRemainingUsers(remaining);
					}

					let preparedApprovers = [...categoryData.approvers];
					preparedApprovers[idx] = {
						value: undefined,
						label: undefined,
					};

					setCategoryData({
						...categoryData,
						approvers: preparedApprovers,
					});
				}

				break;
			}

			default:
				setCategoryData({ ...categoryData, [id]: value });
				break;
		}
	};

	/**
	 * Handle the selected cat_approval_level
	 *
	 * @returns Array with void objects
	 */
	const handleSelectedApprovers = () => {
		let selectComponents = [];
		for (let i = 0; i < categoryData.cat_approval_level; i++) {
			selectComponents = [...selectComponents, {}];
		}

		return selectComponents;
	};

	/**
	 * Handle the selected cat_approval_level
	 *
	 * @returns Array with void objects
	 */
	const handleSelectedApprover = (id) => {
		if (isType(categoryData.approvers) !== "undefined") {
			if (isType(categoryData.approvers[id]) !== "undefined") {
				if (isType(categoryData.approvers[id].value) !== "undefined") {
					return [categoryData.approvers[id]];
				} else {
					return [];
				}
			} else {
				return [];
			}
		} else {
			return [];
		}
	};

	/**
	 * Compare the selected approvers with all the actual approvers
	 *
	 * @param {array} approvers The approvers of the selected category
	 */
	const compareApprovers = (approvers) => {
		let availableApprovers = [];
		for (let i = 0; i < users.length; i++) {
			if (!approvers.some((user) => user.value === users[i].value)) {
				availableApprovers = [...availableApprovers, users[i]];
			}
		}

		setRemainingUsers(availableApprovers);
	};

	const handleDisabled = () => {
		if (modal.modal === "create") {
			if (status === true || categoryData?.cat_description === "") {
				return true;
			} else {
				let approversValidation = [];
				approversValidation = categoryData?.approvers.filter(
					(user) => user.value === undefined
				);

				if (approversValidation.length > 0) {
					return true;
				} else {
					return false;
				}
			}
		} else {
			if (status === true || categoryData?.cat_description !== "") {
				if (selectedItem?.approvers) {
					let currentApprovers = [...selectedItem.approvers];
					if (isEqual(currentApprovers, categoryData?.approvers)) {
						return true;
					} else {
						for (let i = 0; i < currentApprovers.length; i++) {
							if (
								categoryData?.approvers[i].value !== undefined
							) {
								return false;
							} else {
								return true;
							}
						}
					}
				} else {
					return true;
				}
			} else {
				return true;
			}
		}
	};

	return (
		<>
			<Helmet>
				<title>Usuarios</title>
			</Helmet>
			<div className="home">
				<Sidebar />
				<div className="content">
					<Topbar />
					<CategoriesList
						items={filteredData ?? items}
						refreshItems={() => refreshCategories()}
						showModal={(value) => setModal(value)}
						setSelectedItem={(value) => {
							compareApprovers(value.approvers);
							setCategoryData(value);
							setSelectedItem(value);
						}}
						filters={selectedFilters}
						setFilter={handleSelectedFilters}
						resetFilters={() => handleResetFilters()}
						showOffCanvas={() => setIsOpen(true)}
					/>
				</div>
				<Toaster />
			</div>
			<OffCanvas
				onClose={() => setIsOpen(false)}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				staticOffCanvas={false}
				closeOnOutsideClick={false}
			>
				<div className="offcanvas-body h-3/5">
					{selectedFilters?.approvers !== "Todos" ? (
						<span
							className="flex justify-end mt-1 text-sm text-blue-500 hover:text-blue-400 underline cursor-pointer"
							onClick={() => handleResetFilters()}
						>
							Limpiar filtros
						</span>
					) : (
						<br />
					)}
					<form>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Aprobadores
							</label>
							<div className="mt-2">
								<Select
									options={
										users
											? [
													{
														value: 0,
														label: "Todos",
													},
													...users,
											  ] ?? users
											: users
									}
									onChange={(value) =>
										handleSelectedFilters(
											"approvers",
											value[0].label
										)
									}
									value={handleSelectValue("approvers")}
									multiple={false}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
					</form>
				</div>
			</OffCanvas>
			<Modal
				onClose={() => {
					setModal({ show: false });
				}}
				show={modal.show}
				modalTitle={`${
					modal.modal === "create" ? "Crear" : "Editar"
				} categoría de pago`}
			>
				<div className="modal-content-body">
					<form>
						<div className="mb-4">
							<label
								htmlFor="cat_description"
								className="block text-sm font-medium leading-6 text-gray-900"
								required
							>
								Descripción
								<br />
								<span className="font-normal text-gray-600">
									Ingresa la descripción de la categoría
								</span>
							</label>
							<div className="mt-2">
								<input
									id="cat_description"
									type="text"
									className="w-full"
									onChange={(e) =>
										handleCategoryForm(
											e.target.id,
											e.target.value
										)
									}
									defaultValue={capitalize(
										selectedItem?.cat_description
									)}
									placeholder="Ej: Pago a proveedores"
									autoFocus
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="cat_approval_level"
								className="block text-sm font-medium leading-6 text-gray-900"
								required
							>
								Nivel de aprobación
								<br />
								<span className="font-normal text-gray-600">
									Selecciona el nivel de aprobación de la
									categoría
								</span>
							</label>
							<div className="mt-2">
								{
									<select
										ref={selecteRef}
										id="cat_approval_level"
										className="input w-full"
										defaultValue={
											selectedItem?.cat_approval_level
										}
										onChange={(e) =>
											handleCategoryForm(
												e.target.id,
												e.target.value
											)
										}
									>
										<option value={1}>1</option>
										<option value={2}>2</option>
										<option value={3}>3</option>
										<option value={4}>4</option>
										<option value={5}>5</option>
									</select>
								}
							</div>
						</div>
						<hr className="py-2" />
						<div className="mb-4">
							<label
								htmlFor="users"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Aprobadores
								<br />
								<span className="font-normal text-gray-600">
									Selecciona los aprobadores de la categoría
								</span>
							</label>
							{handleSelectedApprovers().map((approver, idx) => (
								<div key={idx} className="flex mt-3">
									<span className="flex items-center px-3 text-sm text-slate-500">
										{idx + 1}.
									</span>
									<Select
										className="w-full"
										placeholder="Selecciona el aprobador"
										options={remainingUsers ?? users}
										onChange={(value) =>
											handleCategoryForm(
												"users",
												value,
												idx
											)
										}
										value={handleSelectedApprover(idx)}
										searchable={false}
									/>
									{/*<div
										className={`flex items-center justify-between px-3 min-w-[15%]${
											categoryData.cat_approval_level ===
											1
												? " hidden"
												: ""
										}`}
									>
										{idx === 0 ? null : (
											<span title="Subir">
												<ChevronIcon className="h-4 w-4 cursor-pointer fill-slate-500 hover:fill-slate-800" />
											</span>
										)}
										{idx ===
										categoryData.cat_approval_level -
											1 ? null : (
											<span title="Bajar">
												<ChevronIcon className="h-4 w-4 cursor-pointer fill-slate-500 hover:fill-slate-800 rotate-180" />
											</span>
										)}
									</div>*/}
								</div>
							))}
						</div>
					</form>
				</div>
				<div className="modal-content-footer justify-end block text-sm font-medium leading-6 text-gray-900">
					{status === true ? (
						""
					) : (
						<button
							className="px-3 py-2 text-sm font-semibold text-gray-900"
							onClick={() => {
								setModal(false);
							}}
							title="Cancelar"
						>
							Cancelar
						</button>
					)}
					<button
						className="btn primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
						title="Crear"
						onClick={() => handleCategorySubmit()}
						disabled={handleDisabled()}
					>
						{status === true ? (
							<div className="lds-dual-ring button"></div>
						) : (
							""
						)}
						Guardar
					</button>
				</div>
			</Modal>
		</>
	);
}

export default Categories;
