export const INDEX = "/";
export const NO_ROLES = "/no-roles";
export const MY_PAYMENTS = "/mis-pagos";
export const MY_PAYMENTS_DETAIL = "/mis-pagos/:id";
export const PAYMENTS = "/pagos";
export const PAYMENTS_DETAIL = "/pagos/:id";
export const PAYMENTS_TO_APPROVE = "/pagos-por-aprobar";
export const PAYMENTS_TO_APPROVE_DETAIL = "/pagos-por-aprobar/:id";
export const CATEGORIES = "/categorias-de-pagos";
export const ROLES = "/roles";
export const USERS = "/usuarios";
export const LOGIN = "/login";