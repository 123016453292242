import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const approvePayment = async (body) => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "put",
		maxBodyLength: Infinity,
		url: "https://apipagoterceros.spagrupoinmobiliario.com/api/payments/approvePayment",
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		data: body,
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);
	console.log(data);

	if (data.error) {
		return data.error;
	} else {
		return "Payment has been aproved successfully!";
	}
};

export default approvePayment;
