import React, { useCallback, useEffect, useRef, useState } from "react";
import { ChevronIcon } from "../assets/svg/icons";
import { PlusIcon } from "../assets/svg/icons";
import { inArray } from "../utils/compare";
import { capitalize } from "../utils/format";

const defaultItems = [
	{
		label: "Mostrar 5",
		value: 5,
	},
	{
		label: "Mostrar 10",
		value: 10,
	},
	{
		label: "Mostrar 15",
		value: 15,
	},
	{
		label: "Mostrar 20",
		value: 20,
	},
	{
		label: "Mostrar 25",
		value: 25,
	},
];

/**
 * Returns a multi functional select
 *
 * @see prepareObjectArray function
 *
 * @param {Array} options The formated array of objects [{label: x, value: z}].
 * @param {Function} onChange The function to manage the value change.
 * @param {Array} value The value selected.
 * @param {Boolean} clearable If the value can be clearable.
 * @param {Boolean} multiple If can select multiple values.
 * @param {Boolean} searchable If can search the values.
 * @param {String} placeholder The placeholder value.
 * @param {Number} tabIndex The index of the element through tab.
 * @param {string} className The custom styles to add.
 * @returns {Component} Returns select component.
 */
function Select({
	options = defaultItems,
	onChange = (onChangeValue) => console.log(onChangeValue),
	value = [],
	clearable = true,
	multiple = false,
	searchable = false,
	capitalizeValue = false,
	placeholder = "",
	tabIndex = 0,
	isMulti,
	className,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState(0);
	const [query, setQuery] = useState("");
	const selectRef = useRef();
	const inputRef = useRef();

	const clearOptions = () => {
		onChange([]);
	};

	const selectOption = useCallback(
		(option) => {
			if (multiple) {
				if (inArray(value, option)) {
					//onChange(value.filter((o) => o.value !== option.value));
					onChange(option);
				} else {
					//onChange([...value, option]);
					onChange(option);
				}
			} else {
				if (option.value !== value?.value) onChange([option]);
				setIsOpen(false);
			}
		},
		[onChange, multiple, value]
	);

	const isSelected = (option) => {
		return inArray(value, option);
	};

	useEffect(() => {
		if (isOpen) setHighlightedIndex(0);
	}, [isOpen]);

	useEffect(() => {
		const handler = (e) => {
			if (e.target !== selectRef.current) return;
			switch (e.code) {
				case "Enter":
				case "Space":
					setIsOpen((prev) => !prev);
					if (isOpen) selectOption(options[highlightedIndex]);
					break;

				case "ArrowUp":
				case "ArrowDown": {
					if (!isOpen) {
						setIsOpen(true);
						break;
					}
					const newValue =
						highlightedIndex + (e.code === "ArrowDown" ? 1 : -1);

					if (newValue >= 0 && newValue < options.length) {
						setHighlightedIndex(newValue);
					}

					break;
				}

				case "Escape":
					setIsOpen(false);
					break;

				default:
					if (searchable) inputRef.current.focus();
					setIsOpen(true);
					break;
			}
		};

		selectRef.current?.addEventListener("keydown", handler);

		return () => selectRef.current?.removeEventListener("keydown", handler);
	}, [isOpen, highlightedIndex, options, selectOption]);

	useEffect(() => {
		const handler = (e) => {
			if (e.target !== inputRef.current) return;
			switch (e.code) {
				case "Escape":
					setIsOpen(false);
					break;

				case "Enter":
				case "Space":
					setIsOpen((prev) => !prev);
					if (isOpen) selectOption(options[highlightedIndex]);
					break;

				case "ArrowUp":
				case "ArrowDown": {
					if (!isOpen) {
						setIsOpen(true);
						break;
					}
					const newValue =
						highlightedIndex + (e.code === "ArrowDown" ? 1 : -1);

					if (newValue >= 0 && newValue < options.length) {
						setHighlightedIndex(newValue);
					}

					break;
				}

				default:
					setIsOpen(true);
					break;
			}
		};

		inputRef.current?.addEventListener("keydown", handler);

		return () => inputRef.current?.removeEventListener("keydown", handler);
	}, [isOpen, highlightedIndex, options, selectOption]);

	return (
		<>
			<div
				ref={selectRef}
				tabIndex={tabIndex}
				className={`select${className ? " " + className : ""}`}
				onClick={() => {
					if (searchable && value.length === 0) {
						inputRef.current.focus();
						setIsOpen(!isOpen);
					} else {
						setIsOpen(!isOpen);
					}
				}}
				onBlur={() => setIsOpen(false)}
			>
				{searchable && value.length === 0 ? (
					<input
						ref={inputRef}
						type="search"
						placeholder={placeholder}
						className="select-value-search"
						onChange={(e) => setQuery(e.target.value)}
					/>
				) : (
					<span className="select-value">
						{value.length < 1 ? (
							<span className="select-value-placeholder">
								{placeholder}
							</span>
						) : (
							""
						)}
						{multiple
							? value?.map((value) => (
									<div
										key={value.value}
										className="item"
										onClick={(e) => {
											e.stopPropagation();
											selectOption(value);
										}}
									>
										<span title={capitalize(value.label)}>
											{capitalize(value.label)}
										</span>
										<PlusIcon className={"rotate-45"} />
									</div>
							  ))
							: capitalizeValue === true
							? capitalize(value[0]?.label)
							: value[0]?.label}
					</span>
				)}
				{clearable && value.length > 0 ? (
					<button
						className="select-clear-btn"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							clearOptions();
						}}
					>
						<PlusIcon className={"rotate-45"} />
					</button>
				) : (
					""
				)}
				<div className="select-divider"></div>
				<div
					className="select-caret-btn"
					onClick={(e) => {
						e.stopPropagation();
						setIsOpen(!isOpen);
					}}
				>
					<ChevronIcon className={"rotate-180"} />
				</div>
				<ul className={`select-options${isOpen ? " show" : ""}`}>
					{options
						.filter((option) => {
							const searchItem = query.toLocaleLowerCase();
							const value = option.label.toLocaleLowerCase();
							if (!searchItem) return true;
							return (
								value
									.toLowerCase()
									.indexOf(searchItem.toLowerCase()) > -1
							);
						})
						.map((option, index) => (
							<li
								key={option.value}
								className={`select-option${
									isSelected(option) ? " selected" : ""
								}${
									index === highlightedIndex
										? " highlighted"
										: ""
								}`}
								onMouseEnter={() => setHighlightedIndex(index)}
								onClick={(e) => {
									e.stopPropagation();
									selectOption(option);
								}}
							>
								{capitalizeValue === true
									? capitalize(option.label)
									: option.label}
							</li>
						))}
				</ul>
			</div>
			{/*<div className="select2">
				<div
					ref={selectRef}
					tabIndex={tabIndex}
					className="select-control"
				>
					{isMulti ? (
						<div className="select-control-single-value">
							<div className="select-control-value">
								<button>value</button>
							</div>
							<div className="select-control-input" data-value="">
								<input
									className="select__input"
									autocapitalize="none"
									autocomplete="off"
									autocorrect="off"
									id="react-select-3-input"
									spellcheck="false"
									tabIndex="0"
									type="text"
									aria-autocomplete="list"
									aria-expanded="false"
									aria-haspopup="true"
									role="combobox"
								/>
							</div>
						</div>
					) : (
						<div className="select-control-multi-value">
							<div className="select-control-value">
								<button>value</button>
							</div>
							<div className="select-control-value">
								<button>value</button>
							</div>
						</div>
					)}
					<div className="select-control-actions">
						<div className="select-control-action">
							<PlusIcon className={"rotate-45"} />
						</div>
						<div className="select-control-divisior"></div>
						<div className="select-control-action">
							<ChevronIcon className={"rotate-180"} />
						</div>
					</div>
				</div>
					</div>*/}
		</>
	);
}

export default Select;
