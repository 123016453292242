import React, { useState } from "react";
import { FunnelIcon, RefreshIcon } from "../../../assets/svg/icons";
import range from "../../../utils/range";
import Pagination from "../../../components/Pagination";
import Loader from "../../../components/Loader";
import { capitalize, prepareObjectArray } from "../../../utils/format";
import delay from "../../../utils/delay";
import BreadCrumbs from "../../../components/BreadCrumbs";

function UsersList({
	items,
	refreshItems,
	showModal,
	setSelectedItem,
	showOffCanvas,
	filters,
	setFilter,
	resetFilters,
}) {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(15);
	const [query, setQuery] = useState("");
	const [status, setStatus] = useState(false);

	const [canPreviousPage, setCanPreviousPage] = useState(false);
	const [canNextPage, setCanNextPage] = useState(false);

	const handleListRowClick = (e, item) => {
		if (e.detail === 2) {
			selectItem(item);
		}
	};

	const selectItem = (value) => {
		let preparedItem = { ...value };
		let filteredApprovers = Array.from(
			Array(preparedItem.approvers.length),
			() => ({})
		);

		for (let i = 0; i < preparedItem.approvers.length; i++) {
			filteredApprovers[i].value = preparedItem.approvers[i].id;
			filteredApprovers[i].label = preparedItem.approvers[i].name;
			filteredApprovers[i].level =
				preparedItem.approvers[i].usersxcategories.approval_level;
		}

		delete preparedItem.approvers;
		preparedItem = { ...preparedItem, approvers: filteredApprovers };

		/*preparedItem.approvers = prepareObjectArray(filteredApprovers);

		for (let i = 0; i < preparedItem.approvers.length; i++) {
			preparedItem.approvers[i].level =
				preparedItem.approvers[i].usersxcategories.approval_level;
		}*/

		setSelectedItem(preparedItem);
		showModal({ modal: "update", show: true });
	};

	if (items !== undefined) {
		const totalPages = Math.ceil(items.length / itemsPerPage);

		const handleLimit = (e) => {
			setItemsPerPage(e.target.value);

			if (currentPage === 1) {
				setCanPreviousPage(false);
			} else {
				setCanPreviousPage(true);
			}

			if (currentPage === totalPages) {
				setCanNextPage(false);
			} else {
				setCanNextPage(true);
			}
		};

		if (currentPage <= totalPages) {
		} else {
			setCurrentPage(totalPages);
		}

		const limits = range(5, 30, 5);

		const indexOfLastIndex = currentPage * itemsPerPage;
		const indexOfFirstItem = indexOfLastIndex - itemsPerPage;
		const currentItems = items.slice(indexOfFirstItem, indexOfLastIndex);

		const handleRefreshItems = async () => {
			setStatus(true);
			refreshItems();
			await delay(2000);
			setStatus(false);
		};

		return (
			<div className="flex flex-col justify-between h-full px-0 sm:px-6">
				<div>
					<div className="list-options flex flex-col sm:flex-row items-center justify-between mb-10 sm:m-0 h-20">
						<div className="flex items-center self-start pl-4 sm:p-0 h-20">
							<BreadCrumbs />
						</div>
						<div className="flex gap-4 items-center justify-center h-20 self-end mt-4 sm:m-0 pr-4 sm:p-0">
							<button
								className="btn text-slate-400 bg-transparent hover:text-white hover:bg-blue-500"
								onClick={() => {
									setSelectedItem({
										cat_description: "",
										cat_approval_level: 1,
										approvers: [
											{
												value: undefined,
												label: undefined,
											},
										],
									});
									showModal({ modal: "create", show: true });
								}}
							>
								Nueva
							</button>
							<div className="h-4 w-4" title="Actualizar datos">
								<RefreshIcon
									className={`h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black${
										status ? " rotate" : ""
									}`}
									onClick={() => handleRefreshItems()}
								/>
							</div>
							<div className="h-4 w-4" title="Filtros">
								<FunnelIcon
									className="h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black"
									onClick={showOffCanvas}
								/>
							</div>
							<div className="topbar-search">
								<input
									type="search"
									placeholder="Buscar"
									title="Busque por el nombre"
									onChange={(e) => setQuery(e.target.value)}
								/>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
								</svg>
							</div>
						</div>
					</div>
					<div className="table-container">
						<table className="table-auto w-full">
							<thead>
								<tr>
									<th>Id</th>
									<th>Categoría</th>
									<th>Aprobadores</th>
								</tr>
							</thead>
							{typeof items[0] !== "string" ? (
								<tbody>
									{currentItems
										.filter((item) => {
											const searchItem =
												query.toLocaleLowerCase();
											const value =
												item.cat_description.toLocaleLowerCase();
											if (!searchItem) return true;
											return (
												value
													.toLowerCase()
													.indexOf(
														searchItem.toLowerCase()
													) > -1
											);
										})
										.map((item) => (
											<tr
												key={item.id}
												className="hover:bg-slate-100"
												onClick={(e) => {
													handleListRowClick(e, item);
												}}
											>
												<td title={item.id}>
													{item.id}
												</td>
												<td
													title={capitalize(
														item.cat_description
													)}
													className="hover:underline cursor-pointer"
													onClick={() =>
														selectItem(item)
													}
												>
													{capitalize(
														item.cat_description
													)}
												</td>
												<td>
													<ul className="flex flex-wrap gap-1">
														{item.approvers?.map(
															(user, id) => (
																<li
																	key={id}
																	title={
																		user.name
																	}
																	className="approver py-1 px-2 rounded-full text-slate-700 bg-slate-300"
																>
																	{`${user.usersxcategories?.approval_level}. ${user.name}`}
																</li>
															)
														)}
													</ul>
												</td>
											</tr>
										))}
								</tbody>
							) : (
								<tbody>
									<tr key={0} className="h-12">
										<td
											colSpan={3}
											className="text-gray-500 text-center"
										>
											<span>No hay resultados.</span>{" "}
											<span
												className="text-blue-500 hover:text-blue-400 underline cursor-pointer"
												onClick={resetFilters}
											>
												Limpiar filtros
											</span>
										</td>
									</tr>
								</tbody>
							)}
						</table>
					</div>
				</div>
				<span className="pagination-info flex flex-row flex-wrap gap-5 items-center py-12 px-2">
					{items.length > 0 ? (
						<>
							<span className="pagination-info-limit">
								<select
									className="input"
									value={15}
									onChange={handleLimit}
								>
									{limits.map((limit) => (
										<option
											key={limit}
											id={limit}
											value={limit}
										>{`Mostrar ${limit}`}</option>
									))}
								</select>
							</span>
							<Pagination
								totalPages={totalPages}
								page={currentPage}
								setPage={setCurrentPage}
								limit={itemsPerPage}
								siblings={1}
								canNextPage={canNextPage}
								setCanNextPage={setCanNextPage}
								canPreviousPage={canPreviousPage}
								setCanPreviousPage={setCanPreviousPage}
							/>
						</>
					) : null}
				</span>
			</div>
		);
	} else {
		return (
			<div className="flex flex-col justify-between h-full px-0 sm:px-6">
				<div>
					<div className="list-options flex flex-col sm:flex-row items-center justify-between mb-10 sm:m-0 h-20">
						<div className="flex items-center self-start pl-4 sm:p-0 h-20">
							<BreadCrumbs />
						</div>
						<div className="flex gap-4 items-center justify-center h-20 self-end mt-4 sm:m-0 pr-4 sm:p-0">
							<div className="h-4 w-4" title="Actualizar datos">
								<RefreshIcon
									className={`h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black${
										status ? " rotate" : ""
									}`}
								/>
							</div>
							<div className="h-4 w-4" title="Filtros">
								<FunnelIcon
									className="h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black"
									onClick={showOffCanvas}
								/>
							</div>
							<div className="topbar-search">
								<input
									type="search"
									placeholder="Buscar"
									title="Busque por el nombre"
									onChange={(e) => setQuery(e.target.value)}
								/>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
								</svg>
							</div>
						</div>
					</div>
					<div className="table-container">
						<table className="table-auto w-full">
							<thead>
								<tr>
									<th>Id</th>
									<th>Categoría</th>
									<th>Aprobadores</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
								<tr>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
								<tr className="opacity-70">
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
								<tr className="opacity-50">
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
								<tr className="opacity-20">
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
									<td>
										<span className="flex h-4 w-full bg-gray-200 rounded-md skeleton"></span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

export default UsersList;
