import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";
import PropTypes from "prop-types";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import getAccessToken from "../services/getAccessToken";
import validateAppUser from "../services/validateAppUser";
import checkAppUser from "../features/authentication/services/checkAppUser";

const USER_AUTH = "USER_AUTH";
const USER_DATA = "USER_DATA";
const USER_REMBR = "USER_REMBR";
const USER_REMBR_EMAIL = "USER_REMBR_EMAIL";
const USER_REMBR_PASS = "USER_REMBR_PASS";

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
	const [isAuthenticated, setIsAuthenticated] = useState(
		secureLocalStorage.getItem(USER_AUTH)
	);
	const [userData, setUserData] = useState(
		secureLocalStorage.getItem(USER_DATA)
	);

	const login = useCallback(
		// async function (email, pass, rememberMe) {
		async function (microsoftUser, type) {
			let userData, appData;

			if (type && type === "microsoft") {
				userData = microsoftUser;
				appData = await validateAppUser(microsoftUser.microsoftId);
			} else {
				const tk = await getAccessToken();

				const user = microsoftUser.email;
				const password = microsoftUser.password;

				const payLoad = {
					method: "post",
					maxBodyLength: Infinity,
					url: "https://apiauth.spagrupoinmobiliario.com/tenantAuth/auth/validateInTenant",
					headers: {
						Authorization: `Bearer ${tk.data.token}`,
					},
					data: {
						email: user,
						password: password,
					},
					validateStatus: () => true,
				};

				let { data } = await axios(payLoad);
				console.log(data);
				data = data.data.status ? data.data : data;

				if (data.error) {
					console.log(data.error);
					return data.error;
				} else {
					userData = data.data_user;
					appData = await validateAppUser(userData.microsoftId);
				}
			}

			if (appData.error) {
				return appData.error;
			} else {
				userData.appId = appData.AppInfo.id;
				userData.appRoles = appData.AppInfo.roles;
				secureLocalStorage.setItem(USER_AUTH, true);
				setIsAuthenticated(true);
				secureLocalStorage.setItem(USER_DATA, userData);
				setUserData(userData);
			}

			return "ACCESS_GRANTED";
			//}
		},
		[]
	);

	const logout = useCallback(function () {
		secureLocalStorage.removeItem(USER_AUTH);
		secureLocalStorage.removeItem(USER_DATA);
		setIsAuthenticated(false);
	}, []);

	const value = useMemo(
		() => ({
			login,
			logout,
			isAuthenticated,
			userData,
		}),
		[login, logout, isAuthenticated, userData]
	);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
}

AuthContextProvider.propTypes = {
	children: PropTypes.object,
};

export function useAuthContext() {
	return useContext(AuthContext);
}
