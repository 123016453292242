import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const downloadFile = async (type, id, filename) => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "get",
		responseType: "blob", // Indica que la respuesta es un archivo
		maxBodyLength: Infinity,
		url: `https://apipagoterceros.spagrupoinmobiliario.com/api/payments/downloadFile/${type}/${id}/${filename}`,
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);

	if (data.error) {
		return data.error;
	} else {
		return data;
	}
};

export default downloadFile;
