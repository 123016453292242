import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const generatePaymentsReport = async (query, banks = null) => {
	const tk = await getAccessToken();
	let payLoad;

	if (banks !== null) {
		payLoad = {
			method: "post",
			responseType: "blob",
			maxBodyLength: Infinity,
			url: `https://apipagoterceros.spagrupoinmobiliario.com/api/reports/generatePaymentsReport${query}`,
			headers: {
				Authorization: `Bearer ${tk.data.token}`,
			},
			data: { banks },
			validateStatus: () => true,
		};
	} else {
		payLoad = {
			method: "post",
			responseType: "blob",
			maxBodyLength: Infinity,
			url: `https://apipagoterceros.spagrupoinmobiliario.com/api/reports/generatePaymentsReport${query}`,
			headers: {
				Authorization: `Bearer ${tk.data.token}`,
			},
			validateStatus: () => true,
		};
	}

	const { data } = await axios(payLoad);
	console.log(data);

	if (data.error) {
		return data.error;
	} else {
		return data;
	}
};

export default generatePaymentsReport;
