import { isType } from "./compare";

/**
 * Returns the string capitalized
 *
 * @param {string} value The string to capitalize.
 * @returns {string} Returns the capitalized string.
 */
export function capitalize(value) {
	if (isType(value, "string")) {
		return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
	}
}

/**
 * Returns the array with the correct format for the Select component
 * [{label: x, value: z}]
 *
 * @param {Array} array The array to be prepared.
 * @param {Boolean} reverse Reverse the order of the array. Descending by default.
 * @returns {Array} Returns the prepared array.
 */
export function prepareObjectArray(array, reverse = false) {
	if (isType(array, "array")) {
		if (array.length > 0) {
			const objectKeys = Object.keys(array[0]);
			const preparedArray = [];

			if (reverse) {
				for (let i = 0; i < array.length; i++) {
					preparedArray.push({
						value: array[i][objectKeys[1]],
						label: array[i][objectKeys[0]],
					});
				}
			} else {
				for (let i = 0; i < array.length; i++) {
					preparedArray.push({
						value: array[i][objectKeys[0]],
						label: array[i][objectKeys[1]],
					});
				}
			}

			/*if (reverse) {
				for (let i = 0; i < array.length; i++) {
					preparedArray[i].label = preparedArray[i][objectKeys[0]];
					delete preparedArray[i][objectKeys[0]];
					preparedArray[i].value = preparedArray[i][objectKeys[1]];
					delete preparedArray[i][objectKeys[1]];
				}
			} else {
				for (let i = 0; i < preparedArray.length; i++) {
					preparedArray[i].label = preparedArray[i][objectKeys[1]];
					delete preparedArray[i][objectKeys[1]];
					preparedArray[i].value = preparedArray[i][objectKeys[0]];
					delete preparedArray[i][objectKeys[0]];
				}
			}*/

			return preparedArray;
		} else {
			console.error(
				"Void array." +
					"\n" +
					"Please provide a valid value to prepared." +
					"\n\n" +
					`Value provided (${isType(array)}):`,
				array
			);
		}
	} else {
		console.error(
			"Invalid parameter entered." +
				"\n" +
				`@${isType(array)} type received instead of @array type.` +
				"\n\n" +
				"Please provide a valid value to prepared." +
				"\n\n" +
				`Value provided (${isType(array)}):`,
			array
		);
	}
}

function format() {
	return <div>format</div>;
}

export default format;
