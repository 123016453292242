import React, { useEffect, useRef, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import Sidebar from "../layouts/Sidebar";
import Topbar from "../layouts/Topbar";
import Modal from "../hooks/useModal";
import PaymentList from "../features/payments/components/PaymentList";
import Select from "../components/Select";
import { capitalize, prepareObjectArray } from "../utils/format";
import delay from "../utils/delay";
import OffCanvas from "../components/OffCanvas";
import { isEqual, isType } from "../utils/compare";
import { TrashFilledIcon } from "../assets/svg/icons";
// Requests
import getCategories from "../features/categories/services/getCategories";
import getPayments from "../features/payments/services/getPayments";
import getBanks from "../services/getBanks";
import getDocumentTypes from "../services/getDocumentTypes";
import { useAuthContext } from "../contexts/AuthContext";
import createMultiplePayments from "../features/payments/services/createMultiplePayments";
import createIndividualPayment from "../features/payments/services/createIndividualPayment";
import getPaystates from "../features/payments/services/getPaystates";
import { useNavigate } from "react-router-dom";

const emptyFilters = {
	sort: true,
	paystate: "Todos",
	paytype: "Todos",
	category: "Todos",
};

function MyPayments() {
	const selectRef = useRef();
	const fileRef = useRef();
	const formRef = useRef();

	const navigate = useNavigate();
	const { userData } = useAuthContext();

	if (userData && userData.appRoles.length <= 0) {
		navigate(`/no-roles`);
	}

	var appRoles = [];
	for (let i = 0; i < userData.appRoles.length; i++) {
		appRoles = [...appRoles, userData.appRoles[i].rol_description];
	}

	const [dragNDrop, setDragNDrop] = useState();
	const [modal, setModal] = useState({ show: false });
	const [isOpen, setIsOpen] = useState(false);

	const [selectedFilters, setSelectedFilters] = useState(
		sessionStorage.getItem("myPaymentsFilters")
			? JSON.parse(sessionStorage.getItem("myPaymentsFilters"))
			: sessionStorage.setItem(
					"myPaymentsFilters",
					JSON.stringify(emptyFilters)
			  )
	);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState();

	const [uploadFiles, setUploadFiles] = useState();
	const [items, setItems] = useState();
	const [paystates, setPaystates] = useState();
	const [banks, setBanks] = useState();
	const [documentTypes, setDocumentTypes] = useState();
	const [categories, setCategories] = useState();

	const [status, setStatus] = useState(false);
	const [roles, setRoles] = useState();
	const [selectedItem, setSelectedItem] = useState();

	const [paymentData, setPaymentData] = useState({});

	const handleResetFilters = () => {
		setSelectedFilters(emptyFilters);
		setFilteredData(items);
		sessionStorage.setItem(
			"myPaymentsFilters",
			JSON.stringify(emptyFilters)
		);
	};

	const handleSettedFilters = (providedItems) => {
		providedItems = providedItems ?? items;

		if (providedItems) {
			let itemsToFilter = [...providedItems];
			let actualFilters = JSON.parse(
				sessionStorage.getItem("myPaymentsFilters")
			);

			setFilteredData();
			for (var key of Object.getOwnPropertyNames(selectedFilters)) {
				if (key !== "undefined") {
					switch (key) {
						case "sort": {
							if (actualFilters.sort === true) {
								// Descending
								itemsToFilter.sort(
									(a, b) =>
										new Date(b.created) -
										new Date(a.created)
								);
								//setFilteredData(itemsToFilter);
							} else {
								//Ascending
								itemsToFilter.sort(
									(a, b) =>
										new Date(a.created) -
										new Date(b.created)
								);
							}
							break;
						}

						case "paystate": {
							if (
								actualFilters.paystate &&
								actualFilters.paystate !== "Todos"
							) {
								itemsToFilter = itemsToFilter.filter(
									(filter) =>
										filter.paystate.pay_sta_description ===
										actualFilters.paystate
								);
							}
							break;
						}

						case "paytype": {
							if (
								actualFilters.paytype &&
								actualFilters.paytype !== "Todos"
							) {
								itemsToFilter = itemsToFilter.filter(
									(filter) =>
										filter.pay_type ===
										actualFilters.paytype
								);
							}

							break;
						}

						case "category": {
							if (
								actualFilters.category &&
								actualFilters.category !== "Todos"
							) {
								itemsToFilter = itemsToFilter.filter(
									(filter) =>
										filter.category.cat_description ===
										actualFilters.category
								);
							}

							break;
						}

						default:
							break;
					}
					//console.log("original", items, itemsToFilter);
				}
			}

			//await delay(500);
			//console.log(itemsToFilter);
			setFilteredData(itemsToFilter.length > 0 ? itemsToFilter : [""]);
		}
	};

	useEffect(() => {
		handleSettedFilters();
	}, [selectedFilters]);

	useEffect(() => {
		handleSettedFilters();
	}, []);

	const searchForRole = (roleToSearch) => {
		let isThere = appRoles.findIndex(
			(role) => role.toLowerCase() === roleToSearch.toLowerCase()
		);
		return isThere > -1 ? true : false;
	};

	const refreshPaystates = async () => {
		let { paystates } = await getPaystates();
		let preparedArray = prepareObjectArray(paystates);
		setPaystates(preparedArray);
	};

	const refreshBanks = async () => {
		let { banks } = await getBanks();
		setBanks(banks);
	};

	const refreshDocumentTypes = async () => {
		let { documenttypes } = await getDocumentTypes();
		setDocumentTypes(documenttypes);
	};

	const refreshCategories = async () => {
		let categories = await getCategories();
		for (let i = 0; i < categories.length; i++) {
			delete categories[i].cat_approval_level;
			delete categories[i].users;
		}
		categories = prepareObjectArray(categories);
		setCategories(categories);
	};

	const refreshPayments = async () => {
		let payments = await getPayments();
		let filteredPayments = [];

		for (const e of payments) {
			if (e.user.id === userData.appId) {
				filteredPayments.push(e);
			}
		}

		let actualSortOrder = JSON.parse(
			sessionStorage.getItem("myPaymentsFilters")
		);

		if (Object.keys(selectedFilters).length > 1) {
			handleSettedFilters(filteredPayments);
		} else {
			if (actualSortOrder.sort === true) {
				// Descending
				filteredPayments.sort(
					(a, b) => new Date(b.created) - new Date(a.created)
				);
			} else {
				//Ascending
				filteredPayments.sort(
					(a, b) => new Date(a.created) - new Date(b.created)
				);
			}
		}

		setItems(filteredPayments);
	};

	useEffect(() => {
		refreshPayments();
		refreshCategories();
		refreshBanks();
		refreshDocumentTypes();
		refreshPaystates();

		const interval = setInterval(() => {
			refreshPayments();
			refreshCategories();
			refreshBanks();
			refreshDocumentTypes();
			refreshPaystates();
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	/**
	 * Handle the selected values of the filters inputs
	 *
	 * Validate if the filter it's already selected.
	 * Add or update the filter
	 *
	 * @param {string} id The filter's identifier
	 * @param {string} value The filter's value
	 */
	const handleSelectedFilters = (id, value) => {
		setSelectedFilters({ ...selectedFilters, [id]: value });
		sessionStorage.setItem(
			"myPaymentsFilters",
			JSON.stringify({ ...selectedFilters, [id]: value })
		);
	};

	const handleSelectValue = (id) => {
		let value = [];

		let originItem;
		if (selectedFilters[id]) {
			switch (id) {
				case "paystate": {
					if (paystates) {
						if (selectedFilters[id] === "Todos") {
							originItem = [{ value: 0, label: "Todos" }];
						} else {
							originItem = paystates.filter((paystate) => {
								return paystate.label === selectedFilters[id];
							});
						}
						return originItem;
					}
					break;
				}

				case "paytype": {
					switch (selectedFilters[id]) {
						case "REMESA": {
							originItem = [{ value: 1, label: "REMESA" }];
							break;
						}

						case "INDIVIDUAL": {
							originItem = [{ value: 2, label: "INDIVIDUAL" }];
							break;
						}

						default:
						case "TODO": {
							originItem = [{ value: 0, label: "Todos" }];
							break;
						}
					}
					return originItem;
					break;
				}

				case "category": {
					if (categories) {
						if (selectedFilters[id] === "Todos") {
							originItem = [{ value: 0, label: "Todos" }];
						} else {
							originItem = categories.filter((category) => {
								return category.label === selectedFilters[id];
							});
						}
						return originItem;
					}
					break;
				}

				default:
					break;
			}
		}

		return value;
	};

	/**
	 * Handle the changes of the payment form
	 *
	 * @param {string} id The identificator fo the payment object
	 * @param {any} value The value to set
	 */
	const handlePaymentForm = async (id, value, idx = 0) => {
		//console.log(value);
		switch (id) {
			case "category_id": {
				setPaymentData({
					...paymentData,
					[id]: value[0].value,
				});
				break;
			}

			default:
				//setPaymentData({ ...paymentData, [id]: value });
				break;
		}
	};

	const handleDisabled = () => {
		switch (modal.modal) {
			case "remesa": {
				if (status === true || paymentData?.cat_description === "") {
					return true;
				} else {
					let approversValidation = [];
					approversValidation = paymentData?.approvers.filter(
						(user) => user.value === undefined
					);

					if (approversValidation.length > 0) {
						return true;
					} else {
						return false;
					}
				}
				break;
			}

			case "individual": {
				return true;
				break;
			}

			default:
				return false;
				break;
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setModal({ show: false });
		setStatus(true);
		let response;
		const toastId = toast.loading("Actualizando...");
		const fields = Object.fromEntries(new window.FormData(e.target));
		fields.category_id = paymentData.category_id;

		if (modal.modal === "individual") {
			fields.bank_id = Number(fields.bank_id);
			fields.document_type_id = Number(fields.document_type_id);
			fields.pay_lis_account_number = Number(
				fields.pay_lis_account_number
			);
			fields.pay_lis_payment_amount = Number(
				fields.pay_lis_payment_amount
			);
			fields.user_id = userData.appId;
			console.log(fields);

			fields.pay_lis_account_number =
				fields.pay_lis_account_number.toString();


			response = await createIndividualPayment(fields);
			console.log(response);
		} else {
			let formData = new FormData();

			formData.append("file", uploadFiles, uploadFiles.name);
			formData.append("pay_description", fields.pay_description);
			formData.append("category_id", fields.category_id);
			formData.append("user_id", userData.appId);

			response = await createMultiplePayments(formData);
		}

		switch (response) {
			case "USER_NOT_FOUND":
				toast.error("No se encontró el usuario", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "ERROR_CREATING_MULTIPLE_PAYMENTS":
				toast.error("Occurió un error al crear el pago múltiple", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "ERROR_CREATING_INDIVIDUAL_PAYMENT":
				toast.error("Occurió un error al crear el pago individual", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_CREATED_SUCCESSFULLY":
				toast.success("Pago creado exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			default:
				toast.error(
					`Ocurrió un error al crear ${
						modal.modal === "individual"
							? "el pago individual"
							: "la remesa de pago"
					}`,
					{
						id: toastId,
						style: {
							border: "1px solid #FF4C4C",
						},
					}
				);
				break;
		}

		refreshPayments();
		await delay(1000);
		toast.dismiss(toastId);
		setStatus(false);
	};

	const handleUploadFiles = async (e) => {
		if (e.target.files.length > 0) {
			let droppedFile = e.target.files[0];
			let ext = droppedFile.type.split("/")[1];

			if (ext === "csv") {
				toast.success("Archivo cargado exitosamente", {
					position: "center-top",
					style: {
						border: "1px solid #62D346",
					},
				});
				setUploadFiles(droppedFile);
			} else {
				toast((t) => (
					<span className="flex items-center gap-1">
						<span>
							El archivo tipo <b>'{ext.toUpperCase()}'</b> cargado
							no coincide con el formato requerido <b>'CSV'.</b>
						</span>
						<button
							className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
							onClick={() => toast.dismiss(t.id)}
						>
							Cerrar
						</button>
					</span>
				));
			}
		} else {
			toast((t) => (
				<span className="flex items-center gap-1">
					<span>Inserción no válida.</span>
					<button
						className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
						onClick={() => toast.dismiss(t.id)}
					>
						Cerrar
					</button>
				</span>
			));
		}
	};

	const handleDragNDropOver = (e) => {
		e.preventDefault();
		setDragNDrop(true);
	};

	const handleDrop = (e) => {
		e.preventDefault();

		if (e.dataTransfer.files.length > 0) {
			let droppedFile = e.dataTransfer.files[0];
			let ext = droppedFile.type.split("/")[1];

			if (ext === "csv") {
				toast.success("Archivo cargado exitosamente", {
					position: "center-top",
					style: {
						border: "1px solid #62D346",
					},
				});
				setUploadFiles(droppedFile);
			} else {
				toast((t) => (
					<span className="flex items-center gap-1">
						<span>
							El archivo tipo <b>'{ext.toUpperCase()}'</b> cargado
							no coincide con el formato requerido <b>'CSV'.</b>
						</span>
						<button
							className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
							onClick={() => toast.dismiss(t.id)}
						>
							Cerrar
						</button>
					</span>
				));
			}
		} else {
			toast((t) => (
				<span className="flex items-center gap-1">
					<span>Inserción no válida.</span>
					<button
						className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
						onClick={() => toast.dismiss(t.id)}
					>
						Cerrar
					</button>
				</span>
			));
		}

		handleDragNDropOut();
	};

	const handleDragNDropOut = () => {
		setDragNDrop(false);
	};

	const handleUploadedFile = () => {
		if (!uploadFiles) {
			return (
				<div
					dropping={dragNDrop === true ? "true" : "false"}
					onDragOver={handleDragNDropOver}
					onDragLeave={handleDragNDropOut}
					onDrop={handleDrop}
					className="droparea mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/40 px-6 py-10"
				>
					<div className="text-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="mx-auto h-12 w-12 text-gray-300"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
							/>
						</svg>
						<div className="mt-4 flex text-sm leading-6 text-gray-600">
							<label
								forhtml="file-upload"
								className="relative cursor-pointer rounded-md font-semibold"
							>
								<span
									className="text-blue-500 hover:text-blue-600"
									title="Cargar archivo"
								>
									Carga un archivo
								</span>
								<input
									ref={fileRef}
									id="file-upload"
									name="file-upload"
									type="file"
									className="sr-only"
									accept=".csv"
									onChange={(e) => handleUploadFiles(e)}
								/>
							</label>
							<p className="pl-1">o arrastra y suelta</p>
						</div>
						<p className="text-xs leading-5 text-gray-600">
							Únicamente archivos CSV
						</p>
					</div>
				</div>
			);
		} else {
			return (
				<div className="flex h-14 flex-row rounded-md bg-gray-200">
					<div className="flex w-16 items-center justify-center">
						<svg
							className="h-8"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 20 20"
						>
							<path
								fill="#fff"
								d="M18 7h-2.5c-.827 0-1.5-.673-1.5-1.5V2H6v16h12V7z"
							/>
							<path
								fill="#fff"
								d="M18 6v-.293l-3-3V5.5c0 .275.225.5.5.5H18z"
							/>
							<path
								fill="#605E5C"
								fillRule="evenodd"
								d="M18.707 5 15 1.293A1 1 0 0 0 14.293 1H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5.707A1 1 0 0 0 18.707 5zM18 5.707V6h-2.5a.501.501 0 0 1-.5-.5V2.707l3 3zM6 2.2v15.6c0 .11.09.2.2.2h11.6a.2.2 0 0 0 .2-.2V7h-2.5A1.5 1.5 0 0 1 14 5.5V2H6.2a.2.2 0 0 0-.2.2z"
								clipRule="evenodd"
								opacity=".64"
							/>
							<path
								fill="#134A2C"
								fillRule="evenodd"
								d="M12.5 12h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"
								clipRule="evenodd"
							/>
							<path
								fill="#21A366"
								fillRule="evenodd"
								d="M12.5 10h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"
								clipRule="evenodd"
							/>
							<path
								fill="#33C481"
								d="M16.5 8h-1c-.275 0-.5.225-.5.5s.225.5.5.5h1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5z"
							/>
							<path
								fill="#21A366"
								d="M13.5 8h-1c-.275 0-.5.225-.5.5s.225.5.5.5h1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5z"
							/>
							<path
								fill="#107C41"
								d="M2 16h8a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1z"
							/>
							<path
								fill="#fff"
								d="m4.606 14 3.707-6H7.15l-3.755 6h1.21z"
							/>
							<path
								fill="#fff"
								d="M7.374 14 3.668 8H4.83l3.754 6h-1.21z"
							/>
						</svg>
					</div>
					<div className="flex w-full justify-between">
						<div className="flex flex-col justify-center">
							<span
								className="max-w-xs overflow-hidden text-ellipsis whitespace-nowrap font-semibold"
								title={uploadFiles.name}
							>
								{uploadFiles.name}
							</span>
							<span className="text-sm">
								{Number(uploadFiles.size) * 0.001 + "KB"}
							</span>
						</div>
						<div
							className="flex h-full w-14 cursor-pointer items-center justify-center rounded-br-md rounded-tr-md fill-gray-500 hover:bg-red-300 hover:fill-red-500"
							title="Eliminar"
							onClick={() => setUploadFiles()}
						>
							<TrashFilledIcon className="h-6 fill-inherit" />
						</div>
					</div>
				</div>
			);
		}
	};

	const handleModalStep = () => {
		switch (modal.modal) {
			case "individual": {
				return (
					<form ref={formRef} onSubmit={handleFormSubmit}>
						<div className="mb-2">
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Pago
							</h2>
							<span className="font-normal text-gray-600">
								Ingresa la información básica del pago
							</span>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
								required
							>
								Descripción del pago
							</label>
							<div className="mt-2">
								<input
									id="pay_description"
									name="pay_description"
									type="text"
									className="w-full"
									onChange={(e) =>
										handlePaymentForm(
											e.target.id,
											e.target.value
										)
									}
									placeholder="Ej: Pago comercial"
									autoFocus
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="cat_description"
								className="block text-sm font-medium leading-6 text-gray-900"
								required
							>
								Categoría
							</label>
							<div className="mt-2">
								<Select
									options={categories}
									onChange={(value) => {
										setSelectedCategories(value);
										handlePaymentForm("category_id", value);
									}}
									value={selectedCategories}
									searchable={false}
									capitalizeValue={true}
									isMulti
									placeholder="Selecciona la categoría"
								/>
							</div>
						</div>
						<hr className="py-2" />
						<div className="mb-2">
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Titular
							</h2>
							<span className="font-normal text-gray-600">
								Ingresa la información básica del titular
							</span>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_lis_holder_name"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Nombre del titular
							</label>
							<div className="mt-2">
								<input
									id="pay_lis_holder_name"
									name="pay_lis_holder_name"
									type="text"
									className="w-full"
									onChange={(e) =>
										handlePaymentForm(
											e.target.id,
											e.target.value
										)
									}
									placeholder="Ej: Humpty Alexander Dumpty"
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="document_type_id"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Tipo de documento
							</label>
							<div className="mt-2">
								{
									<select
										ref={selectRef}
										id="document_type_id"
										name="document_type_id"
										className="input w-full"
										placeholder=""
										onChange={(e) =>
											handlePaymentForm(
												e.target.id,
												e.target.value
											)
										}
									>
										{documentTypes.map((document) => (
											<option
												key={document.id}
												value={document.id}
											>
												{capitalize(
													document.doc_typ_description
												)}
											</option>
										))}
									</select>
								}
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_lis_holder_document_number"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Número de documento
							</label>
							<div className="mt-2">
								<input
									id="pay_lis_holder_document_number"
									name="pay_lis_holder_document_number"
									type="text"
									className="w-full"
									placeholder="Ej: 1597534682"
									onChange={(e) =>
										handlePaymentForm(
											e.target.id,
											e.target.value
										)
									}
								/>
							</div>
						</div>
						<hr className="py-2" />
						<div className="mb-2">
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Cuenta
							</h2>
							<span className="font-normal text-gray-600">
								Ingresa la información básica de la cuenta
							</span>
						</div>
						<div className="mb-4">
							<label
								htmlFor="bank_id"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Banco
							</label>
							<div className="mt-2">
								<select
									ref={selectRef}
									id="bank_id"
									name="bank_id"
									className="input w-full"
									placeholder="Selecciona el banco"
									onChange={(e) =>
										handlePaymentForm(
											e.target.id,
											e.target.value
										)
									}
								>
									{banks.map((bank) => (
										<option key={bank.id} value={bank.id}>
											{bank.ban_description}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_lis_account_number"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Número de cuenta
							</label>
							<div className="mt-2">
								<input
									id="pay_lis_account_number"
									name="pay_lis_account_number"
									type="number"
									className="w-full"
									placeholder="Ej: 3432424234"
									onChange={(e) =>
										handlePaymentForm(
											e.target.id,
											e.target.value
										)
									}
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_lis_account_type"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Tipo de cuenta
							</label>
							<div className="mt-2">
								{
									<select
										ref={selectRef}
										id="pay_lis_account_type"
										name="pay_lis_account_type"
										className="input w-full"
										placeholder="Selecciona el tipo de cuenta"
										onChange={(e) =>
											handlePaymentForm(
												e.target.id,
												e.target.value
											)
										}
									>
										<option value={"AHORROS"}>
											Ahorros
										</option>
										<option value={"CORRIENTE"}>
											Corriente
										</option>
									</select>
								}
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_lis_payment_amount"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Valor
							</label>
							<div className="mt-2">
								<input
									id="pay_lis_payment_amount"
									name="pay_lis_payment_amount"
									type="number"
									className="w-full"
									onChange={(e) =>
										handlePaymentForm(
											e.target.id,
											e.target.value
										)
									}
									placeholder="$1250000"
									min={0}
								/>
							</div>
						</div>
					</form>
				);
				break;
			}

			case "remesa": {
				return (
					<form ref={formRef} onSubmit={(e) => handleFormSubmit(e)}>
						<div className="mb-4">
							<h2 className="text-base font-semibold leading-7 text-gray-900">
								Pago
							</h2>
							<span className="font-normal text-gray-600">
								Ingresa la información básica del pago
							</span>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
								required
							>
								Descripción del pago
							</label>
							<div className="mt-2">
								<input
									id="pay_description"
									name="pay_description"
									type="text"
									className="w-full"
									onChange={(e) =>
										handlePaymentForm(
											e.target.id,
											e.target.value
										)
									}
									placeholder="Ej: Pago comercial"
									autoFocus
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="cat_description"
								className="block text-sm font-medium leading-6 text-gray-900"
								required
							>
								Categoría
							</label>
							<div className="mt-2">
								<Select
									options={categories}
									onChange={(value) => {
										setSelectedCategories(value);
										handlePaymentForm("category_id", value);
									}}
									value={selectedCategories}
									searchable={false}
									capitalizeValue={true}
									isMulti
									placeholder="Selecciona la categoría"
								/>
							</div>
						</div>
						<hr className="py-2" />
						<div className="mb-4">
							<label
								htmlFor="cat_description"
								className="block text-sm font-medium leading-6 text-gray-900"
								required
							>
								Archivo
							</label>
							<div className="mt-2">{handleUploadedFile()}</div>
						</div>
					</form>
				);
				break;
			}

			case "":
			default:
				return (
					<span className="font-normal text-gray-600">
						Seleccione el método con el que cargará el pago.
					</span>
				);
				break;
		}
	};

	return (
		<>
			<Helmet>
				<title>Mis pagos</title>
			</Helmet>
			<div className="home">
				<Sidebar />
				<div className="content">
					<Topbar />
					<PaymentList
						items={filteredData ?? items}
						refreshItems={() => refreshCategories()}
						showModal={(value) => setModal(value)}
						setSelectedItem={(value) => {
							setPaymentData(value);
							setSelectedItem(value);
						}}
						filters={selectedFilters}
						setFilter={handleSelectedFilters}
						resetFilters={() => handleResetFilters()}
						showOffCanvas={() => setIsOpen(true)}
					/>
				</div>
				<Toaster />
			</div>
			<OffCanvas
				onClose={() => setIsOpen(false)}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				staticOffCanvas={false}
				closeOnOutsideClick={false}
			>
				<div className="offcanvas-body h-3/5">
					{selectedFilters?.paystate !== "Todos" ||
					selectedFilters?.category !== "Todos" ||
					selectedFilters?.paytype !== "Todos" ||
					selectedFilters?.createdBy !== "Todos" ? (
						<span
							className="mt-1 flex cursor-pointer justify-end text-sm text-blue-500 underline hover:text-blue-400"
							onClick={() => handleResetFilters()}
						>
							Limpiar filtros
						</span>
					) : (
						<br />
					)}
					<form ref={formRef} onSubmit={handleFormSubmit}>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Estado
							</label>
							<div className="mt-2">
								<Select
									options={
										paystates
											? [
													{
														value: 0,
														label: "Todos",
													},
													...paystates,
											  ] ?? paystates
											: paystates
									}
									onChange={(value) =>
										handleSelectedFilters(
											"paystate",
											value[0].label
										)
									}
									value={handleSelectValue("paystate")}
									multiple={false}
									capitalizeValue={true}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Tipo de pago
							</label>
							<div className="mt-2">
								<Select
									options={[
										{
											value: 0,
											label: "Todos",
										},
										{
											value: 1,
											label: "REMESA",
										},
										{
											value: 2,
											label: "INDIVIDUAL",
										},
									]}
									onChange={(value) =>
										handleSelectedFilters(
											"paytype",
											value[0].label
										)
									}
									value={handleSelectValue("paytype")}
									multiple={false}
									capitalizeValue={true}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Categoría
							</label>
							<div className="mt-2">
								<Select
									options={
										categories
											? [
													{
														value: 0,
														label: "Todos",
													},
													...categories,
											  ] ?? categories
											: categories
									}
									onChange={(value) =>
										handleSelectedFilters(
											"category",
											value[0].label
										)
									}
									value={handleSelectValue("category")}
									multiple={false}
									capitalizeValue={true}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
					</form>
				</div>
			</OffCanvas>
			<Modal
				onClose={() => {
					setModal({ modal: "", show: false });
				}}
				show={modal.show}
				modalTitle="Cargar pago"
			>
				<div className="modal-content-body">{handleModalStep()}</div>
				<div
					className={`${
						modal.modal === "" ? "justify-between" : "justify-end"
					} modal-content-footer text-sm font-medium leading-6 text-gray-900`}
				>
					{modal.modal === "" ? (
						<>
							<button
								className="primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								onClick={() => {
									setModal({
										modal: "individual",
										show: true,
									});
								}}
								title="Cargar pago individual"
								disabled={status === true ? true : false}
							>
								{status === true &&
								modal.modal === "individual" ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar pago individual
							</button>
							<button
								className="primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								title="Cargar remesa de pago"
								onClick={() =>
									setModal({ modal: "remesa", show: true })
								}
								disabled={status === true ? true : false}
							>
								{status === true && modal.modal === "remesa" ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar remesa de pago
							</button>
						</>
					) : (
						<>
							{status === true ? (
								""
							) : (
								<button
									className="px-3 py-2 text-sm font-semibold text-gray-900"
									onClick={() => {
										setModal(false);
									}}
									title="Cancelar"
								>
									Cancelar
								</button>
							)}
							<button
								className="primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								title="Cargar remesa de pago"
								onClick={() => formRef.current.requestSubmit()}
								disabled={status === true ? true : false}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar
							</button>
						</>
					)}
				</div>
			</Modal>
		</>
	);
}

export default MyPayments;
