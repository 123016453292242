import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const updatePaylistState = async (id) => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "put",
		maxBodyLength: Infinity,
		url: `https://apipagoterceros.spagrupoinmobiliario.com/api/payments/updatePaylistState/${id}`,
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);
	console.log(data);

	if (data.message) {
		return data.message;
	} else {
		return data.error;
	}
};

export default updatePaylistState;
