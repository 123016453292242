import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const updateMultiplePayments = async (formData) => {
	console.log(Object.fromEntries(formData));
	const tk = await getAccessToken();

	const payLoad = {
		method: "put",
		maxBodyLength: Infinity,
		url: "https://apipagoterceros.spagrupoinmobiliario.com/api/payments/updateMultiplePayments",
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		data: formData,
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);

	if (data.message) {
		return data.message;
	} else {
		return data.error;
	}
};

export default updateMultiplePayments;
