import React from "react";

/**
 * Function copied of lodash dependency
 * Returns an array with the specified parameters
 *
 * @param {number} start The start of the range.
 * @param {number} end The end of the range.
 * @param {number} step The value to increment or decrement by.
 * @param {boolean} [fromRight] Specify iterating from right to left.
 * @returns {Array} Returns the range of numbers.
 */
function range(start, end, step, fromRight = false) {
	// Validate step variable
	if (step && typeof step != "number") {
		end = step = undefined;
	}

	// Validate end variable
	if (end === undefined) {
		end = start;
		start = 0;
	}

	// Define step
	step = step === undefined ? (start < end ? 1 : -1) : step;

	var index = -1;
	var length = Math.max(Math.ceil((end - start) / (step || 1)), 0);
	var result = Array(length);

	while (length--) {
		result[fromRight ? length : ++index] = start;
		start += step;
	}

	return result;
}

export default range;
