import { Navigate, Outlet } from "react-router-dom";
import {
	MY_PAYMENTS,
	NO_ROLES,
	PAYMENTS,
	PAYMENTS_TO_APPROVE,
} from "../../config/routes/paths";
import { useAuthContext } from "../../contexts/AuthContext";

export default function PublicRoute() {
	const { isAuthenticated, userData } = useAuthContext();

	if (isAuthenticated) {
		var appRoles = [];
		for (let i = 0; i < userData.appRoles.length; i++) {
			appRoles = [...appRoles, userData.appRoles[i].rol_description];
		}

		const searchForRole = (roleToSearch) => {
			let isThere = appRoles.findIndex(
				(role) => role.toLowerCase() === roleToSearch.toLowerCase()
			);
			return isThere;
		};

		const handleIndexPath = () => {
			if (
				searchForRole("administrador") > -1 ||
				searchForRole("tesoreria") > -1 ||
				searchForRole("contabilidad") > -1 ||
				searchForRole("financiero") > -1
			) {
				return PAYMENTS;
			} else if (searchForRole("usuario") > -1) {
				return MY_PAYMENTS;
			} else if (searchForRole("aprobador") > -1) {
				return PAYMENTS_TO_APPROVE;
			} else {
				return NO_ROLES;
			}
		};

		return <Navigate to={handleIndexPath()} />;
	}

	return (
		<div>
			<Outlet />
		</div>
	);
}
