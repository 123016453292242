import React from "react";
import { Link, useLocation } from "react-router-dom";
import { capitalize } from "../utils/format";
import { ChevronIcon } from "../assets/svg/icons";

function BreadCrumbs() {
	const location = useLocation();

	var crumbs = location.pathname.split("/");
	var actualCrumb = location.pathname.split("/").pop();

	const handleOrderReference = (id) => {
		let reference = "000000";
		id = id.toString();

		reference = reference.slice(0, reference.length - id.length) + id;
		return "# " + reference;
	};

	if (crumbs.length > 2) crumbs.shift();

	if (crumbs.length > 1 && crumbs[1] !== "") {
		return (
			<ul className="flex flex-row flex-nowrap gap-2">
				{crumbs.map((crumb, id) => {
					if (crumb === "") {
						return (
							<li
								key={"inicio"}
								id={"inicio"}
								title="Inicio"
								className="text-gray-700 hover:text-black cursor-pointer"
							>
								<Link to="/">Inicio</Link>
							</li>
						);
					} else if (crumb === actualCrumb) {
						return (
							<li key={id} className="flex gap-2">
								<div className="flex items-center">
									<ChevronIcon className="rotate-90 fill-gray-500 h-3 w-3" />
								</div>
								<div
									title={
										!isNaN(crumb)
											? handleOrderReference(crumb)
											: capitalize(
													crumb.replaceAll("-", " ")
											  )
									}
									className="flex items-center text-black font-semibold"
								>
									{!isNaN(crumb)
										? handleOrderReference(crumb)
										: capitalize(
												crumb.replaceAll("-", " ")
										  )}
								</div>
							</li>
						);
					} else {
						return (
							<li key={id} className="flex gap-2">
								<div className="flex items-center">
									<ChevronIcon className="rotate-90 fill-gray-500 h-3 w-3" />
								</div>
								<div
									title={
										!isNaN(crumb)
											? handleOrderReference(crumb)
											: capitalize(
													crumb.replaceAll("-", " ")
											  )
									}
									className="flex items-center text-gray-700 hover:text-black"
								>
									<Link to={`/${crumb}`}>
										{!isNaN(crumb)
											? handleOrderReference(crumb)
											: capitalize(
													crumb.replaceAll("-", " ")
											  )}
									</Link>
								</div>
							</li>
						);
					}
				})}
			</ul>
		);
	} else {
		return (
			<ul className="flex flex-row flex-nowrap gap-2">
				<li key={0} title="Inicio" className="text-gray-700">
					Inicio
				</li>
			</ul>
		);
	}
}

export default BreadCrumbs;
