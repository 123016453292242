import React, { useRef, useState } from "react";
import {
	BankNotesIcon,
	CheckCircleIcon,
	CheckIcon,
	ChevronIcon,
	ClipboardCheckIcon,
	DownloadIcon,
	ExpandIcon,
	FunnelIcon,
	HomeIcon,
	MagnifyingGlassIcon,
	PaperClipIcon,
	RefreshIcon,
	TicketIcon,
	TrashIcon,
	XCircleIcon,
	XIcon,
} from "../../../assets/svg/icons";
import range from "../../../utils/range";
import Pagination from "../../../components/Pagination";
import Loader from "../../../components/Loader";
import { capitalize, prepareObjectArray } from "../../../utils/format";
import { formatDateUTC } from "../../../utils/formatDate";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "../../../components/AccordionComp";
import { useAuthContext } from "../../../contexts/AuthContext";
import addNote from "../services/addNote";
import { toast } from "react-hot-toast";
import delay from "../../../utils/delay";
import downloadFile from "../services/downloadFile";
import { BlockIcon } from "../../../assets/svg/icons";

function PaymentDetails({
	items,
	refreshItems,
	setModal,
	setSelectedItem,
	showOffCanvas,
}) {
	const navigate = useNavigate();

	const { userData } = useAuthContext();

	const noteRef = useRef();

	const location = useLocation();
	var actualCrumb = location.pathname.split("/").pop();

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(15);
	const [status, setStatus] = useState(false);
	const [query, setQuery] = useState("");
	const [note, setNote] = useState("");

	const [canPreviousPage, setCanPreviousPage] = useState(false);
	const [canNextPage, setCanNextPage] = useState(false);

	const choices = {
		NUEVO: "text-slate-700 border border-slate-400 bg-slate-200",
		APROBADO: "text-emerald-700 border border-emerald-400 bg-emerald-200",
		RECHAZADO: "text-red-700 border border-red-400 bg-red-200",
		"PENDIENTE POR PAGAR":
			"text-amber-700 border border-amber-400 bg-amber-100",
		PAGADO: "text-blue-700 border border-blue-400 bg-blue-200",
		"PENDIENTE POR CONTABILIZAR":
			"text-amber-700 border border-amber-400 bg-amber-100",
		CONTABILIZADO: "text-violet-700 border border-violet-400 bg-violet-200",
		REMESA: "text-emerald-700 border border-emerald-400 bg-emerald-200",
		INDIVIDUAL: "text-violet-700 border border-violet-400 bg-violet-200",
		UNIFICADO: "text-pink-700 border border-pink-400 bg-pink-200",
	};

	const handleListRowClick = (e, item) => {
		if (e.detail === 2) {
			selectItem(item);
		}
	};

	const selectItem = (value) => {
		navigate(`/pagos/${value.id}`);
	};

	if (items !== undefined) {
		var payment = items.payment;
		var order = items.order;
		var notes = items.notes;

		var appRoles = [];
		for (let i = 0; i < userData.appRoles.length; i++) {
			appRoles = [...appRoles, userData.appRoles[i].rol_description];
		}

		const totalPages = Math.ceil(order.length / itemsPerPage);
		if (currentPage <= totalPages) {
		} else {
			setCurrentPage(totalPages);
		}

		const limits = range(5, 30, 5);

		const indexOfLastIndex = currentPage * itemsPerPage;
		const indexOfFirstItem = indexOfLastIndex - itemsPerPage;
		const currentItems = order.slice(indexOfFirstItem, indexOfLastIndex);

		const handleLimit = (e) => {
			setItemsPerPage(e.target.value);

			if (currentPage === 1) {
				setCanPreviousPage(false);
			} else {
				setCanPreviousPage(true);
			}

			if (currentPage === totalPages) {
				setCanNextPage(false);
			} else {
				setCanNextPage(true);
			}
		};

		const handleOrderReference = (id) => {
			let reference = "000000";
			id = id.toString();

			reference = reference.slice(0, reference.length - id.length) + id;
			return "# " + reference;
		};

		const handleCurrency = (value) => {
			let formatting_options = {
				style: "currency",
				currency: "COP",
				minimumFractionDigits: 0,
			};

			let currency = new Intl.NumberFormat("es-CO", formatting_options);
			let finalCurreny = currency.format(value);

			return finalCurreny;
		};

		const handleNoteFormSubmit = (e) => {
			e.preventDefault();
			if (noteRef.current?.value !== "") {
				handleAddNote();
			}
		};

		const handleAddNote = async () => {
			setStatus(true);
			const toastId = toast.loading("Enviando...");

			if (noteRef.current?.value !== "") {
				let body = {
					id: payment.id,
					pay_not_description: noteRef.current.value,
					user_id: userData.appId,
				};

				let response = await addNote(body);

				switch (response) {
					case "USER_NOT_FOUND":
						toast.error("No se encontró el usuario", {
							id: toastId,
							style: {
								border: "1px solid #FF4C4C",
							},
						});
						break;

					case "PAYMENT_NOT_EXISTS":
						toast.error("No existe el pago", {
							id: toastId,
							style: {
								border: "1px solid #FF4C4C",
							},
						});
						break;

					case "Invalid description":
						toast.error("El valor de la nota no es válido", {
							id: toastId,
							style: {
								border: "1px solid #FF4C4C",
							},
						});
						break;

					case "Note added successfully!":
						toast.success("Nota guardada exitosamente", {
							id: toastId,
							style: {
								border: "1px solid #62D346",
							},
						});
						break;

					case "ERROR_CRAEATING_NOTE":
					default:
						toast.error("Ocurrió un error al guardar la nota", {
							id: toastId,
							style: {
								border: "1px solid #FF4C4C",
							},
						});
						break;
				}

				noteRef.current.value = "";
			}
			refreshItems();
			setStatus(false);
		};

		const handleRefreshItems = async () => {
			setStatus(true);
			refreshItems();
			await delay(2000);
			setStatus(false);
		};

		const searchForRole = (roleToSearch) => {
			let isThere = appRoles.findIndex(
				(role) => role.toLowerCase() === roleToSearch.toLowerCase()
			);
			return isThere;
		};

		const handleButtonsByState = () => {
			let paymentState = payment.paystate.pay_sta_description;

			switch (paymentState) {
				case "NUEVO": {
					let currentApprover;

					for (const approver of payment.category.approvers) {
						if (
							approver.usersxcategories.approval_level ===
							payment.pay_approval_level
						) {
							currentApprover = approver;
						}
					}

					if (currentApprover?.id !== userData.appId) return null;

					return (
						<>
							<div>
								<button
									title="Rechazar"
									className="flex gap-1 items-center py-1 px-2 bg-red-500 hover:bg-red-400 text-white rounded-md"
									onClick={() =>
										setModal({
											modal: "Rechazar",
											show: true,
										})
									}
								>
									Rechazar
									<XIcon className="h-4 w-4" />
								</button>
							</div>
							<div>
								<button
									title="Aprobar"
									className="flex gap-1 items-center py-1 px-2 bg-emerald-500 hover:bg-emerald-400 text-white rounded-md"
									onClick={() =>
										setModal({
											modal: "Aprobar",
											show: true,
										})
									}
								>
									Aprobar
									<CheckIcon className="h-4 w-4" />
								</button>
							</div>
						</>
					);
					break;
				}

				case "APROBADO": {
					if (
						searchForRole("ADMINISTRADOR") < 0 &&
						searchForRole("TESORERIA") < 0
					)
						return null;

					return (
						<div>
							<button
								title="Procesar"
								className="flex gap-1 items-center py-1 px-2 bg-sky-500 hover:bg-sky-400 text-white rounded-md"
								onClick={() =>
									setModal({
										modal: "Procesar",
										show: true,
									})
								}
							>
								Procesar
							</button>
						</div>
					);
					break;
				}

				case "RECHAZADO": {
					if (userData.appId !== payment.user.id) return null;

					return (
						<div>
							<button
								title="Procesar"
								className="flex gap-1 items-center py-1 px-2 bg-orange-500 hover:bg-orange-400 text-white rounded-md"
								onClick={() => {
									setSelectedItem(items);
									setModal({
										modal: capitalize(payment.pay_type),
										show: true,
									});
								}}
							>
								Cargar de nuevo
								<RefreshIcon className="h-4 w-4" />
							</button>
						</div>
					);
					break;
				}

				case "PENDIENTE POR PAGAR": {
					if (
						searchForRole("ADMINISTRADOR") < 0 &&
						searchForRole("TESORERIA") < 0
					)
						return null;

					return (
						<div>
							<button
								title="Pagar"
								className="flex gap-1 items-center py-1 px-2 bg-emerald-500 hover:bg-emerald-400 text-white rounded-md"
								onClick={() =>
									setModal({
										modal: "Pagar",
										show: true,
									})
								}
							>
								Pagar
								<BankNotesIcon className="h-4 w-4" />
							</button>
						</div>
					);
					break;
				}

				case "PAGADO": {
					return (
						<div>
							<button
								title="Iniciar contabilización"
								className="flex gap-1 items-center py-1 px-2 bg-violet-500 hover:bg-violet-400 text-white rounded-md"
								onClick={() =>
									setModal({
										modal: "Iniciar contabilización",
										show: true,
									})
								}
							>
								Iniciar contabilización
							</button>
						</div>
					);
					break;
				}

				case "PENDIENTE POR CONTABILIZAR": {
					if (
						searchForRole("ADMINISTRADOR") < 0 &&
						searchForRole("TESORERIA") < 0
					)
						return null;
					return (
						<div>
							<button
								title="Contabilizar"
								className="flex gap-1 items-center py-1 px-2 bg-indigo-500 hover:bg-indigo-400 text-white rounded-md"
								onClick={() =>
									setModal({
										modal: "Contabilizar",
										show: true,
									})
								}
							>
								Contabilizar
							</button>
						</div>
					);
					break;
				}

				default:
					break;
			}
		};

		const handleApprovers = () => {
			let category = { ...payment.category };
			let approvers = [...payment.category.approvers];

			switch (payment.paystate.pay_sta_description) {
				case "RECHAZADO": {
					return (
						<ol className="mt-3 overflow-hidden">
							{approvers
								.sort(
									// Sort from lowest approver to highest approver
									(a, b) =>
										b.usersxcategories.approval_level -
										a.usersxcategories.approval_level
								)
								.map((approver, id) => {
									if (
										payment.pay_approval_level <
										category.cat_approval_level
									) {
										if (
											approver.usersxcategories
												.approval_level >
											payment.pay_approval_level
										) {
											return (
												<li
													key={id}
													className="relative pb-10"
												>
													<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-emerald-300"></div>
													<div className="relative flex items-start">
														<span className="flex items-center h-9">
															<span className="relative flex items-center justify-center h-8 w-8 bg-emerald-400 rounded-full z-10">
																<CheckIcon className="text-white h-5 w-5" />
															</span>
														</span>
														<span className="flex flex-col ml-4 min-w-0">
															<span className="text-sm font-semibold">
																{approver.name}
															</span>
															<span className="text-sm text-gray-500">
																Aprobado
															</span>
														</span>
													</div>
												</li>
											);
										} else {
											if (
												approver.usersxcategories
													.approval_level ===
												payment.pay_approval_level
											) {
												if (
													approver.usersxcategories
														.approval_level === 1
												) {
													return (
														<li
															key={id}
															className="relative"
														>
															<div className="relative flex items-start">
																<span className="flex items-center h-9">
																	<span className="relative flex items-center justify-center h-8 w-8 bg-red-400 rounded-full z-10">
																		<XIcon className="text-white h-5 w-5" />
																	</span>
																</span>
																<span className="flex flex-col ml-4 min-w-0">
																	<span className="text-red-400 text-sm font-semibold">
																		{
																			approver.name
																		}
																	</span>
																	<span className="text-sm text-gray-500">
																		Rechazado
																	</span>
																</span>
															</div>
														</li>
													);
												} else {
													return (
														<li
															key={id}
															className="relative pb-10"
														>
															<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-gray-300"></div>
															<div className="relative flex items-start">
																<span className="flex items-center h-9">
																	<span className="relative flex items-center justify-center h-8 w-8 bg-red-400 rounded-full z-10">
																		<XIcon className="text-white h-5 w-5" />
																	</span>
																</span>
																<span className="flex flex-col ml-4 min-w-0">
																	<span className="text-red-400 text-sm font-semibold">
																		{
																			approver.name
																		}
																	</span>
																	<span className="text-sm text-gray-500">
																		Rechazado
																	</span>
																</span>
															</div>
														</li>
													);
												}
											} else {
												if (
													approver.usersxcategories
														.approval_level === 1
												) {
													return (
														<li
															key={id}
															className="relative"
														>
															<div className="relative flex items-start">
																<span className="flex items-center h-9">
																	<span className="relative flex items-center justify-center h-8 w-8 bg-white border-gray-300 border-2 rounded-full z-10">
																		<span className="h-2.5 w-2.5 bg-transparent rounded-full"></span>
																	</span>
																</span>
																<span className="flex flex-col ml-4 min-w-0">
																	<span className="text-sm">
																		{
																			approver.name
																		}
																	</span>
																	<span className="text-sm text-gray-500">
																		Pendiente
																		por
																		aprobar
																	</span>
																</span>
															</div>
														</li>
													);
												} else {
													return (
														<li
															key={id}
															className="relative pb-10"
														>
															<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-gray-300"></div>
															<div className="relative flex items-start">
																<span className="flex items-center h-9">
																	<span className="relative flex items-center justify-center h-8 w-8 bg-white border-gray-300 border-2 rounded-full z-10">
																		<span className="h-2.5 w-2.5 bg-transparent rounded-full"></span>
																	</span>
																</span>
																<span className="flex flex-col ml-4 min-w-0">
																	<span className="text-sm">
																		{
																			approver.name
																		}
																	</span>
																	<span className="text-sm text-gray-500">
																		Pendiente
																		por
																		aprobar
																	</span>
																</span>
															</div>
														</li>
													);
												}
											}
										}
									} else {
										if (
											approver.usersxcategories
												.approval_level <
											payment.pay_approval_level
										) {
											if (
												approver.usersxcategories
													.approval_level === 1
											) {
												return (
													<li
														key={id}
														className="relative"
													>
														<div className="relative flex items-start">
															<span className="flex items-center h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-white border-gray-300 border-2 rounded-full z-10">
																	<span className="h-2.5 w-2.5 bg-transparent rounded-full"></span>
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-sm">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Pendiente
																	por aprobar
																</span>
															</span>
														</div>
													</li>
												);
											} else {
												return (
													<li
														key={id}
														className="relative pb-10"
													>
														<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-gray-300"></div>
														<div className="relative flex items-start">
															<span className="flex items-center h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-white border-gray-300 border-2 rounded-full z-10">
																	<span className="h-2.5 w-2.5 bg-transparent rounded-full"></span>
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-sm">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Pendiente
																	por aprobar
																</span>
															</span>
														</div>
													</li>
												);
											}
										} else {
											if (
												approver.usersxcategories
													.approval_level === 1
											) {
												return (
													<li
														key={id}
														className="relative pb-10"
													>
														<div className="relative flex items-start">
															<span className="flex items-center h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-red-400 rounded-full z-10">
																	<XIcon className="text-white h-5 w-5" />
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-red-500 text-sm font-semibold">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Rechazado
																</span>
															</span>
														</div>
													</li>
												);
											} else {
												return (
													<li
														key={id}
														className="relative pb-10"
													>
														<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-gray-300"></div>
														<div className="relative flex items-start">
															<span className="flex items-center h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-red-400 rounded-full z-10">
																	<XIcon className="text-white h-5 w-5" />
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-red-500 text-sm font-semibold">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Rechazado
																</span>
															</span>
														</div>
													</li>
												);
											}
										}
									}
								})}
						</ol>
					);
					break;
				}

				case "NUEVO":
				default: {
					return (
						<ol className="mt-3 overflow-hidden">
							{approvers
								.sort(
									// Sort from lowest approver to highest approver
									(a, b) =>
										b.usersxcategories.approval_level -
										a.usersxcategories.approval_level
								)
								.map((approver, id) => {
									if (
										approver.usersxcategories
											.approval_level ===
										category.cat_approval_level
									) {
										// Check if the actual approver is the latest
										if (
											approver.usersxcategories
												.approval_level >
											payment.pay_approval_level
										) {
											// Check if the actual approver level is major than the payment approval level
											if (
												approver.usersxcategories
													.approval_level === 1
											) {
												// Check if the actual approver level is the first one
												return (
													<li
														key={id}
														className="relative"
													>
														<div className="relative flex items-start">
															<span className="flex items-center h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-emerald-400 rounded-full z-10">
																	<CheckIcon className="text-white h-5 w-5" />
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-sm font-semibold">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Aprobado
																</span>
															</span>
														</div>
													</li>
												);
											} else {
												return (
													<li
														key={id}
														className="relative pb-10"
													>
														<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-emerald-400"></div>
														<div className="relative flex items-start">
															<span className="flex items-center h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-emerald-400 rounded-full z-10">
																	<CheckIcon className="text-white h-5 w-5" />
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-sm font-semibold">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Aprobado
																</span>
															</span>
														</div>
													</li>
												);
											}
										} else {
											if (
												approver.usersxcategories
													.approval_level === 1
											) {
												// Check if the actual approver level is the first one
												return (
													<li
														key={id}
														className="relative pb-10"
													>
														<div className="relative flex items-start">
															<span className="flex items-start h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-white border-2 border-emerald-400 rounded-full">
																	<span className="h-2.5 w-2.5 bg-emerald-400 rounded-full"></span>
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-sm font-medium text-emerald-400">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Pendiente
																	por aprobar
																</span>
															</span>
														</div>
													</li>
												);
											} else {
												return (
													<li
														key={id}
														className="relative pb-10"
													>
														<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-gray-300"></div>
														<div className="relative flex items-start">
															<span className="flex items-start h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-white border-2 border-emerald-400 rounded-full">
																	<span className="h-2.5 w-2.5 bg-emerald-400 rounded-full"></span>
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-sm font-medium text-emerald-400">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Pendiente
																	por aprobar
																</span>
															</span>
														</div>
													</li>
												);
											}
										}
									} else {
										if (
											approver.usersxcategories
												.approval_level >
											payment.pay_approval_level
										) {
											// Check if the actual approver level is major than the payment approval level
											if (
												approver.usersxcategories
													.approval_level === 1
											) {
												// Check if the actual approver level is the first one
												return (
													<li
														key={id}
														className="relative"
													>
														<div className="relative flex items-start">
															<span className="flex items-center h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-emerald-400 rounded-full z-10">
																	<CheckIcon className="text-white h-5 w-5" />
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-sm font-semibold">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Aprobado
																</span>
															</span>
														</div>
													</li>
												);
											} else {
												return (
													<li
														key={id}
														className="relative pb-10"
													>
														<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-emerald-400"></div>
														<div className="relative flex items-start">
															<span className="flex items-center h-9">
																<span className="relative flex items-center justify-center h-8 w-8 bg-emerald-400 rounded-full z-10">
																	<CheckIcon className="text-white h-5 w-5" />
																</span>
															</span>
															<span className="flex flex-col ml-4 min-w-0">
																<span className="text-sm font-semibold">
																	{
																		approver.name
																	}
																</span>
																<span className="text-sm text-gray-500">
																	Aprobado
																</span>
															</span>
														</div>
													</li>
												);
											}
										} else {
											if (
												approver.usersxcategories
													.approval_level > 1
											) {
												// Check if the actual approver level is major than 1
												if (
													payment.pay_approval_level ===
													approver.usersxcategories
														.approval_level
												) {
													// Check if the actual approver level is equal to the payment approval level
													return (
														<li
															key={id}
															className="relative pb-10"
														>
															<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-gray-300"></div>
															<div className="relative flex items-start">
																<span className="flex items-start h-9">
																	<span className="relative flex items-center justify-center h-8 w-8 bg-white border-2 border-emerald-400 rounded-full">
																		<span className="h-2.5 w-2.5 bg-emerald-400 rounded-full"></span>
																	</span>
																</span>
																<span className="flex flex-col ml-4 min-w-0">
																	<span className="text-sm font-medium text-emerald-400">
																		{
																			approver.name
																		}
																	</span>
																	<span className="text-sm text-gray-500">
																		Pendiente
																		por
																		aprobar
																	</span>
																</span>
															</div>
														</li>
													);
												} else {
													return (
														<li
															key={id}
															className="relative pb-10"
														>
															<div className="absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5 bg-gray-300"></div>
															<div className="relative flex items-start">
																<span className="flex items-center h-9">
																	<span className="relative flex items-center justify-center h-8 w-8 bg-white border-gray-300 border-2 rounded-full z-10">
																		<span className="h-2.5 w-2.5 bg-transparent rounded-full"></span>
																	</span>
																</span>
																<span className="flex flex-col ml-4 min-w-0">
																	<span className="text-sm">
																		{
																			approver.name
																		}
																	</span>
																	<span className="text-sm text-gray-500">
																		Pendiente
																		por
																		aprobar
																	</span>
																</span>
															</div>
														</li>
													);
												}
											} else {
												if (
													payment.pay_approval_level ===
													approver.usersxcategories
														.approval_level
												) {
													// Check if the actual approver level is equal to the payment approval level
													return (
														<li
															key={id}
															className="relative"
														>
															<div className="relative flex items-start">
																<span className="flex items-start h-9">
																	<span className="relative flex items-center justify-center h-8 w-8 bg-white border-2 border-emerald-400 rounded-full">
																		<span className="h-2.5 w-2.5 bg-emerald-400 rounded-full"></span>
																	</span>
																</span>
																<span className="flex flex-col ml-4 min-w-0">
																	<span className="text-sm font-medium text-emerald-400">
																		{
																			approver.name
																		}
																	</span>
																	<span className="text-sm text-gray-500">
																		Pendiente
																		por
																		aprobar
																	</span>
																</span>
															</div>
														</li>
													);
												} else {
													return (
														<li
															key={id}
															className="relative"
														>
															<div className="relative flex items-start">
																<span className="flex items-center h-9">
																	<span className="relative flex items-center justify-center h-8 w-8 bg-white border-gray-300 border-2 rounded-full z-10">
																		<span className="h-2.5 w-2.5 bg-transparent rounded-full"></span>
																	</span>
																</span>
																<span className="flex flex-col ml-4 min-w-0">
																	<span className="text-sm font-regular">
																		{
																			approver.name
																		}
																	</span>
																	<span className="text-sm text-gray-500">
																		Pendiente
																		por
																		aprobar
																	</span>
																</span>
															</div>
														</li>
													);
												}
											}
										}
									}
								})}
						</ol>
					);
					break;
				}
			}
		};

		const download = (file, name) => {
			// Crear un objeto URL del blob
			const url = window.URL.createObjectURL(new Blob([file]));

			// Crear un enlace de descarga
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", name);

			// Hacer clic en el enlace para descargar el archivo
			document.body.appendChild(link);
			link.click();

			// Liberar el objeto URL
			window.URL.revokeObjectURL(url);
		};

		const downloadReports = async () => {
			let file = await downloadFile(
				"REPORT",
				payment.id,
				payment.pay_report_file_name
			);

			download(file, payment.pay_report_file_name);
		};

		return (
			<div className="flex flex-col justify-between h-full px-0 sm:px-6">
				<div>
					<div className="list-options flex flex-col sm:flex-row items-center justify-between sm:mb-10 sm:m-0 h-20">
						<div className="flex items-center justify-between self-start px-4 sm:px-0 h-5 sm:h-20 w-full">
							<BreadCrumbs />
							<RefreshIcon
								className={`h-4 w-4 stroke-slate-400 cursor-pointer hover:stroke-black${
									status ? " rotate" : ""
								}`}
								onClick={() => handleRefreshItems()}
							/>
						</div>
					</div>
					<div className="px-4 sm:px-0 mb-5">
						<div className="px-4 sm:px-0">
							<h3 className="flex items-center gap-2 text-lg font-semibold leading-7 text-gray-900">
								<ClipboardCheckIcon className="h-5 w-5 fill flex-shrink-0 text-gray-500" />
								Orden {handleOrderReference(payment.id)}
							</h3>
							<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
								Detalles generales de la orden de pago.
							</p>
						</div>

						<div className="flex gap-2 justify-end mt-3 w-full">
							{handleButtonsByState()}
						</div>

						<div className="mt-6 border-t border-gray-200">
							<dl>
								<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Descripción
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										{payment.pay_description}
									</dd>
								</div>
								<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Creado
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										{formatDateUTC(
											payment.created
										).longDateTime24()}
									</dd>
								</div>
								<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Creado por
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										{payment.user.name}
									</dd>
								</div>
								<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-gray-200">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Fecha de vencimiento
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										{payment.invoice_due_date
											? payment.invoice_due_date
											: "---"}
									</dd>
								</div>
								<button
									title="Ver notas"
									className="flex items-center gap-1 mt-2 text-sm font-normal text-blue-500 hover:text-blue-400"
									onClick={() =>
										setModal({
											modal: "Logs",
											show: true,
										})
									}
								>
									Ver logs
									<ExpandIcon className="h-4 w-4" />
								</button>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Cuenta de pago
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										{payment.payaccount
											? `${payment.payaccount.pay_acc_description} - ${payment.payaccount.pay_acc_account_number} - ${payment.payaccount.pay_acc_ledger_account}`
											: "---"}
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Tipo de pago
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<span
											className={`py-1 px-2 rounded-full ${
												choices[payment.pay_type]
											}`}
											title={capitalize(payment.pay_type)}
										>
											{capitalize(payment.pay_type)}
										</span>
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Estado de pago
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<span
											className={`py-1 px-2 rounded-full ${
												choices[
													payment.paystate
														.pay_sta_description
												]
											}`}
											title={capitalize(
												payment.paystate
													.pay_sta_description
											)}
										>
											{capitalize(
												payment.paystate
													.pay_sta_description
											)}
										</span>
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Categoría
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="flex min-w-0 flex-1 gap-2">
											<span className="truncate font-medium">
												{capitalize(
													payment.category
														.cat_description
												)}
											</span>
											<span className="flex-shrink-0 text-gray-400">
												{`- ${payment.category.cat_approval_level} aprobadores`}
											</span>
										</div>
										{handleApprovers()}
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="flex flex-col gap-1 items-start text-sm font-medium leading-6 text-gray-900">
										<span>Notas</span>
										<button
											title="Ver notas"
											className="flex items-center gap-1 font-normal text-blue-500 hover:text-blue-400"
											onClick={() =>
												setModal({
													modal: "Notas",
													show: true,
												})
											}
										>
											Ver notas
											<ExpandIcon className="h-4 w-4" />
										</button>
									</dt>
									{items?.payment.paystate
										.pay_sta_description !== "RECHAZADO" &&
									items?.payment.paystate
										.pay_sta_description !== "UNIFICADO" &&
									items?.payment.paystate
										.pay_sta_description !==
										"CONTABILIZADO" &&
									(searchForRole("ADMINISTRADOR") > -1 ||
										searchForRole("USUARIO") > -1 ||
										searchForRole("TESORERIA") > -1 ||
										searchForRole("CONTABILIDAD") > -1 ||
										searchForRole("FINANCIERO") > -1) ? (
										<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
											<form
												onSubmit={handleNoteFormSubmit}
											>
												<div className="flex flex-col flex-1 gap-2">
													<textarea
														ref={noteRef}
														id="description"
														className="block p-2.5 h-28 min-h-max w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-200 focus:ring-primary-500 focus:border-primary-500"
														style={{
															minHeight: 80,
														}}
														defaultValue={""}
														onChange={(e) =>
															setNote(
																e.target.value
															)
														}
														placeholder="Ingresa una nota..."
													></textarea>
													<div className="flex items-center justify-end gap-3 w-full">
														<span className="text-gray-500">
															* Máximo 300
															caracteres
														</span>
														<button
															type="submit"
															className="btn self-end pointer-events-auto rounded-md bg-blue-500 px-3 py-2 text-[0.8125rem] font-semibold leading-5 text-white hover:bg-blue-400"
															onClick={() =>
																handleAddNote()
															}
															disabled={
																status ===
																	true ||
																noteRef.current
																	?.value
																	.length >
																	300 ||
																noteRef.current
																	?.value ===
																	""
																	? true
																	: false
															}
														>
															Añadir nota
														</button>
													</div>
												</div>
											</form>
										</dd>
									) : null}
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-t boder-gray-200">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										<div className="flex flex-row">
											<span className="text-gray-700">
												Pagos
											</span>
											<span className="flex-shrink-0 text-gray-400 font-normal">
												{` - ${order.length} ${
													order.length > 1
														? " pagos"
														: "pago"
												} en total`}
											</span>
										</div>
										<div>
											<span className="text-gray-700">
												Total a pagar
											</span>
											<span className="flex-shrink-0 text-gray-400 font-normal">
												{` - ${handleCurrency(
													order.reduce(
														(total, item) =>
															total +
															item.pay_lis_payment_amount,
														0
													)
												)}`}
											</span>
										</div>
									</dt>
									<dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
										{!order.length > 0 ? (
											"---"
										) : (
											<Accordion
												className={
													"flex flex-col gap-2"
												}
											>
												<div className="relative flex items-center gap-2 self-end">
													<button
														title="Descargar órdenes"
														className="py-2 px-2 bg-blue-500 hover:bg-blue-400 rounded-md"
														onClick={() =>
															downloadReports()
														}
													>
														<DownloadIcon className="h-4 w-4 stroke-white" />
													</button>
													<input
														type="search"
														name="search"
														id="search"
														title="Buscar por
Concepto
Documento del titutar"
														className="block py-1.5 pr-10 w-44 sm:w-52 rounded-md border-gray-200 placeholder:text-gray-400"
														onChange={(e) =>
															setQuery(
																e.target.value
															)
														}
														aria-invalid="true"
														aria-describedby="email-error"
													/>
													<div className="absolute top-0 right-0 bottom-0 flex items-center pr-3 pointer-events-none">
														<MagnifyingGlassIcon className="h-5 w-5 stroke-gray-400" />
													</div>
												</div>
												{order
													.filter((item) => {
														const searchItem =
															query.toLocaleLowerCase();
														const value =
															item.pay_lis_holder_document_number.toLocaleLowerCase();
														const value2 =
															item.pay_lis_payment_concept.toLocaleLowerCase();
														if (!searchItem)
															return true;
														return (
															value
																.toLowerCase()
																.indexOf(
																	searchItem.toLowerCase()
																) > -1 ||
															value2
																.toLowerCase()
																.indexOf(
																	searchItem.toLowerCase()
																) > -1
														);
													})
													.map((item, id) => (
														<div
															key={item.id}
															className="flex items-start"
														>
															{item.state ===
															true ? (
																<div className="flex items-center justify-start h-14 w-14">
																	<div
																		title="Inhabilitar pago"
																		className="flex items-center justify-center h-10 w-10 hover:bg-red-400 stroke-black hover:stroke-white rounded-md cursor-pointer"
																		onClick={() => {
																			setSelectedItem(
																				item
																			);
																			setModal(
																				{
																					modal: "Pago",
																					show: true,
																				}
																			);
																		}}
																	>
																		<BlockIcon className="h-5 w-5 stroke-inherit" />
																	</div>
																</div>
															) : null}
															<Accordion.Item
																className={`flex flex-col items-center justify-between py-4 pl-4 pr-5 w-full text-sm leading-6 rounded-md border border-gray-200${
																	item.state ===
																	false
																		? " border-red-500"
																		: ""
																}`}
															>
																<Accordion.Header
																	className={`flex w-full flex-1 items-center hover:fill-blue-500 hover:text-blue-500 hover:cursor-pointer select-none${
																		item.state ===
																		false
																			? " hover:fill-red-500 hover:text-red-500"
																			: ""
																	}`}
																>
																	<div className="flex w-0 flex-1 items-center">
																		<TicketIcon
																			className={`h-5 w-5${
																				item.state ===
																				false
																					? " stroke-red-500"
																					: ""
																			}`}
																		/>
																		<div className="ml-4 flex min-w-0 flex-1 gap-2">
																			<span
																				title={
																					item.pay_lis_payment_concept
																				}
																				className={`truncate font-medium text-inherit${
																					item.state ===
																					false
																						? " text-red-500"
																						: ""
																				}`}
																			>
																				{`#${
																					id +
																					1
																				} - ${
																					item.pay_lis_payment_concept
																				}`}
																			</span>
																		</div>
																		<span className="text-gray-400">
																			{
																				item.pay_lis_holder_document_number
																			}
																		</span>
																	</div>
																</Accordion.Header>
																<Accordion.Body
																	className={
																		"mt-5 w-full"
																	}
																>
																	<div className="grid gap-4 md:grid-cols-2 md:gap-6">
																		<div className="w-full">
																			<span className="block mb-2 text-sm font-medium text-gray-900">
																				Concepto
																			</span>
																			<span className="text-gray-700">
																				{
																					item.pay_lis_payment_concept
																				}
																			</span>
																		</div>
																		<div className="w-full">
																			<span className="block mb-2 text-sm font-medium text-gray-900">
																				Valor
																			</span>
																			<span className="text-gray-700">
																				{handleCurrency(
																					item.pay_lis_payment_amount
																				)}
																			</span>
																		</div>
																		<div className="w-full">
																			<span className="block mb-2 text-sm font-medium text-gray-900">
																				Cuenta
																			</span>
																			<span className="text-gray-700">
																				{
																					item.pay_lis_account_number
																				}
																			</span>
																			<span className="flex-shrink-0 text-gray-400">
																				{` - ${capitalize(
																					item.pay_lis_account_type
																				)}`}
																			</span>
																		</div>
																		<div className="w-full">
																			<span className="block mb-2 text-sm font-medium text-gray-900">
																				Banco
																			</span>
																			<div className="text-gray-700">
																				{
																					item
																						.bank
																						.ban_description
																				}
																			</div>
																		</div>
																		<div className="w-full">
																			<span className="block mb-2 text-sm font-medium text-gray-900">
																				Nombre
																				titular
																			</span>
																			<span className="text-gray-700">
																				{
																					item.pay_lis_holder_name
																				}
																			</span>
																		</div>
																		<div className="w-full">
																			<span className="block mb-2 text-sm font-medium text-gray-900">
																				Email
																				titular
																			</span>
																			<span className="block truncate w-48 sm:w-full text-gray-700">
																				{item.pay_lis_holder_mail ??
																					"---"}
																			</span>
																		</div>
																		<div className="w-full">
																			<span className="block mb-2 text-sm font-medium text-gray-900">
																				Documento
																			</span>
																			<span className="text-gray-700">
																				{
																					item.pay_lis_holder_document_number
																				}
																			</span>
																			<span className="flex-shrink-0 text-gray-400">
																				{` - ${capitalize(
																					item
																						.documenttype
																						.doc_typ_description
																				)}`}
																			</span>
																		</div>
																		<div className="w-full">
																			<span className="block mb-2 text-sm font-medium text-gray-900">
																				Inmueble
																			</span>
																			<div className="flex items-center gap-1 text-gray-700">
																				<HomeIcon className="h-4 w-4" />
																				{item.pay_lis_holder_immovable ??
																					"---"}
																			</div>
																		</div>
																	</div>
																</Accordion.Body>
															</Accordion.Item>
														</div>
													))}
											</Accordion>
										)}
									</dd>
								</div>
							</dl>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="flex flex-col justify-between h-full px-0 sm:px-6">
				<div>
					<div className="list-options flex flex-col sm:flex-row items-center justify-between mb-10 sm:m-0 h-20">
						<div className="flex items-center self-start pl-4 sm:p-0 h-20">
							<BreadCrumbs />
						</div>
					</div>
					<div className="px-4 sm:px-0 mb-5">
						<div className="px-4 sm:px-0">
							<h3 className="flex items-center gap-2 text-lg font-semibold leading-7 text-gray-900">
								<ClipboardCheckIcon className="h-5 w-5 fill flex-shrink-0 text-gray-500" />
								<span className="flex self-start h-4 w-40 bg-gray-200 rounded-md skeleton"></span>
							</h3>
							<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
								Detalles generales de la orden de pago.
							</p>
						</div>

						<div className="mt-6 border-t border-gray-200">
							<dl>
								<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Descripción
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<span className="flex self-start h-4 w-60 bg-gray-200 rounded-md skeleton"></span>
									</dd>
								</div>
								<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Creado
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<span className="flex self-start h-4 w-60 bg-gray-200 rounded-md skeleton"></span>
									</dd>
								</div>
								<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-gray-200">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Creado por
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<span className="flex self-start h-4 w-60 bg-gray-200 rounded-md skeleton"></span>
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Cuenta de pago
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<span className="flex self-start h-4 w-60 bg-gray-200 rounded-md skeleton"></span>
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Tipo de pago
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<span
											className={`py-1 px-2 rounded-full`}
										>
											<span className="flex self-start h-4 w-44 bg-gray-200 rounded-md skeleton"></span>
										</span>
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Estado de pago
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<span
											className={`py-1 px-2 rounded-full`}
										>
											<span className="flex self-start h-4 w-44 bg-gray-200 rounded-md skeleton"></span>
										</span>
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Categoría
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="flex min-w-0 flex-1 gap-2">
											<span className="flex self-start h-4 w-32 bg-gray-200 rounded-md skeleton"></span>
											<span className="flex self-start h-4 w-52 bg-gray-200 rounded-md skeleton"></span>
										</div>
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="flex flex-col gap-1 items-start text-sm font-medium leading-6 text-gray-900">
										<span>Notas</span>
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<form>
											<div className="flex flex-col flex-1 gap-2">
												<textarea
													ref={noteRef}
													id="description"
													className="block p-2.5 h-28 min-h-max w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-200 focus:ring-primary-500 focus:border-primary-500"
													style={{ minHeight: 80 }}
													defaultValue={""}
													onChange={(e) =>
														setNote(e.target.value)
													}
													placeholder="Ingresa una nota..."
												></textarea>
												<div className="flex items-center justify-end gap-3 w-full">
													<span className="text-gray-500">
														* Máximo 300 caracteres
													</span>
													<button
														type="submit"
														className="btn self-end pointer-events-auto rounded-md bg-blue-500 px-3 py-2 text-[0.8125rem] font-semibold leading-5 text-white hover:bg-blue-400"
														disabled={
															status === true ||
															noteRef.current
																?.value.length >
																300 ||
															noteRef.current
																?.value === ""
																? true
																: false
														}
													>
														Añadir nota
													</button>
												</div>
											</div>
										</form>
									</dd>
								</div>
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-t boder-gray-200">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Pagos
									</dt>
									<dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
										<Accordion
											className={"flex flex-col gap-2"}
										>
											<div className="relative self-end">
												<input
													type="search"
													name="search"
													id="search"
													title="Buscar por Documento del titutar"
													className="block py-1.5 pr-10 rounded-md border-gray-200 placeholder:text-gray-400"
													onChange={(e) =>
														setQuery(e.target.value)
													}
													placeholder="Ej: 1005748906"
													aria-invalid="true"
													aria-describedby="email-error"
												/>
												<div className="absolute top-0 right-0 bottom-0 flex items-center pr-3 pointer-events-none">
													<MagnifyingGlassIcon className="h-5 w-5 stroke-gray-400" />
												</div>
											</div>

											<Accordion.Item
												key={0}
												className="flex flex-col items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 rounded-md border border-gray-200"
											>
												<Accordion.Header className="flex w-full flex-1 items-center hover:fill-blue-500 hover:text-blue-500 hover:cursor-pointer select-none">
													<div className="flex w-0 flex-1 items-center">
														<TicketIcon className="h-5 w-5" />
														<div className="ml-4 flex min-w-0 flex-1 gap-2">
															<span className="flex self-start h-4 w-full bg-gray-200 rounded-md skeleton"></span>
														</div>
														<span className="flex self-start h-4 w-full bg-gray-200 rounded-md skeleton"></span>
													</div>
												</Accordion.Header>
												<Accordion.Body
													className={"mt-5 w-full"}
												>
													<div className="grid gap-4 md:grid-cols-2 md:gap-6">
														<div className="w-full">
															<span className="block mb-2 text-sm font-medium text-gray-900">
																Concepto
															</span>
															<span className="flex self-start h-4 w-56 bg-gray-200 rounded-md skeleton"></span>
														</div>
														<div className="w-full">
															<span className="block mb-2 text-sm font-medium text-gray-900">
																Valor
															</span>
															<span className="flex self-start h-4 w-32 bg-gray-200 rounded-md skeleton"></span>
														</div>
														<div className="w-full">
															<span className="block mb-2 text-sm font-medium text-gray-900">
																Cuenta
															</span>
															<span className="text-gray-700">
																<span className="flex self-start h-4 w-32 bg-gray-200 rounded-md skeleton"></span>
															</span>
														</div>
														<div className="w-full">
															<span className="block mb-2 text-sm font-medium text-gray-900">
																Banco
															</span>
															<div className="text-gray-700">
																<span className="flex self-start h-4 w-48 bg-gray-200 rounded-md skeleton"></span>
															</div>
														</div>
														<div className="w-full">
															<span className="block mb-2 text-sm font-medium text-gray-900">
																Nombre titular
															</span>
															<span className="text-gray-700">
																<span className="flex self-start h-4 w-full bg-gray-200 rounded-md skeleton"></span>
															</span>
														</div>
														<div className="w-full">
															<span className="block mb-2 text-sm font-medium text-gray-900">
																Email titular
															</span>
															<span
																className="text-gray-700"
																onClick={() => (
																	<span className="flex self-start h-4 w-full bg-gray-200 rounded-md skeleton"></span>
																)}
															>
																<span className="flex self-start h-4 w-full bg-gray-200 rounded-md skeleton"></span>
															</span>
														</div>
														<div className="w-full">
															<span className="block mb-2 text-sm font-medium text-gray-900">
																Documento
															</span>
															<span className="text-gray-700">
																<span className="flex self-start h-4 w-48 bg-gray-200 rounded-md skeleton"></span>
															</span>
														</div>
														<div className="w-full">
															<span className="block mb-2 text-sm font-medium text-gray-900">
																Inmueble
															</span>
															<div className="flex items-center gap-1 text-gray-700">
																<HomeIcon className="h-4 w-4" />
																<span className="flex self-start h-4 w-24 bg-gray-200 rounded-md skeleton"></span>
															</div>
														</div>
													</div>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</dd>
								</div>
							</dl>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PaymentDetails;
