import React, { useEffect, useRef } from "react";
import { FilterClearIcon, PlusIcon } from "../assets/svg/icons";

function OffCanvas({
	isOpen = false,
	setIsOpen,
	onClose,
	onClear,
	children = "",
	filter = false,
	staticOffCanvas = false,
	closeOnOutsideClick = false,
}) {
	const offCanvasRef = useRef();
	if (staticOffCanvas) closeOnOutsideClick = false;

	useEffect(() => {
		if (closeOnOutsideClick) {
			function handleClickOutside(e) {
				if (
					offCanvasRef.current &&
					!offCanvasRef.current.contains(e.target)
				) {
					setIsOpen(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [closeOnOutsideClick, setIsOpen]);

	if (isOpen)
		return (
			<div
				ref={offCanvasRef}
				className={`offcanvas${staticOffCanvas ? " static" : ""}`}
			>
				<div className="offcanvas-content flex flex-col p-4">
					<div className="offcanvas-content-header flex flex-row items-center justify-between pb-2 h-10 border-b border-slate-300">
						<span className="title text-lg font-bold">Filtros</span>
						<div className="flex flex-row gap-2 items-center h-10">
							{filter ? (
								<button
									title="Restablecer filtros"
									className="flex items-center justify-center h-8 w-8"
									onClick={onClear}
								>
									<FilterClearIcon className={"h-4"} />
								</button>
							) : (
								""
							)}
							<button
								title="Cerrar"
								className="h-8 w-8"
								onClick={onClose}
							>
								<PlusIcon className={"rotate-45 h-6"} />
							</button>
						</div>
					</div>
					{children}
				</div>
			</div>
		);
}

export default OffCanvas;
