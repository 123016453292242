import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const getCategories = async () => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "get",
		maxBodyLength: Infinity,
		url: "https://apipagoterceros.spagrupoinmobiliario.com/api/categories",
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);

	return data.categories;
};

export default getCategories;
