import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Toaster, toast } from "react-hot-toast";

//import LV_logotipo from "../../../assets/images/LV_logotipo.png";
import { useAuthContext } from "../../../contexts/AuthContext";
import secureLocalStorage from "react-secure-storage";
import SPALogo from "../../../assets/images/SPA_full_logo.png";
import { MicrosoftIcon } from "../../../assets/svg/icons";
import delay from "../../../utils/delay";

const USER_REMBR = "USER_REMBR";
const USER_REMBR_EMAIL = "USER_REMBR_EMAIL";
const USER_REMBR_PASS = "USER_REMBR_PASS";

function Login() {
	const { login } = useAuthContext();
	const [email, setEmail] = useState(
		secureLocalStorage.getItem(USER_REMBR_EMAIL)
	);
	const [pass, setPass] = useState(
		secureLocalStorage.getItem(USER_REMBR_PASS)
	);
	const [status, setStatus] = useState(false);
	const [rememberMe, setRememberMe] = useState(
		secureLocalStorage.getItem(USER_REMBR) ? true : false
	);

	const handleAuth = async (userData, type) => {
		//e.preventDefault();
		setStatus(true);
		toast.dismiss();
		const toastId = toast.loading("Autenticando...");

		//if (email !== "" && email !== "##" && pass !== "" && pass !== "##") {
		const message = await login(userData, type);

		switch (message) {
			case "USER_IS_NOT_ACTIVE":
				toast.error("El usuario está inhabilitado", {
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "USER_NOT_FOUND_IN_TENANT":
				toast.error("El usuario no existe", {
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "INCORRECT_PASSWORD":
				toast.error("Contraseña incorrecta", {
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "ACCESS_GRANTED":
				toast.success("Bienvenido", {
					position: "bottom-right",
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			default:
				toast.error("Verifica tus datos", {
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;
		}
		/*} else {
			toast.error("Diligencia todos los campos", {
				id: toastId,
				style: {
					border: "1px solid #FF4C4C",
				},
			});
		}*/
		toast.dismiss();
		setStatus(false);
	};

	const disableButton = async () => {
		setStatus(true);
		await delay(5000);
		setStatus(false);
	};

	return (
		<div className="login flex h-screen w-full items-center justify-center">
			<Helmet>
				<title>Iniciar sesi&oacute;n</title>
			</Helmet>
			<div className="login-form relative flex w-80 flex-col gap-4 bg-white p-8">
				<div className="absolute -top-28 left-0 flex w-full items-center justify-center">
					<img
						src={SPALogo}
						title="SPA Grupo Inmobiliario"
						alt="logo_SPA_Grupo_Inmobiliario.png"
						className="h-24"
					/>
					{/*<SPALogo className="h-16 w-16" />*/}
				</div>
				<span className="text-2xl font-semibold text-gray-800">
					Iniciar sesi&oacute;n
				</span>
				{/* <form className="flex flex-col items-end gap-2">
					<label htmlFor="userEmail" className="block w-full">
						<span className="text-gray">Email</span>
						<input
							className="mt-1 block w-full"
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							type="email"
							name="userEmail"
							id="userEmail"
							placeholder="Email"
							title="Email"
						/>
					</label>
					<label htmlFor="userPass" className="block w-full">
						Contrase&ntilde;a
						<input
							className="mt-1 block w-full"
							onChange={(e) => {
								setPass(e.target.value);
							}}
							type="password"
							name="userPass"
							id="userPass"
							placeholder="Contrase&ntilde;a"
							title="Contrase&ntilde;a"
						/>
					</label>
					<label className="flex w-full gap-2">
						<input
							type="checkbox"
							name="rememberMe"
							title="Recu&eacute;rdame"
							onClick={(event) =>
								setRememberMe(event.target.checked)
							}
						/>
						<span className="text-sm text-gray-500">
							Recu&eacute;rdame
						</span>
					</label>
					{status ? (
						<div className="lds-dual-ring mt-2"></div>
					) : (
						<button
							className="btn primary mt-2 w-full"
							type="submit"
							title="Iniciar sesi&oacute;n"
							onClick={() =>
								handleAuth(
									{ email: email, password: pass },
									"login"
								)
							}
						>
							Iniciar sesi&oacute;n
						</button>
					)}
				</form> */}
				<div className="mt-2 flex w-full flex-col items-center justify-center gap-4">
					<span className="text-sm font-normal text-gray-500">
						Continúa con:
					</span>
					<div className="flex w-full flex-col gap-1">
						<button
							className="flex w-full items-center justify-center gap-2 rounded-md border px-3 py-2 font-semibold text-gray-600 hover:bg-gray-50 disabled:bg-gray-100"
							disabled={status}
							onClick={() => {
								disableButton();
								//setStatus(true);
								let popup = window.open(
									"https://apiauth.spagrupoinmobiliario.com/tenantAuth/auth/microsoft",
									"microsoftLogin",
									"toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=620,height=700"
								);

								window.addEventListener("message", (event) => {
									if (
										event.origin ===
										"https://apiauth.spagrupoinmobiliario.com"
									) {
										if (
											event.data &&
											event.data.status === 200
										) {
											handleAuth(
												event.data.user_info,
												"microsoft"
											);
											popup.close();
										}
									}
								});
							}}
						>
							<MicrosoftIcon className="h-4 w-4" />
							{status ? (
								<div className="lds-ellipsis flex h-2 items-center">
									<div className="h-2 w-2 bg-gray-300"></div>
									<div className="h-2 w-2 bg-gray-300"></div>
									<div className="h-2 w-2 bg-gray-300"></div>
								</div>
							) : (
								"Microsoft"
							)}
						</button>
					</div>
				</div>
			</div>
			<Toaster />
		</div>
	);
}

export default Login;
