import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const createMultiplePayments = async (formData) => {
	console.log(Object.fromEntries(formData));
	const tk = await getAccessToken();

	const payLoad = {
		method: "post",
		maxBodyLength: Infinity,
		url: "https://apipagoterceros.spagrupoinmobiliario.com/api/payments/createMultiplePayments",
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		data: formData,
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);
	console.log(data);

	if (data.payment) {
		return "PAYMENT_CREATED_SUCCESSFULLY";
	} else {
		return data.error;
	}
};

export default createMultiplePayments;
