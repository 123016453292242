import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const createIndividualPayment = async (body) => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "post",
		maxBodyLength: Infinity,
		url: "https://apipagoterceros.spagrupoinmobiliario.com/api/payments/createIndividualPayment",
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		data: body,
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);
	console.log(data);

	if (data.error) {
		return data.error;
	} else {
		return "PAYMENT_CREATED_SUCCESSFULLY";
	}
};

export default createIndividualPayment;
