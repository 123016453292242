/**
 * Returns a boolean value of the comparison between a value and its expected type
 *
 * @param {any} value The value to be compared to.
 * @param {any} type The variable type to compare.
 * @returns {Boolean} Returns the comparison result.
 */
export function isType(value, type = undefined) {
	if (type) {
		type = type.toLowerCase();

		if (type === "array") {
			return Array.isArray(value);
		} else {
			return value != null && typeof value == type;
		}
	} else {
		if (Array.isArray(value)) {
			return "array";
		} else {
			return typeof value;
		}
	}
}

/**
 * Returns a boolean value between the comparision of two values
 * (string, number, boolean, array or object)
 *
 * @param {any} firstValue The first value to compare.
 * @param {any} secondValue The second value to compare.
 * @returns {Boolean} Returns the comparison result.
 */
export function isEqual(firstValue, secondValue) {
	if (firstValue === secondValue) {
		return true;
	}

	if (
		firstValue == null ||
		secondValue == null ||
		(!isType(firstValue, "object") && !isType(secondValue, "object"))
	) {
		return firstValue !== firstValue && secondValue !== secondValue;
	}

	return isEqualDeep(firstValue, secondValue);
}

/**
 * Returns a boolean value of the search
 *
 * @param {Array} arrayToSearch The array to search in.
 * @param {any} valueToFind The value to be searched.
 * @returns {Boolean} Returns the comparison result.
 */
export function inArray(arrayToSearch, valueToFind) {
	if (!isType(arrayToSearch, "array")) {
		arrayToSearch = [arrayToSearch];
	}

	for (let i = 0; i < arrayToSearch.length; i++) {
		if (isEqual(arrayToSearch[i], valueToFind)) return true;
	}

	return false;
}

function isEqualDeep(firstValue, secondValue) {
	return JSON.stringify(firstValue) === JSON.stringify(secondValue);
}

export function isNumeric(number) {
	return !isNaN(parseFloat(number)) && !isNaN(+number);
}
