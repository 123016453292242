import axios from "axios";

const getAccessToken = async () => {
	const payLoad = {
		method: "post",
		maxBodyLength: Infinity,
		url: "https://apiauth.spagrupoinmobiliario.com/tenantAuth/auth/getAccessToken",
		headers: {
			xapikey: "g4Q$9K&%$S1Om=$UEepA",
		},
		data: {
			email: process.env.REACT_APP_ADMIN_EMAIL,
			password: process.env.REACT_APP_ADMIN_PASSWORD,
		},
	};

	const { data } = await axios(payLoad);

	return data;
};

export default getAccessToken;
