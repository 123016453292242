import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import {
	PeopleFilledIcon,
	SPALogo,
	SquaresFilledIcon,
	SquaresPlusFilledIcon,
	SquaresStackFilledIcon,
	SquaresStackIcon,
	TagFilledIcon,
} from "../assets/svg/icons";

function Sidebar() {
	let navigate = useNavigate();
	const location = useLocation();
	const actualCrumb = location.pathname.split("/").pop();

	const [sidebarStatus, setSidebarStatus] = useState(); // collapse sidebar variable
	const { logout, userData } = useAuthContext(); // get AuthContext attributes and methods

	var appRoles = [];
	for (let i = 0; i < userData.appRoles.length; i++) {
		appRoles = [...appRoles, userData.appRoles[i].rol_description];
	}

	// set variable with app render size
	// position [0] for width
	// position [1] for height
	const [windowSize, setWindowSize] = useState([
		window.innerWidth,
		window.innerHeight,
	]);

	const handleSidebar = (value) => {
		if (windowSize[0] > 720) {
			setSidebarStatus(value);
		}
	};

	// update variable's values with the changing window's size
	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize([window.innerWidth, window.innerHeight]);
		};

		window.addEventListener("resize", handleWindowResize);

		// if window's width it's less than 720px then collapse the sidebar
		if (windowSize[0] < 720) {
			setSidebarStatus(true);
		}

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	});

	const searchForRole = (roleToSearch) => {
		let isThere = appRoles.findIndex(
			(role) => role.toLowerCase() === roleToSearch.toLowerCase()
		);
		return isThere;
	};

	return (
		<div className={`sidebar ${sidebarStatus ? "collapsed" : ""}`}>
			<div className="sidebar-image" title="Pago terceros SPA">
				<SPALogo
					onClick={() => {
						if (windowSize[0] > 720) {
							handleSidebar(!sidebarStatus);
						}
					}}
				/>
			</div>
			<div className="sidebar-items mt-5">
				<span className="sidebar-title">Acciones principales</span>
				<ul className="sidebar-list">
					{searchForRole("administrador") > -1 ||
					searchForRole("tesoreria") > -1 ||
					searchForRole("contabilidad") > -1 ||
					searchForRole("financiero") > -1 ? (
						<li
							className={`sidebar-item-group${
								actualCrumb === "pagos" ? " active" : ""
							}`}
							title="Pagos"
							onClick={() => navigate("/pagos")}
						>
							<div className="sidebar-item">
								<span className="sidebar-item-icon">
									<SquaresStackFilledIcon className="h-4 w-4" />
								</span>
								<span className="sidebar-item-title">
									Pagos
								</span>
							</div>
							{/*<span className="sidebar-item-notification">2</span>*/}
						</li>
					) : null}
					{searchForRole("aprobador") > -1 ? (
						<li
							className={`sidebar-item-group${
								actualCrumb === "pagos-por-aprobar"
									? " active"
									: ""
							}`}
							title="Pagos por aprobar"
							onClick={() => navigate("/pagos-por-aprobar")}
						>
							<div className="sidebar-item">
								<span className="sidebar-item-icon">
									<SquaresFilledIcon className="h-4 w-4" />
								</span>
								<span className="sidebar-item-title">
									Pagos por aprobar
								</span>
							</div>
						</li>
					) : null}
					{searchForRole("usuario") > -1 ? (
						<li
							className={`sidebar-item-group${
								actualCrumb === "mis-pagos" ? " active" : ""
							}`}
							title="Mis pagos"
							onClick={() => navigate("/mis-pagos")}
						>
							<div className="sidebar-item">
								<span className="sidebar-item-icon">
									<SquaresPlusFilledIcon className="h-4 w-4" />
								</span>
								<span className="sidebar-item-title">
									Mis pagos
								</span>
							</div>
						</li>
					) : null}
					{searchForRole("administrador") > -1 ? (
						<>
							<li
								className={`sidebar-item-group${
									actualCrumb === "categorias-de-pagos"
										? " active"
										: ""
								}`}
								title="Categoría de pagos"
								onClick={() => navigate("/categorias-de-pagos")}
							>
								<div className="sidebar-item">
									<span className="sidebar-item-icon">
										<TagFilledIcon className="h-4 w-4" />
									</span>
									<span className="sidebar-item-title">
										Categorías de pagos
									</span>
								</div>
							</li>
							<li
								className={`sidebar-item-group${
									actualCrumb === "usuarios" ? " active" : ""
								}`}
								title="Usuarios"
								onClick={() => navigate("/usuarios")}
							>
								<div className="sidebar-item">
									<span className="sidebar-item-icon">
										<PeopleFilledIcon className="h-4 w-4" />
									</span>
									<span className="sidebar-item-title">
										Usuarios
									</span>
								</div>
							</li>
						</>
					) : null}
					{/*<li
						className={`sidebar-item-group${
							actualCrumb === "roles" ? " active" : ""
						}`}
						title="Roles"
						onClick={() => navigate("/roles")}
					>
						<div className="sidebar-item">
							<span className="sidebar-item-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 640 512"
								>
									<path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
								</svg>
							</span>
							<span className="sidebar-item-title">Roles</span>
						</div>
					</li>*/}
				</ul>
			</div>
			<div className="sidebar-logout mt-2">
				<span
					title="Cerrar sesi&oacute;n"
					className="sidebar-logout-btn"
					onClick={() => logout()}
				>
					<span>Cerrar sesi&oacute;n</span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
					>
						<path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
					</svg>
				</span>
			</div>
		</div>
	);
}

export default Sidebar;
