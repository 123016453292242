import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContextProvider, useAuthContext } from "./contexts/AuthContext";
import Login from "./features/authentication/components/Login";
import Users from "./pages/Users";
import {
	USERS,
	LOGIN,
	INDEX,
	PAYMENTS,
	ROLES,
	CATEGORIES,
	PAYMENTS_DETAIL,
	MY_PAYMENTS,
	MY_PAYMENTS_DETAIL,
	PAYMENTS_TO_APPROVE,
	PAYMENTS_TO_APPROVE_DETAIL,
	NO_ROLES,
} from "./config/routes/paths";
import PrivateRoute from "./components/router/PrivateRoute";
import PublicRoute from "./components/router/PublicRoute";
import Payments from "./pages/Payments";
import NoRoles from "./pages/NoRoles";
import Categories from "./pages/Categories";
import PaymentsDetail from "./pages/PaymentDetail";
import MyPayments from "./pages/MyPayments";
import PaymentApproval from "./pages/PaymentApproval";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={LOGIN} element={<PublicRoute />}>
					<Route index path={LOGIN} element={<Login />}></Route>
				</Route>
				<Route path={INDEX} element={<PrivateRoute />}>
					<Route index path={INDEX} element={<Payments />}></Route>
					<Route
						exact
						path={NO_ROLES}
						element={<NoRoles />}
					></Route>
					<Route
						exact
						path={MY_PAYMENTS}
						element={<MyPayments />}
					></Route>
					<Route
						exact
						path={MY_PAYMENTS_DETAIL}
						element={<PaymentsDetail />}
					></Route>
					<Route exact path={PAYMENTS} element={<Payments />}></Route>
					<Route
						path={PAYMENTS_DETAIL}
						element={<PaymentsDetail />}
					></Route>
					<Route
						exact
						path={PAYMENTS_TO_APPROVE}
						element={<PaymentApproval />}
					></Route>
					<Route
						path={PAYMENTS_TO_APPROVE_DETAIL}
						element={<PaymentsDetail />}
					></Route>
					<Route
						exact
						path={CATEGORIES}
						element={<Categories />}
					></Route>
					<Route exact path={USERS} element={<Users />}></Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
