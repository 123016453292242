import React from "react";
import { useAuthContext } from "../contexts/AuthContext";
import userImage from "../assets/images/user-image.jpeg";
import { isType } from "../utils/compare";
import { capitalize } from "../utils/format";

function Topbar() {
	const { userData } = useAuthContext();
	var role;
	var roles = [];

	if (isType(userData.appRoles, "array")) {
		if (userData.appRoles.length > 0) {
			if (userData.appRoles.length > 1) {
				role = capitalize(userData.appRoles[0].rol_description) + "...";
				for (let i = 0; i < userData.appRoles.length; i++) {
					roles = [
						...roles,
						capitalize(userData.appRoles[i].rol_description),
					];
				}
			} else {
				role = capitalize(userData.appRoles[0].rol_description);
			}
		} else {
			role = "No tiene roles";
		}
	} else {
		role = "No tiene roles";
	}

	return (
		<div className="topbar">
			<div className="topbar-user">
				<div className="topbar-user-container">
					<div className="topbar-user-info">
						<span
							className="font-semibold text-end"
							title={userData.name}
						>
							{userData.name}
						</span>
						<span className="text-end" title={roles.join(", ")}>
							{role}
						</span>
					</div>
					<div
						className="topbar-user-image"
						title="Humpty Alexander Dumpty"
					>
						<img
							src={userImage}
							alt={userData.name}
							title={userData.name}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Topbar;
