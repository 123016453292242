const DAYS = [
	"Domingo",
	"Lunes",
	"Martes",
	"Miércoles",
	"Jueves",
	"Viernes",
	"Sábado",
];

const MONTHS = [
	"Enero",
	"Febrero",
	"Marzo",
	"Abril",
	"Mayo",
	"Junio",
	"Julio",
	"Agosto",
	"Septiembre",
	"Octubre",
	"Noviembre",
	"Diciembre",
];

/*export function formatDate(givenDate, formatTo) {
	const validate = isUTC(givenDate);

	if (validate === true) {
		const formattedDate = formatDateUTC(givenDate);
	}
}*/

/**
 * Returns the UTC date with different date structures
 *
 * shortTime24: hh:mm
 * longTime24: hh:mm:ss
 *
 * shortDate: dd/mm/yyyy
 * shortDateTime: dd/mm/yyyy hh:mm:ss
 *
 * longDate: day, month dd, yyyy
 * longDateTime24: day, month dd, yyyy hh:mm:ss
 *
 * UTC
 *
 * @param {string} givenDate The UTC date to be formated
 * @returns {Array} Returns an array of date formats
 */
export function formatDateUTC(givenDate = new Date()) {
	var dateArray = givenDate.substring(0, givenDate.indexOf("T")).split("-");
	var timeArray = givenDate
		.substring(givenDate.indexOf("T") + 1, givenDate.indexOf("."))
		.split(":");

	const date = {
		DAYS,
		MONTHS,
		BASE_DATE: {
			year: dateArray[0],
			month: dateArray[1],
			day: dateArray[2],
			hour: timeArray[0],
			minute: timeArray[1],
			second: timeArray[2],
		},
	};

	date.shortTime24 = function () {
		const formattedDate = `${this.BASE_DATE.hour}:${this.BASE_DATE.minute}`;
		return formattedDate;
	};

	date.longTime24 = function () {
		const formattedDate = `${this.BASE_DATE.hour}:${this.BASE_DATE.minute}:${this.BASE_DATE.second}`;
		return formattedDate;
	};

	date.shortDate = function () {
		const formattedDate = `${this.BASE_DATE.day}/${this.BASE_DATE.month}/${this.BASE_DATE.year}`;
		return formattedDate;
	};

	date.shortDateTime24 = function () {
		const formattedDate = `${this.BASE_DATE.day}/${this.BASE_DATE.month}/${this.BASE_DATE.year} ${this.BASE_DATE.hour}:${this.BASE_DATE.minute}:${this.BASE_DATE.second}`;
		return formattedDate;
	};

	date.longDate = function () {
		const date = new Date(
			`${this.BASE_DATE.year}/${this.BASE_DATE.month}/${this.BASE_DATE.day}`
		);
		const day = DAYS[date.getDay()];
		const month = MONTHS[this.BASE_DATE.month - 1];
		const formattedDate = `${day}, ${month} ${this.BASE_DATE.day}, ${this.BASE_DATE.year}`;
		return formattedDate;
	};

	date.longDateTime24 = function () {
		const date = new Date(
			`${this.BASE_DATE.year}/${this.BASE_DATE.month}/${this.BASE_DATE.day}`
		);
		const day = DAYS[date.getDay()];
		const month = MONTHS[this.BASE_DATE.month - 1];
		const formattedDate = `${day}, ${month} ${this.BASE_DATE.day}, ${this.BASE_DATE.year} ${this.BASE_DATE.hour}:${this.BASE_DATE.minute}:${this.BASE_DATE.second}`;
		return formattedDate;
	};

	date.UTC = function () {
		const formattedDate = `${this.BASE_DATE.year}-${this.BASE_DATE.month}-${this.BASE_DATE.day}T${this.BASE_DATE.hour}:${this.BASE_DATE.minute}:${this.BASE_DATE.second}Z`;
		return formattedDate;
	};

	return date;
}

/*function isUTC(givenDate) {
	if (givenDate.length >= 19 && givenDate.length <= 24) {
		return true;
	} else {
		return false;
	}
}*/
