import React, { useEffect } from "react";
import returnPaginationRange from "../utils/returnPaginationRange";
import { ChevronIcon } from "../assets/svg/icons";

function Pagination({
	totalPages,
	page,
	setPage,
	limit,
	siblings,
	canNextPage,
	setCanNextPage,
	canPreviousPage,
	setCanPreviousPage,
}) {
	const gotoPage = (page) => {
		if (page === "... ") {
			setPage(page - 1);
		} else if (page === " ...") {
			setPage(page + 1);
		} else {
			setPage(page);
		}

		if (page === 1) {
			setCanPreviousPage(false);
		} else {
			setCanPreviousPage(true);
		}

		if (page === totalPages) {
			setCanNextPage(false);
		} else {
			setCanNextPage(true);
		}
	};

	const previousPage = () => {
		if (canPreviousPage === true) {
			if (page - 1 === 1) {
				setPage(page - 1);
				setCanPreviousPage(false);
			} else {
				setPage(page - 1);
				setCanPreviousPage(true);
			}
		}

		setCanNextPage(true);
	};

	const nextPage = () => {
		if (canNextPage === true) {
			if (page + 1 === totalPages) {
				setPage(page + 1);
				setCanNextPage(false);
			} else {
				setPage(page + 1);
				setCanNextPage(true);
			}
		}

		setCanPreviousPage(true);
	};

	let pagesArray = returnPaginationRange(totalPages, page, limit, siblings);

	useEffect(() => {
		if (pagesArray.length > 0 && page <= 0) {
			setPage(1);
		}
	}, [pagesArray, page, setPage]);

	return (
		<ul className="pagination flex gap-1">
			<li
				title="Primero"
				className={`page first ${
					canPreviousPage === false ? "disabled " : ""
				}flex items-center justify-center rounded-md`}
				onClick={() => gotoPage(1)}
			>
				<ChevronIcon className={"-rotate-90"} />
				<ChevronIcon className={"-rotate-90"} />
			</li>
			<li
				key={0}
				title="Anterior"
				className={`page ${
					canPreviousPage === false ? "disabled " : ""
				}flex items-center justify-center rounded-md`}
				onClick={() => previousPage()}
			>
				<ChevronIcon className={"-rotate-90"} />
			</li>
			{pagesArray.map((value) => (
				<li
					key={value}
					id={value}
					title={value}
					className={`page ${
						value === page ? "active " : ""
					} flex items-center justify-center rounded-md`}
					onClick={() => gotoPage(value)}
				>
					{value}
				</li>
			))}
			<li
				key={pagesArray.length}
				title="Siguiente"
				className={`page ${
					canNextPage === false ? "disabled " : ""
				}flex items-center justify-center rounded-md`}
				onClick={() => nextPage()}
			>
				<ChevronIcon className={"rotate-90"} />
			</li>
			<li
				id={pagesArray.length}
				title="Último"
				className={`page last ${
					canNextPage === false ? "disabled " : ""
				}flex items-center justify-center rounded-md`}
				onClick={() => gotoPage(pagesArray[pagesArray.length - 1])}
			>
				<ChevronIcon className={"rotate-90"} />
				<ChevronIcon className={"rotate-90"} />
			</li>
		</ul>
	);
}

export default Pagination;
