import React, { useEffect, useRef, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import Sidebar from "../layouts/Sidebar";
import Topbar from "../layouts/Topbar";
import Modal from "../hooks/useModal";
import PaymentList from "../features/payments/components/PaymentList";
import Select from "../components/Select";
import { capitalize, prepareObjectArray } from "../utils/format";
import delay from "../utils/delay";
import OffCanvas from "../components/OffCanvas";
import {
	ClipboardIcon,
	QuestionMarkIcon,
	TrashFilledIcon,
} from "../assets/svg/icons";
// Requests
import getCategories from "../features/categories/services/getCategories";
import getPayments from "../features/payments/services/getPayments";
import getPayment from "../features/payments/services/getPayment";
import getBanks from "../services/getBanks";
import getDocumentTypes from "../services/getDocumentTypes";
import { useAuthContext } from "../contexts/AuthContext";
import createMultiplePayments from "../features/payments/services/createMultiplePayments";
import createIndividualPayment from "../features/payments/services/createIndividualPayment";
import unifyPayments from "../features/payments/services/unifyPayments";
import getPaystates from "../features/payments/services/getPaystates";
import getUsers from "../features/users/services/getUsers";
import generatePaymentsReport from "../features/payments/services/generatePaymentsReport";
import { inArray, isNumeric } from "../utils/compare";
import { useNavigate } from "react-router-dom";

const emptyFilters = {
	sort: true,
	paystate: "Todos",
	paytype: "Todos",
	banks: ["Todos"],
	category: "Todos",
	createdBy: "Todos",
};

function Payments() {
	const selectRef = useRef();
	const fileRef = useRef();
	const formRef = useRef();
	const startDateRef = useRef();
	const endDateRef = useRef();
	const startDueDateRef = useRef();
	const endDueDateRef = useRef();

	const navigate = useNavigate();
	const { userData } = useAuthContext();

	if (userData && userData.appRoles.length <= 0) {
		navigate(`/no-roles`);
	}

	var appRoles = [];
	for (let i = 0; i < userData.appRoles.length; i++) {
		appRoles = [...appRoles, userData.appRoles[i].rol_description];
	}

	const [dragNDrop, setDragNDrop] = useState();
	const [modal, setModal] = useState({ show: false });
	const [questionMark, setQuestionMark] = useState({
		question: "",
		show: false,
	});
	const [isOpen, setIsOpen] = useState(false);

	const [selectedFilters, setSelectedFilters] = useState(
		sessionStorage.getItem("paymentsFilters")
			? JSON.parse(sessionStorage.getItem("paymentsFilters"))
			: sessionStorage.setItem(
					"paymentsFilters",
					JSON.stringify(emptyFilters)
			  )
	);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState();

	const [uploadFiles, setUploadFiles] = useState();
	const [items, setItems] = useState();
	const [users, setUsers] = useState();
	const [banks, setBanks] = useState();
	const [paystates, setPaystates] = useState();
	const [documentTypes, setDocumentTypes] = useState();
	const [categories, setCategories] = useState();

	const [status, setStatus] = useState(false);
	const [roles, setRoles] = useState();
	const [selectedItem, setSelectedItem] = useState();

	const [paymentData, setPaymentData] = useState({});

	const handleResetFilters = () => {
		if (isOpen) {
			startDateRef.current.value = "";
			endDateRef.current.value = "";
			startDueDateRef.current.value = "";
			endDueDateRef.current.value = "";
		}
		setSelectedFilters(emptyFilters);
		setFilteredData(items);
		sessionStorage.setItem("paymentsFilters", JSON.stringify(emptyFilters));
	};

	const handleSettedFilters = (providedItems) => {
		providedItems = providedItems ?? items;

		if (providedItems) {
			let itemsToFilter = [...providedItems];
			let actualFilters = JSON.parse(
				sessionStorage.getItem("paymentsFilters")
			);

			setFilteredData();
			for (var key of Object.getOwnPropertyNames(selectedFilters)) {
				if (key !== "undefined") {
					switch (key) {
						case "sort": {
							if (actualFilters.sort === true) {
								// Descending
								itemsToFilter.sort(
									(a, b) =>
										new Date(b.created) -
										new Date(a.created)
								);
								//setFilteredData(itemsToFilter);
							} else {
								//Ascending
								itemsToFilter.sort(
									(a, b) =>
										new Date(a.created) -
										new Date(b.created)
								);
							}
							break;
						}

						case "paystate": {
							if (
								actualFilters.paystate &&
								actualFilters.paystate !== "Todos"
							) {
								itemsToFilter = itemsToFilter.filter(
									(filter) =>
										filter.paystate.id ===
										actualFilters.paystate
								);
							}
							break;
						}

						case "paytype": {
							if (
								actualFilters.paytype &&
								actualFilters.paytype !== "Todos"
							) {
								itemsToFilter = itemsToFilter.filter(
									(filter) =>
										filter.pay_type ===
										actualFilters.paytype
								);
							}

							break;
						}

						case "category": {
							if (
								actualFilters.category &&
								actualFilters.category !== "Todos"
							) {
								itemsToFilter = itemsToFilter.filter(
									(filter) =>
										filter.category.id ===
										actualFilters.category
								);
							}

							break;
						}

						case "createdBy": {
							if (
								actualFilters.createdBy &&
								actualFilters.createdBy !== "Todos"
							) {
								itemsToFilter = itemsToFilter.filter(
									(filter) =>
										filter.user.id ===
										actualFilters.createdBy
								);
							}

							break;
						}

						case "banks": {
							if (actualFilters.paytype === "INDIVIDUAL") {
								if (
									actualFilters.banks &&
									!inArray(actualFilters.banks, "Todos")
								) {
									itemsToFilter = itemsToFilter.filter(
										(item) =>
											actualFilters.banks.includes(
												item.bank_id
											)
									);
								}
							}

							break;
						}

						case "startDate": {
							if (actualFilters.startDate) {
								if (actualFilters.endDate) {
									itemsToFilter = itemsToFilter.filter(
										(item) => {
											let itemDate =
												item.created.split("T")[0];

											return (
												itemDate >=
													actualFilters.startDate &&
												itemDate <=
													actualFilters.endDate
											);
										}
									);
								} else {
									itemsToFilter = itemsToFilter.filter(
										(item) => {
											let itemDate =
												item.created.split("T")[0];

											return (
												itemDate >=
												actualFilters.startDate
											);
										}
									);
								}
							}

							break;
						}

						case "startDueDate": {
							if (actualFilters.startDueDate) {
								if (actualFilters.endDueDate) {
									itemsToFilter = itemsToFilter.filter(
										(item) => {
											let itemDate =
												item.created.split("T")[0];

											return (
												itemDate >=
													actualFilters.startDueDate &&
												itemDate <=
													actualFilters.endDueDate
											);
										}
									);
								} else {
									itemsToFilter = itemsToFilter.filter(
										(item) => {
											let itemDate =
												item.created.split("T")[0];

											return (
												itemDate >=
												actualFilters.startDueDate
											);
										}
									);
								}
							}

							break;
						}

						default:
							break;
					}
					//console.log("original", items, itemsToFilter);
				}
			}

			//await delay(500);
			setFilteredData(itemsToFilter.length > 0 ? itemsToFilter : [""]);
		}
	};

	useEffect(() => {
		handleSettedFilters();
	}, [selectedFilters]);

	useEffect(() => {
		handleSettedFilters();
	}, []);

	const searchForRole = (roleToSearch) => {
		let isThere = appRoles.findIndex(
			(role) => role.toLowerCase() === roleToSearch.toLowerCase()
		);
		return isThere > -1 ? true : false;
	};

	const refreshUsers = async () => {
		const { users } = await getUsers();
		let preparedArray = prepareObjectArray(users);
		setUsers(preparedArray);
	};

	const refreshPaystates = async () => {
		let { paystates } = await getPaystates();
		let preparedArray = prepareObjectArray(paystates);
		setPaystates(preparedArray);
	};

	const refreshBanks = async () => {
		let { banks } = await getBanks();
		let preparedArray = prepareObjectArray(banks);
		setBanks(preparedArray);
	};

	const refreshDocumentTypes = async () => {
		let { documenttypes } = await getDocumentTypes();
		setDocumentTypes(documenttypes);
	};

	const refreshCategories = async () => {
		let categories = await getCategories();
		for (let i = 0; i < categories.length; i++) {
			delete categories[i].cat_approval_level;
			delete categories[i].users;
		}
		categories = prepareObjectArray(categories);
		setCategories(categories);
	};

	const refreshPayments = async () => {
		let actualSortOrder = JSON.parse(
			sessionStorage.getItem("paymentsFilters")
		);

		let payments = await getPayments();

		if (Object.keys(selectedFilters).length > 1) {
			handleSettedFilters(payments);
		} else {
			if (actualSortOrder.sort === true) {
				// Descending
				payments.sort(
					(a, b) => new Date(b.created) - new Date(a.created)
				);
			} else {
				//Ascending
				payments.sort(
					(a, b) => new Date(a.created) - new Date(b.created)
				);
			}
		}

		setItems(payments);
	};

	useEffect(() => {
		refreshPayments();
		refreshCategories();
		refreshBanks();
		refreshDocumentTypes();
		refreshPaystates();
		refreshUsers();

		const interval = setInterval(() => {
			refreshPayments();
			refreshCategories();
			refreshBanks();
			refreshDocumentTypes();
			refreshPaystates();
			refreshUsers();
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	/**
	 * Handle the selected values of the filters inputs
	 *
	 * Validate if the filter it's already selected.
	 * Add or update the filter
	 *
	 * @param {string} id The filter's identifier
	 * @param {string} value The filter's value
	 */
	const handleSelectedFilters = (id, value) => {
		if (id === "banks") {
			let formatedBanks;

			if (value === "Todos") {
				formatedBanks = ["Todos"];
			} else {
				if (selectedFilters?.banks) {
					if (selectedFilters.banks.indexOf(value) > -1) {
						if (selectedFilters.banks.length > 1) {
							formatedBanks = selectedFilters.banks.filter(
								(bank) => bank !== value && bank !== "Todos"
							);
						} else {
							formatedBanks = ["Todos"];
						}
					} else {
						formatedBanks = [...selectedFilters.banks, value];
						formatedBanks = formatedBanks.filter(
							(bank) => bank !== "Todos"
						);
					}
				} else {
					formatedBanks = [value];
				}
			}

			setSelectedFilters({
				...selectedFilters,
				banks: formatedBanks,
			});
			sessionStorage.setItem(
				"paymentsFilters",
				JSON.stringify({ ...selectedFilters, banks: formatedBanks })
			);
			return;
		}

		setSelectedFilters({ ...selectedFilters, [id]: value });
		sessionStorage.setItem(
			"paymentsFilters",
			JSON.stringify({ ...selectedFilters, [id]: value })
		);
	};

	const handleSelectValue = (id) => {
		let value = [];

		let originItem;
		if (selectedFilters[id]) {
			switch (id) {
				case "paystate": {
					if (paystates) {
						if (selectedFilters[id] === "Todos") {
							originItem = [{ value: 0, label: "Todos" }];
						} else {
							originItem = paystates.filter((paystate) => {
								return paystate.value === selectedFilters[id];
							});
						}
						return originItem;
					}
					break;
				}

				case "paytype": {
					switch (selectedFilters[id]) {
						case "REMESA": {
							originItem = [{ value: 1, label: "REMESA" }];
							break;
						}

						case "INDIVIDUAL": {
							originItem = [{ value: 2, label: "INDIVIDUAL" }];
							break;
						}

						default:
						case "TODO": {
							originItem = [{ value: 0, label: "Todos" }];
							break;
						}
					}
					return originItem;
					break;
				}

				case "category": {
					if (categories) {
						if (selectedFilters[id] === "Todos") {
							originItem = [{ value: 0, label: "Todos" }];
						} else {
							originItem = categories.filter((category) => {
								return category.value === selectedFilters[id];
							});
						}
						return originItem;
					}
					break;
				}

				case "createdBy": {
					if (users) {
						if (selectedFilters[id] === "Todos") {
							originItem = [{ value: 0, label: "Todos" }];
						} else {
							originItem = users.filter((category) => {
								return category.value === selectedFilters[id];
							});
						}
						return originItem;
					}
					break;
				}

				case "bank": {
					if (banks) {
						if (selectedFilters[id] === "Todos") {
							originItem = "Todos";
						} else {
							originItem = selectedFilters[id];
						}
						return originItem;
					}
					break;
				}

				case "banks": {
					originItem = [];

					if (banks) {
						if (selectedFilters[id][0] === "Todos") {
							originItem = [{ value: "Todos", label: "Todos" }];
						} else {
							banks.map((bank) => {
								return selectedFilters.banks.filter(
									(filterBank) => {
										if (bank.value === filterBank) {
											originItem.push(bank);
										}
									}
								);
							});
						}

						return originItem;
					}
					break;
				}

				case "startDate": {
					console.log(selectedFilters.startDate);
					break;
				}

				case "endDate": {
					console.log(selectedFilters.endDate);
					break;
				}

				default:
					break;
			}
		}

		return value;
	};

	/**
	 * Handle the changes of the payment form
	 *
	 * @param {string} id The identificator fo the payment object
	 * @param {any} value The value to set
	 */
	const handlePaymentForm = async (id, value, idx = 0) => {
		switch (id) {
			case "category_id": {
				setPaymentData({
					...paymentData,
					[id]: value[0].value,
				});
				break;
			}

			case "pay_lis_holder_name":
			case "pay_lis_account_type":
			case "pay_lis_holder_mail":
			case "pay_lis_holder_document_number":
			case "general_concept":
			case "pay_description": {
				setPaymentData({
					...paymentData,
					[id]: value,
				});
				break;
			}

			case "bank_id":
			case "document_type_id":
			case "pay_lis_account_number":
			case "pay_lis_payment_amount":
			case "pay_lis_holder_immovable": {
				if (value !== "") {
					if (!isNaN(value)) {
						setPaymentData({
							...paymentData,
							[id]: Number(value),
						});
					}
				} else {
					setPaymentData({
						...paymentData,
						[id]: "",
					});
				}
				break;
			}

			default:
				//setPaymentData({ ...paymentData, [id]: value });
				break;
		}
	};

	const handleDisabled = () => {
		switch (modal.modal) {
			case "remesa": {
				if (
					status === true ||
					paymentData?.pay_description === "" ||
					paymentData?.general_concept === "" ||
					paymentData?.category_id === undefined ||
					!uploadFiles
				) {
					return true;
				}
				break;
			}

			case "individual": {
				if (
					status === true ||
					paymentData?.pay_description === "" ||
					paymentData?.category_id === undefined ||
					paymentData?.pay_lis_holder_name === "" ||
					paymentData?.pay_lis_holder_document_number === "" ||
					paymentData?.pay_lis_holder_mail === "" ||
					!paymentData?.pay_lis_holder_mail ||
					paymentData?.pay_lis_holder_document_number?.length < 5 ||
					paymentData?.pay_lis_holder_document_number?.length > 20 ||
					paymentData?.document_type_id === undefined ||
					paymentData?.bank_id === undefined ||
					paymentData?.pay_lis_account_number === undefined ||
					paymentData?.pay_lis_account_type === undefined ||
					paymentData?.pay_lis_payment_amount === undefined ||
					paymentData?.pay_lis_account_number === "" ||
					paymentData?.pay_lis_account_type === "" ||
					paymentData?.pay_lis_payment_amount === ""
				) {
					return true;
				}
				break;
			}

			case "Unificar": {
				if (
					status === true ||
					!paymentData.pay_description ||
					paymentData?.pay_description === ""
				) {
					return true;
				}
				break;
			}

			default:
				return false;
				break;
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setStatus(true);
		let response;
		const toastId = toast.loading("Actualizando...");
		const fields = Object.fromEntries(new window.FormData(e.target));
		fields.category_id = paymentData.category_id;

		if (modal.modal === "individual") {
			let finalObject = {};
			/*fields.bank_id = Number(fields.bank_id);
			fields.document_type_id = Number(fields.document_type_id);
			fields.pay_lis_account_number = Number(
				fields.pay_lis_account_number
			);
			fields.pay_lis_payment_amount = Number(
				fields.pay_lis_payment_amount
			);
			fields.pay_lis_ledger_account = Number(
				fields.pay_lis_ledger_account
			);
			fields.pay_lis_origin_money_nit = Number(
				fields.pay_lis_origin_money_nit
			);
			fields.user_id = userData.appId;
			paymentData.user_id = userData.appId;*/

			for (const value in fields) {
				if (
					Object.prototype.hasOwnProperty.call(fields, value) &&
					fields[value] !== ""
				) {
					const currentValue = fields[value];

					if (currentValue) {
						if (isNumeric(currentValue)) {
							finalObject[value] = Number(currentValue);
						} else {
							finalObject[value] = currentValue;
						}
					}
				}
			}

			finalObject.pay_lis_account_number =
				finalObject.pay_lis_account_number.toString();

			finalObject.user_id = userData.appId;

			response = await createIndividualPayment(finalObject);
		} else {
			let formData = new FormData();

			formData.append("file", uploadFiles, uploadFiles.name);
			formData.append("pay_description", paymentData.pay_description);
			formData.append("general_concept", paymentData.general_concept);
			formData.append("category_id", paymentData.category_id);
			formData.append("user_id", userData.appId);

			response = await createMultiplePayments(formData);
		}

		switch (response) {
			case "USER_NOT_FOUND":
				toast.error("No se encontró el usuario", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "ERROR_CREATING_MULTIPLE_PAYMENTS":
				toast.error("Occurió un error al crear el pago múltiple", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "ERROR_CREATING_INDIVIDUAL_PAYMENT":
				toast.error("Occurió un error al crear el pago individual", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_CREATED_SUCCESSFULLY":
				toast.success("Pago creado exitosamente", {
					id: toastId,
					style: {
						border: "1px solid #62D346",
					},
				});
				break;

			default:
				if (response.indexOf("{") > -1) {
					toast.error(response, {
						id: toastId,
						style: {
							border: "1px solid #FF4C4C",
						},
					});
				} else {
					toast.error(
						`Ocurrió un error al crear ${
							modal.modal === "individual"
								? "el pago individual"
								: "la remesa de pago"
						}`,
						{
							id: toastId,
							style: {
								border: "1px solid #FF4C4C",
							},
						}
					);
				}
				break;
		}

		if (response.indexOf("{") < 0) {
			setModal({ modal: "", show: false });
			setPaymentData({});
			setSelectedCategories({});
			setUploadFiles();
			setStatus(false);
		}
		refreshPayments();
		await delay(10000);
		toast.dismiss(toastId);
		setStatus(false);
	};

	const handleUnify = async () => {
		setModal({ modal: "", show: false });
		setStatus(true);
		let response;
		const toastId = toast.loading("Actualizando...");

		let categories = selectedItem.map((pay) => {
			return pay.id;
		});

		let data = {
			category_id: selectedItem[0].category,
			pay_description: paymentData.pay_description,
			payments: categories.flat(),
			user_id: userData.appId,
		};

		response = await unifyPayments(data);

		if (response?.message) {
			var responseId = response.unified_payment;
			response = response.message;
		}

		switch (response) {
			case "USER_NOT_FOUND":
				toast.error("No se encontró el usuario", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "CATEGORY_NOT_EXISTS":
				toast.error("No se encontró la categoría", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "INVALID_PAYMENTS_LENGTH":
				toast.error("No hay suficientes pagos seleccionados", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYMENT_CATEGORIES_ARE_NOT_EQUAL":
				toast.error("Las categorías de los pagos no son iguales", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "PAYLIST_NOT_EXISTS":
				toast.error("No existe la lista de pagos", {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;

			case "Payments have been successfully unified":
				toast.success(
					`Pagos unificados exitosamente en el pago #${handleReference(
						responseId
					)}`,
					{
						id: toastId,
						style: {
							border: "1px solid #62D346",
						},
					}
				);
				break;

			case "ERROR_UNIFY_PAYMENTS":
			default: {
				toast.error(`Ocurrió un error al unificar los pagos`, {
					id: toastId,
					style: {
						border: "1px solid #FF4C4C",
					},
				});
				break;
			}
		}

		setPaymentData({});
		setSelectedCategories({});
		setUploadFiles();
		refreshPayments();
		await delay(5000);
		toast.dismiss(toastId);
		setStatus(false);
	};

	const handleUploadFiles = async (e) => {
		if (e.target.files.length > 0) {
			let droppedFile = e.target.files[0];
			let ext = droppedFile.type.split("/")[1];

			if (ext === "csv") {
				toast.success("Archivo cargado exitosamente", {
					position: "center-top",
					style: {
						border: "1px solid #62D346",
					},
				});
				setUploadFiles(droppedFile);
			} else {
				toast((t) => (
					<span className="flex items-center gap-1">
						<span>
							El archivo tipo <b>'{ext.toUpperCase()}'</b> cargado
							no coincide con el formato requerido <b>'CSV'.</b>
						</span>
						<button
							className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
							onClick={() => toast.dismiss(t.id)}
						>
							Cerrar
						</button>
					</span>
				));
			}
		} else {
			toast((t) => (
				<span className="flex items-center gap-1">
					<span>Inserción no válida.</span>
					<button
						className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
						onClick={() => toast.dismiss(t.id)}
					>
						Cerrar
					</button>
				</span>
			));
		}
	};

	const handleDragNDropOver = (e) => {
		e.preventDefault();
		setDragNDrop(true);
	};

	const handleDrop = (e) => {
		e.preventDefault();

		if (e.dataTransfer.files.length > 0) {
			let droppedFile = e.dataTransfer.files[0];
			let ext = droppedFile.type.split("/")[1];

			if (ext === "csv") {
				toast.success("Archivo cargado exitosamente", {
					position: "center-top",
					style: {
						border: "1px solid #62D346",
					},
				});
				setUploadFiles(droppedFile);
			} else {
				toast((t) => (
					<span className="flex items-center gap-1">
						<span>
							El archivo tipo <b>'{ext.toUpperCase()}'</b> cargado
							no coincide con el formato requerido <b>'CSV'.</b>
						</span>
						<button
							className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
							onClick={() => toast.dismiss(t.id)}
						>
							Cerrar
						</button>
					</span>
				));
			}
		} else {
			toast((t) => (
				<span className="flex items-center gap-1">
					<span>Inserción no válida.</span>
					<button
						className="ml-2 rounded border bg-gray-100 px-2 py-1 text-gray-900"
						onClick={() => toast.dismiss(t.id)}
					>
						Cerrar
					</button>
				</span>
			));
		}

		handleDragNDropOut();
	};

	const handleDragNDropOut = () => {
		setDragNDrop(false);
	};

	const handleUploadedFile = () => {
		if (!uploadFiles) {
			return (
				<div
					dropping={dragNDrop === true ? "true" : "false"}
					onDragOver={handleDragNDropOver}
					onDragLeave={handleDragNDropOut}
					onDrop={handleDrop}
					className="droparea mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/40 px-6 py-10"
				>
					<div className="text-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="mx-auto h-12 w-12 text-gray-300"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
							/>
						</svg>
						<div className="mt-4 flex text-sm leading-6 text-gray-600">
							<label
								forhtml="file-upload"
								className="relative cursor-pointer rounded-md font-semibold"
							>
								<span
									className="text-blue-500 hover:text-blue-600"
									title="Cargar archivo"
								>
									Carga un archivo
								</span>
								<input
									ref={fileRef}
									id="file-upload"
									name="file-upload"
									type="file"
									className="sr-only"
									accept=".csv"
									onChange={(e) => handleUploadFiles(e)}
								/>
							</label>
							<p className="pl-1">o arrastra y suelta</p>
						</div>
						<p className="text-xs leading-5 text-gray-600">
							Únicamente archivos CSV
						</p>
					</div>
				</div>
			);
		} else {
			return (
				<div className="flex h-14 flex-row rounded-md bg-gray-200">
					<div className="flex w-16 items-center justify-center">
						<svg
							className="h-8"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 20 20"
						>
							<path
								fill="#fff"
								d="M18 7h-2.5c-.827 0-1.5-.673-1.5-1.5V2H6v16h12V7z"
							/>
							<path
								fill="#fff"
								d="M18 6v-.293l-3-3V5.5c0 .275.225.5.5.5H18z"
							/>
							<path
								fill="#605E5C"
								fillRule="evenodd"
								d="M18.707 5 15 1.293A1 1 0 0 0 14.293 1H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5.707A1 1 0 0 0 18.707 5zM18 5.707V6h-2.5a.501.501 0 0 1-.5-.5V2.707l3 3zM6 2.2v15.6c0 .11.09.2.2.2h11.6a.2.2 0 0 0 .2-.2V7h-2.5A1.5 1.5 0 0 1 14 5.5V2H6.2a.2.2 0 0 0-.2.2z"
								clipRule="evenodd"
								opacity=".64"
							/>
							<path
								fill="#134A2C"
								fillRule="evenodd"
								d="M12.5 12h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"
								clipRule="evenodd"
							/>
							<path
								fill="#21A366"
								fillRule="evenodd"
								d="M12.5 10h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"
								clipRule="evenodd"
							/>
							<path
								fill="#33C481"
								d="M16.5 8h-1c-.275 0-.5.225-.5.5s.225.5.5.5h1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5z"
							/>
							<path
								fill="#21A366"
								d="M13.5 8h-1c-.275 0-.5.225-.5.5s.225.5.5.5h1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5z"
							/>
							<path
								fill="#107C41"
								d="M2 16h8a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1z"
							/>
							<path
								fill="#fff"
								d="m4.606 14 3.707-6H7.15l-3.755 6h1.21z"
							/>
							<path
								fill="#fff"
								d="M7.374 14 3.668 8H4.83l3.754 6h-1.21z"
							/>
						</svg>
					</div>
					<div className="flex w-full justify-between">
						<div className="flex flex-col justify-center">
							<span
								className="max-w-xs overflow-hidden text-ellipsis whitespace-nowrap font-semibold"
								title={uploadFiles.name}
							>
								{uploadFiles.name}
							</span>
							<span className="text-sm">
								{Number(uploadFiles.size) * 0.001 + "KB"}
							</span>
						</div>
						<div
							className="flex h-full w-14 cursor-pointer items-center justify-center rounded-br-md rounded-tr-md fill-gray-500 hover:bg-red-300 hover:fill-red-500"
							title="Eliminar"
							onClick={() => setUploadFiles()}
						>
							<TrashFilledIcon className="h-6 fill-inherit" />
						</div>
					</div>
				</div>
			);
		}
	};

	const handleReference = (id) => {
		let reference = "000000";
		id = id.toString();

		reference = reference.slice(0, reference.length - id.length) + id;
		return reference;
	};

	const handleModalStep = () => {
		switch (modal.modal) {
			case "individual": {
				return (
					<>
						<div className="modal-content-body">
							<form ref={formRef} onSubmit={handleFormSubmit}>
								<div className="mb-2">
									<h2 className="text-base font-semibold leading-7 text-gray-900">
										Pago
									</h2>
									<span className="font-normal text-gray-600">
										Ingresa la información básica del pago
									</span>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Descripción del pago
									</label>
									<div className="mt-2">
										<input
											id="pay_description"
											name="pay_description"
											type="text"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="Ej: Pago comercial"
											autoFocus
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="category_id"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Categoría
									</label>
									<div className="mt-2">
										<Select
											options={categories}
											onChange={(value) => {
												setSelectedCategories(value);
												handlePaymentForm(
													"category_id",
													value
												);
											}}
											value={selectedCategories}
											searchable={false}
											capitalizeValue={true}
											isMulti
											placeholder="Selecciona la categoría"
										/>
									</div>
								</div>
								<hr className="py-2" />
								<div className="mb-2">
									<h2 className="text-base font-semibold leading-7 text-gray-900">
										Titular
									</h2>
									<span className="font-normal text-gray-600">
										Ingresa la información básica del
										titular
									</span>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_holder_name"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Nombre del titular
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_holder_name"
											name="pay_lis_holder_name"
											type="text"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="Ej: Humpty Alexander Dumpty"
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="document_type_id"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Tipo de documento
									</label>
									<div className="mt-2">
										{
											<select
												ref={selectRef}
												id="document_type_id"
												name="document_type_id"
												className="input w-full"
												placeholder=""
												onChange={(e) =>
													handlePaymentForm(
														e.target.id,
														e.target.value
													)
												}
											>
												{!paymentData.document_type_id ? (
													<option key="doc_sel">
														Selecciona...
													</option>
												) : null}
												{documentTypes.map(
													(document) => (
														<option
															key={document.id}
															value={document.id}
														>
															{capitalize(
																document.doc_typ_description
															)}
														</option>
													)
												)}
											</select>
										}
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_holder_document_number"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Número de documento
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_holder_document_number"
											name="pay_lis_holder_document_number"
											type="text"
											className="w-full"
											placeholder="Ej: 1597534682"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_holder_mail"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Email del titular
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_holder_mail"
											name="pay_lis_holder_mail"
											type="email"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="Ej: humpty@gmail.com"
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_holder_immovable"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Inmueble
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_holder_immovable"
											name="pay_lis_holder_immovable"
											type="number"
											className="w-full"
											placeholder="Ej: 75846"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										/>
									</div>
								</div>
								<hr className="py-2" />
								<div className="mb-2">
									<h2 className="text-base font-semibold leading-7 text-gray-900">
										Cuenta
									</h2>
									<span className="font-normal text-gray-600">
										Ingresa la información básica de la
										cuenta
									</span>
								</div>
								<div className="mb-4">
									<label
										htmlFor="bank_id"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Banco
									</label>
									<div className="mt-2">
										<select
											ref={selectRef}
											id="bank_id"
											name="bank_id"
											className="input w-full"
											placeholder="Selecciona el banco"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										>
											{!paymentData.bank_id ? (
												<option key="bank_sel">
													Selecciona...
												</option>
											) : null}
											{banks.map((bank) => (
												<option
													key={bank.value}
													value={bank.value}
												>
													{bank.label}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_account_number"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Número de cuenta
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_account_number"
											name="pay_lis_account_number"
											type="number"
											className="w-full"
											placeholder="Ej: 3432424234"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_account_type"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Tipo de cuenta
									</label>
									<div className="mt-2">
										{
											<select
												ref={selectRef}
												id="pay_lis_account_type"
												name="pay_lis_account_type"
												className="input w-full"
												placeholder="Selecciona el tipo de cuenta"
												onChange={(e) =>
													handlePaymentForm(
														e.target.id,
														e.target.value
													)
												}
											>
												{!paymentData.pay_lis_account_type ? (
													<option key="pay_lis_sel">
														Selecciona...
													</option>
												) : null}
												<option value={"AHORROS"}>
													Ahorros
												</option>
												<option value={"CORRIENTE"}>
													Corriente
												</option>
											</select>
										}
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_payment_amount"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Valor
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_payment_amount"
											name="pay_lis_payment_amount"
											type="number"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="$1250000"
											min={0}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_ledger_account"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Cuenta contable
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_ledger_account"
											name="pay_lis_ledger_account"
											type="number"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="84743"
											min={0}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_lis_origin_money_nit"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										NIT desde donde sale el dinero
									</label>
									<div className="mt-2">
										<input
											id="pay_lis_origin_money_nit"
											name="pay_lis_origin_money_nit"
											type="number"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="4585115"
											min={0}
										/>
									</div>
								</div>
							</form>
						</div>
						<div
							className={`${
								modal.modal === ""
									? "justify-between"
									: "justify-end"
							} modal-content-footer text-sm font-medium leading-6 text-gray-900`}
						>
							{status === true ? (
								""
							) : (
								<button
									className="px-3 py-2 text-sm font-semibold text-gray-900"
									onClick={() => {
										setPaymentData({});
										setSelectedCategories({});
										setUploadFiles();
										setModal({ modal: "", show: false });
									}}
									title="Cancelar"
								>
									Cancelar
								</button>
							)}
							<button
								className="btn primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								title="Cargar remesa de pago"
								onClick={() => formRef.current.requestSubmit()}
								//disabled={handleDisabled()}
								disabled={false}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar
							</button>
						</div>
					</>
				);
				break;
			}

			case "remesa": {
				return (
					<>
						<div className="modal-content-body">
							<form
								ref={formRef}
								onSubmit={(e) => handleFormSubmit(e)}
							>
								<div className="mb-4">
									<h2 className="text-base font-semibold leading-7 text-gray-900">
										Pago
									</h2>
									<span className="font-normal text-gray-600">
										Ingresa la información básica del pago
									</span>
								</div>
								<div className="mb-4">
									<label
										htmlFor="pay_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Descripción del pago
									</label>
									<div className="mt-2">
										<input
											id="pay_description"
											name="pay_description"
											type="text"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="Ej: Pago comercial"
											autoFocus
										/>
									</div>
								</div>
								{/* {<div className="mb-4">
									<label
										htmlFor="pay_description"
										className="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900"
										required
									>
										Concepto general
										<QuestionMarkIcon
											className="h-4 w-4 cursor-pointer hover:fill-black hover:stroke-white"
											onClick={() =>
												setQuestionMark({
													show: !questionMark.show,
												})
											}
										/>
									</label>
									<div className="mt-2">
										<input
											id="general_concept"
											name="general_concept"
											type="text"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="Ej: Pago canon"
											autoFocus
										/>
										{questionMark.show ? (
											<p className="mt-2 text-xs">
												Cuando se carga una remesa de
												pago y en ella se encuentran
												múltiples pagos para un mismo
												cliente, el sistema unificará
												estos pagos bajo un mismo
												concepto en el archivo plano.
												<br />
												Para ello se requiere
												especificar el concepto general
												de estos pagos unificados.
											</p>
										) : null}
									</div>
								</div>} */}
								<div className="mb-4">
									<label
										htmlFor="cat_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Categoría
									</label>
									<div className="mt-2">
										<Select
											options={categories}
											onChange={(value) => {
												setSelectedCategories(value);
												handlePaymentForm(
													"category_id",
													value
												);
											}}
											value={selectedCategories}
											searchable={false}
											capitalizeValue={true}
											isMulti
											placeholder="Selecciona la categoría"
										/>
									</div>
								</div>
								<hr className="py-2" />
								<div className="mb-4">
									<label
										htmlFor="cat_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Archivo
									</label>
									<div className="mt-2">
										{handleUploadedFile()}
									</div>
								</div>
							</form>
						</div>
						<div
							className={`${
								modal.modal === ""
									? "justify-between"
									: "justify-end"
							} modal-content-footer text-sm font-medium leading-6 text-gray-900`}
						>
							{status === true ? (
								""
							) : (
								<button
									className="px-3 py-2 text-sm font-semibold text-gray-900"
									onClick={() => {
										setPaymentData({});
										setSelectedCategories({});
										setUploadFiles();
										setModal({ modal: "", show: false });
									}}
									title="Cancelar"
								>
									Cancelar
								</button>
							)}
							<button
								className="btn primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								title="Cargar remesa de pago"
								onClick={() => formRef.current.requestSubmit()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar
							</button>
						</div>
					</>
				);
				break;
			}

			case "Unificar": {
				return (
					<>
						<div className="modal-content-body">
							<span className="text-gray-700">
								¿Desea unificar los pagos seleccionados?
							</span>
							<form
								ref={formRef}
								onSubmit={(e) => handleFormSubmit(e)}
							>
								<div className="mb-4">
									<label
										htmlFor="pay_description"
										className="block text-sm font-medium leading-6 text-gray-900"
										required
									>
										Descripción del pago
									</label>
									<div className="mt-2">
										<input
											id="pay_description"
											name="pay_description"
											type="text"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="Ej: Pago comercial"
											autoFocus
										/>
									</div>
								</div>
								{/* {<div className="mb-4">
									<label
										htmlFor="pay_description"
										className="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900"
										required
									>
										Concepto general
										<QuestionMarkIcon
											className="h-4 w-4 cursor-pointer hover:fill-black hover:stroke-white"
											onClick={() =>
												setQuestionMark({
													show: !questionMark.show,
												})
											}
										/>
									</label>
									<div className="mt-2">
										<input
											id="general_concept"
											name="general_concept"
											type="text"
											className="w-full"
											onChange={(e) =>
												handlePaymentForm(
													e.target.id,
													e.target.value
												)
											}
											placeholder="Ej: Pago canon"
											autoFocus
										/>
										{questionMark.show ? (
											<p className="mt-2 text-xs">
												Cuando se carga una remesa de
												pago y en ella se encuentran
												múltiples pagos para un mismo
												cliente, el sistema unificará
												estos pagos bajo un mismo
												concepto en el archivo plano.
												<br />
												Para ello se requiere
												especificar el concepto general
												de estos pagos unificados.
											</p>
										) : null}
									</div>
								</div>} */}
								<div className="mt-3">
									<label
										htmlFor="pay_description"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Pagos seleccionados:
									</label>
									<div className="mt-2">
										<ul className="mt-1 list-inside list-disc pl-2 text-sm font-normal text-gray-500">
											{selectedItem.map((pay) => (
												<li key={pay.id}>
													{handleReference(pay.id)}
												</li>
											))}
										</ul>
									</div>
								</div>
							</form>
						</div>
						<div className="modal-content-footer justify-end">
							{status === true ? (
								""
							) : (
								<button
									className="px-3 py-2 text-sm font-semibold text-gray-900"
									onClick={() => {
										setPaymentData({});
										setSelectedCategories({});
										setUploadFiles();
										setModal({ modal: "", show: false });
									}}
									title="Cancelar"
								>
									Cancelar
								</button>
							)}
							<button
								className="flex flex-row items-center gap-2 rounded-md bg-violet-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-400"
								title="Unificar"
								onClick={() => handleUnify()}
								disabled={handleDisabled()}
							>
								{status === true ? (
									<div className="lds-dual-ring button"></div>
								) : (
									"Unificar"
								)}
								<ClipboardIcon className="h-4 w-4" />
							</button>
						</div>
					</>
				);
				break;
			}

			case "":
			default:
				return (
					<>
						<div className="modal-content-body">
							<span className="font-normal text-gray-600">
								Seleccione el método con el que cargará el pago.
							</span>
						</div>
						<div
							className={`${
								modal.modal === ""
									? "justify-between"
									: "justify-end"
							} modal-content-footer text-sm font-medium leading-6 text-gray-900`}
						>
							<button
								className="primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								onClick={() => {
									setModal({
										modal: "individual",
										show: true,
									});
								}}
								title="Cargar pago individual"
								disabled={status === true ? true : false}
							>
								{status === true &&
								modal.modal === "individual" ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar pago individual
							</button>
							<button
								className="primary rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
								title="Cargar remesa de pago"
								onClick={() =>
									setModal({ modal: "remesa", show: true })
								}
								disabled={status === true ? true : false}
							>
								{status === true && modal.modal === "remesa" ? (
									<div className="lds-dual-ring button"></div>
								) : (
									""
								)}
								Cargar remesa de pago
							</button>
						</div>
					</>
				);
				break;
		}
	};

	const download = (file, name) => {
		// Crear un objeto URL del blob
		const url = window.URL.createObjectURL(new Blob([file]));

		// Crear un enlace de descarga
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", name);

		// Hacer clic en el enlace para descargar el archivo
		document.body.appendChild(link);
		link.click();

		// Liberar el objeto URL
		window.URL.revokeObjectURL(url);
	};

	const generateReport = async () => {
		let query = [];

		if (selectedFilters?.paystate && selectedFilters.paystate !== "Todos") {
			query = [...query, `pay_state_id=${selectedFilters.paystate}`];
		}

		if (selectedFilters?.paytype && selectedFilters.paytype !== "Todos") {
			query = [...query, `pay_type=${selectedFilters.paytype}`];
		}

		if (selectedFilters?.category && selectedFilters.category !== "Todos") {
			query = [...query, `category_id=${selectedFilters.category}`];
		}

		if (
			selectedFilters?.createdBy &&
			selectedFilters.createdBy !== "Todos"
		) {
			query = [...query, `user_id=${selectedFilters.createdBy}`];
		}

		const file = await generatePaymentsReport(
			"?" + query.join("&"),
			selectedFilters.paytype === "INDIVIDUAL" &&
				selectedFilters?.banks &&
				selectedFilters.banks[0] !== "Todos"
				? selectedFilters.banks
				: null
		);

		download(file, "REPORTE.xlsx");
	};

	return (
		<>
			<Helmet>
				<title>Pagos</title>
			</Helmet>
			<div className="home">
				<Sidebar />
				<div className="content">
					<Topbar />
					<PaymentList
						items={filteredData ?? items}
						refreshItems={() => refreshPayments()}
						showModal={(value) => setModal(value)}
						setSelectedItem={(value) => {
							setSelectedItem(value);
						}}
						filters={selectedFilters}
						setFilter={handleSelectedFilters}
						resetFilters={() => handleResetFilters()}
						showOffCanvas={() => setIsOpen(true)}
						generateReport={() => generateReport()}
					/>
				</div>
				<Toaster />
			</div>
			<OffCanvas
				onClose={() => setIsOpen(false)}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				staticOffCanvas={false}
				closeOnOutsideClick={false}
			>
				<div className="offcanvas-body h-3/5">
					{selectedFilters?.paystate !== "Todos" ||
					selectedFilters?.category !== "Todos" ||
					selectedFilters?.paytype !== "Todos" ||
					selectedFilters?.createdBy !== "Todos" ||
					selectedFilters?.startDate ||
					selectedFilters?.endDate ? (
						<span
							className="mt-1 flex cursor-pointer justify-end text-sm text-blue-500 underline hover:text-blue-400"
							onClick={() => handleResetFilters()}
						>
							Limpiar filtros
						</span>
					) : (
						<br />
					)}
					<form ref={formRef} onSubmit={handleFormSubmit}>
						<div>
							<span className="text-sm font-medium text-gray-900">
								Fecha de creación
							</span>
							<div className="mt-2 flex justify-between">
								<div className="mb-4">
									<label
										htmlFor="startDate"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Desde
									</label>
									<div>
										<input
											ref={startDateRef}
											type="date"
											id="startDate"
											name="startDate"
											className="w-32 text-gray-800"
											onChange={(e) =>
												handleSelectedFilters(
													"startDate",
													e.currentTarget.value
												)
											}
										/>
									</div>
								</div>
								<div className="mx-2 mt-2 h-px w-full self-center bg-gray-400"></div>
								<div className="mb-4">
									<label
										htmlFor="endDate"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Hasta
									</label>
									<div>
										<input
											ref={endDateRef}
											type="date"
											min={
												selectedFilters.startDate ??
												new Date()
													.toISOString()
													.split("T")[0]
											}
											id="endDate"
											name="endDate"
											className="w-32 text-gray-800"
											onChange={(e) =>
												handleSelectedFilters(
													"endDate",
													e.currentTarget.value
												)
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Estado
							</label>
							<div className="mt-2">
								<Select
									options={
										paystates
											? [
													{
														value: "Todos",
														label: "Todos",
													},
													...paystates,
											  ] ?? paystates
											: paystates
									}
									onChange={(value) =>
										handleSelectedFilters(
											"paystate",
											value[0].value
										)
									}
									value={handleSelectValue("paystate")}
									multiple={false}
									capitalizeValue={true}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Tipo de pago
							</label>
							<div className="mt-2">
								<Select
									options={[
										{
											value: "Todos",
											label: "Todos",
										},
										{
											value: 1,
											label: "REMESA",
										},
										{
											value: 2,
											label: "INDIVIDUAL",
										},
									]}
									onChange={(value) =>
										handleSelectedFilters(
											"paytype",
											value[0].label
										)
									}
									value={handleSelectValue("paytype")}
									multiple={false}
									capitalizeValue={true}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
						<div
							className={`mb-4${
								selectedFilters?.paytype === "INDIVIDUAL"
									? ""
									: " hidden"
							}`}
						>
							<label
								htmlFor="bank"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Banco
							</label>
							<div className="mt-2">
								<Select
									options={
										banks
											? [
													{
														value: "Todos",
														label: "Todos",
													},
													...banks,
											  ] ?? banks
											: banks
									}
									onChange={(value) =>
										handleSelectedFilters(
											"banks",
											value.value
										)
									}
									value={handleSelectValue("banks")}
									multiple={true}
									capitalizeValue={true}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
						<div
							className={`${
								selectedFilters?.paytype === "INDIVIDUAL" &&
								(selectedFilters?.category === 12 ||
									selectedFilters?.category === 13)
									? ""
									: "hidden"
							}`}
						>
							<span className="text-sm font-medium text-gray-900">
								Fecha de vencimiento
							</span>
							<div className="mt-2 flex justify-between">
								<div className="mb-4">
									<label
										htmlFor="startDueDate"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Desde
									</label>
									<div>
										<input
											ref={startDueDateRef}
											type="date"
											max={
												new Date()
													.toISOString()
													.split("T")[0]
											}
											id="startDueDate"
											name="startDueDate"
											className="w-32 text-gray-800"
											onChange={(e) =>
												handleSelectedFilters(
													"startDueDate",
													e.currentTarget.value
												)
											}
										/>
									</div>
								</div>
								<div className="mx-2 mt-2 h-px w-full self-center bg-gray-400"></div>
								<div className="mb-4">
									<label
										htmlFor="endDueDate"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Hasta
									</label>
									<div>
										<input
											ref={endDueDateRef}
											type="date"
											min={
												selectedFilters.startDate ??
												new Date()
													.toISOString()
													.split("T")[0]
											}
											id="endDueDate"
											name="endDueDate"
											className="w-32 text-gray-800"
											onChange={(e) =>
												handleSelectedFilters(
													"endDueDate",
													e.currentTarget.value
												)
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Categoría
							</label>
							<div className="mt-2">
								<Select
									options={
										categories
											? [
													{
														value: "Todos",
														label: "Todos",
													},
													...categories,
											  ] ?? categories
											: categories
									}
									onChange={(value) =>
										handleSelectedFilters(
											"category",
											value[0].value
										)
									}
									value={handleSelectValue("category")}
									multiple={false}
									capitalizeValue={true}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
						<div className="mb-4">
							<label
								htmlFor="pay_description"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Creado por
							</label>
							<div className="mt-2">
								<Select
									options={
										users
											? [
													{
														value: "Todos",
														label: "Todos",
													},
													...users,
											  ] ?? users
											: users
									}
									onChange={(value) =>
										handleSelectedFilters(
											"createdBy",
											value[0].value
										)
									}
									value={handleSelectValue("createdBy")}
									multiple={false}
									clearable={false}
									placeholder="Selecciona el estado"
									className="w-full"
								/>
							</div>
						</div>
					</form>
				</div>
			</OffCanvas>
			<Modal
				onClose={() => {
					setPaymentData({});
					setSelectedCategories({});
					setUploadFiles();
					setModal({ modal: "", show: false });
				}}
				show={modal.show}
				modalTitle={`${
					modal.modal === "Unificar" ? "Unificar pago" : "Cargar pago"
				}`}
			>
				{handleModalStep()}
			</Modal>
		</>
	);
}

export default Payments;
