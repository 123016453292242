import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const updateUserRoles = async (body) => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "put",
		maxBodyLength: Infinity,
		url: "https://apipagoterceros.spagrupoinmobiliario.com/api/roles/updateUserRoles",
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		validateStatus: () => true,
		data: body,
	};

	const { data } = await axios(payLoad);

	if (data.data.message) {
		return data.data.message;
	} else {
		return "ERROR_ADDING_ROLES_TO_USER";
	}
};

export default updateUserRoles;
