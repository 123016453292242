import React from "react";
import { Helmet } from "react-helmet";
import { useAuthContext } from "../contexts/AuthContext";
import UndrawFireworks from "../assets/svg/UndrawFireworks";
import { SPALogo } from "../assets/svg/icons";

function NoRoles() {
	console.log("klk");
	const { logout } = useAuthContext();

	return (
		<>
			<Helmet>
				<title>Aún no tienes roles</title>
			</Helmet>
			<div className="flex flex-col items-center justify-center gap-2 h-screen w-screen">
				<div className="flex justify-between mb-2 w-2/3 sm:w-1/3">
					<h2 className="text-3xl font-bold">Ups...</h2>
					<button
						title="Cerrar sesión"
						className="px-2 text-sm border rounded-md hover:bg-gray-100"
						onClick={() => logout()}
					>
						Cerrar sesión
					</button>
				</div>
				<p className="w-2/3 sm:w-1/3 mb-2">
					Parece que aún <b>no tienes permisos</b> para usar esta
					aplicación.
					<br />
					Ponte en contacto con los administradores para poder hacer
					uso de todas las funcionalidades.
				</p>
				<UndrawFireworks className="h-1/3 w-2/3 sm:w-1/3" />
			</div>
			<SPALogo className="absolute bottom-5 left-5 h-10 w-10" />
		</>
	);
}

export default NoRoles;
