import axios from "axios";
import getAccessToken from "./getAccessToken";

const validateAppUser = async (microsoftId) => {
	const tk = await getAccessToken();

	console.log(tk)

	console.log(microsoftId)

	const payLoad = {
		method: "post",
		maxBodyLength: Infinity,
		url: `https://apipagoterceros.spagrupoinmobiliario.com/api/auth/ckeckAppUser/${microsoftId}`,
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);

	return data;
};

export default validateAppUser;
