import axios from "axios";
import getAccessToken from "../../../services/getAccessToken";

const payOrder = async (body) => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "put",
		maxBodyLength: Infinity,
		url: "https://apipagoterceros.spagrupoinmobiliario.com/api/payments/payOrder",
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		data: body,
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);

	if (data.data.message) {
		return data.data;
	} else {
		return data.error;
	}
};

export default payOrder;
