import range from "./range";

/**
 * Returns the range of the pagination
 *
 * @param {number} totalPages The total of the pages.
 * @param {number} page The pagination current page.
 * @param {number} limit The setted limit for the rows to show.
 * @param {number} siblings The side pages to show.
 * @returns {Array} Returns the range of pages.
 */
function returnPaginationRange(totalPages, page, limit, siblings) {
	let totalPageNoInArray = 7 + siblings;

	if (totalPageNoInArray >= totalPages) {
		return range(1, totalPages + 1);
	}

	let leftSiblingsIndex = Math.max(page - siblings, 1);
	let rightSiblingsIndex = Math.min(page + siblings, totalPages);

	let showLeftDots = leftSiblingsIndex > 2;
	let showRightDots = rightSiblingsIndex < totalPages - 2;

	if (!showLeftDots && showRightDots) {
		let leftItemsCount = 3 + 2 * siblings;
		let leftRange = range(1, leftItemsCount + 1);
		return [...leftRange, " ...", totalPages];
	} else if (showLeftDots && !showRightDots) {
		let rightItemsCount = 3 + 2 * siblings;
		let rightRange = range(
			totalPages - rightItemsCount + 1,
			totalPages + 1
		);
		return [1, "... ", ...rightRange];
	} else {
		let middleRange = range(leftSiblingsIndex, rightSiblingsIndex + 1);
		return [1, "... ", ...middleRange, " ...", totalPages];
	}
}

export default returnPaginationRange;
