import React, { createContext, useContext, useState } from "react";
import { ChevronIcon, HomeIcon, TicketIcon } from "../assets/svg/icons";
import { capitalize } from "../utils/format";

const ToggleContext = createContext();

export default function Accordion({ children, ...restProps }) {
	return <ul {...restProps}>{children}</ul>;
}

Accordion.Item = function AccordionItem({ children, ...restProps }) {
	const [toggleShow, setToggleShow] = useState(false);
	return (
		<ToggleContext.Provider value={{ toggleShow, setToggleShow }}>
			<li {...restProps}>{children}</li>
		</ToggleContext.Provider>
	);
};

Accordion.Header = function AccordionHeader({ children, ...restProps }) {
	const { toggleShow, setToggleShow } = useContext(ToggleContext);
	return (
		<div
			onClick={() => {
				setToggleShow(!toggleShow);
			}}
			{...restProps}
		>
			{children}
			<div className="ml-4 flex-shrink-0">
				<ChevronIcon
					className={`h-3 w-3${
						toggleShow ? " rotate-180" : " rotate-0"
					} ease-in-out duration-300`}
				/>
			</div>
		</div>
	);
};

Accordion.Body = function AccordionBody({ children, ...restProps }) {
	const { toggleShow } = useContext(ToggleContext);
	return toggleShow ? <div {...restProps}>{children}</div> : null;
};
//https://www.smashingmagazine.com/2021/08/compound-components-react/
