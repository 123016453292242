import axios from "axios";
import getAccessToken from "./getAccessToken";

const getBanks = async () => {
	const tk = await getAccessToken();

	const payLoad = {
		method: "get",
		maxBodyLength: Infinity,
		url: `https://apipagoterceros.spagrupoinmobiliario.com/api/banks`,
		headers: {
			Authorization: `Bearer ${tk.data.token}`,
		},
		validateStatus: () => true,
	};

	const { data } = await axios(payLoad);

	return data;
};

export default getBanks;
